import React, { useContext, useEffect, useState } from 'react';
import './styles.scss'
import {  Button, Col, Modal, Row, Table } from 'antd';
import {
    DeleteOutlined,
    PlusCircleOutlined,
  } from '@ant-design/icons';
import { useForm } from 'antd/es/form/Form';
import  { ColumnsType } from 'antd/es/table';
import { SpinerHorizontal } from '../../SpinerHorizontal/SpinerHorizontal';
import { UsersSectionProps } from './types';
import {  UsersProps, useGetUsers } from '../../../Services/useGetUsers';
import { useUsers } from '../../../Services/useUsers';
import UserForm, { UserValuesForm }  from '../../Forms/UserForm';
import { CurrencyProps, useGetCurrency } from '../../../Services/useGetCurrency';
import { useCurrency } from '../../../Services/useCurrency';
import CurrencyForm from '../../Forms/CurrencyForm';
import { StylesContext } from '../../../Contexts/UserContext/StylesContext';


export interface DataType {
  index: number,
  id: number,
  name: string,
  sign: string
}

const CurrencySection: React.FC<UsersSectionProps> = () => {

    const {data: dataApi } = useGetCurrency()
    const {adjustedTableHeigthScroll} = useContext(StylesContext) 
    const {deleteCurrency} = useCurrency()

    const [form_currency] = useForm()
    const [loadingTableData, setLoadingTableData] = useState<boolean>(false)
    const [dataTable, setDataTable] = useState<DataType[]>([])
    const [openForm, setOpenForm] = useState<boolean>(false)
    const [rowSelect, setRowSelect] =useState<DataType | undefined>()    
    const [showConfirmDelete, setShowConfirmDelete] = useState(false)

    useEffect(()=>{
      setLoadingTableData(true)
      if(dataApi){
          rowData() 
          setLoadingTableData(false)
      }
  },[dataApi])  

  const rowData = (data? : CurrencyProps[]) =>{
      let filterData: DataType[]= []
      let list: CurrencyProps[] = data ? data : dataApi
      let values: UserValuesForm = form_currency.getFieldsValue() 
    
      if(list && list){
        list.map((data, index)=>{            
          filterData.push({
            index: index,
            id: data.id,
            name: data.name,
            sign: data.sign
          })
        })                  
      }       
      setDataTable(filterData)
    }

    const callback = () =>{
        setLoadingTableData(false)
        setRowSelect(undefined)
        setOpenForm(false)
        setShowConfirmDelete(false)
    }

    const handleRenderer = (text: string, record: DataType) =>{
      return (
          <div className='currency_action_action_render'>
              <DeleteOutlined 
                  onClick={()=>{
                      setRowSelect(record)
                      setShowConfirmDelete(true)
                  }}
                  style={{marginLeft:'30px', color: '#37bde0'}} /> 
          </div>  
      )      
    }
    const ModalConfirmDelete = () => {
      return(
          <Modal
              title="Eliminar"
              open={showConfirmDelete}
              onOk={()=>{
                  if(rowSelect && rowSelect.id){
                      deleteCurrency(callback, rowSelect.id)
                  }}}
              onCancel={()=>setShowConfirmDelete(false)}
              okText="Eliminar"
              cancelText="Cancelar"
          >
              <p>¿Estás seguro de que desea eliminar {rowSelect?.name}?</p>
          </Modal>
      )
  }

  const filters = (type:  'name' | 'sign'   ) => {
    let textAndValue: { text: string, value: string }[] = [];
    let uniqueValues = new Set();

    dataApi?.forEach((item) => {
        let code  
        switch(type){
          case 'name':
            code = item.name
            break;  
          case 'sign':
            code = item.sign
            break;
          default:
            code = ''
            break;
        }
        if (!uniqueValues.has(code)) {
            uniqueValues.add(code);
            textAndValue.push({
                text: code,
                value: code
            });
        }
    });
    return textAndValue;
  }

    
    const columns: ColumnsType<DataType> = [
        {
            title: 'Nombre',
            dataIndex: 'name',
            key: '1',
            className:'cursor-pointer',
            sorter: (a, b) => a.name.localeCompare(b.name),
            filters: filters('name'),
            onFilter: (value, record) => typeof value === 'string' && record.name===value,
            filterSearch: true, 
            onCell: (record, rowIndex) => {
                return {
                  onClick: () => {
                    onClickRow(record)
                  }
                };
              }
        },
        {
            title: 'Moneda',
            dataIndex: 'sign',
            key: '2',
            className:'cursor-pointer',
            sorter: (a, b) => a.sign.localeCompare(b.sign),
            filters: filters('sign'),
            onFilter: (value, record) => typeof value === 'string' && record.sign===value,
            filterSearch: true, 
            onCell: (record, rowIndex) => {
                return {
                  onClick: () => {
                    onClickRow(record)
                  }
                };
              }
        },        
        {
          title: 'Acciones',
          className:'cursor-pointer',
          dataIndex: 'id',
          render:  handleRenderer,
          key: '3',
          width: 110,      
      }, 
    ];
    
    const onClickRow = (record: DataType) =>{         
      setRowSelect(record)
        setOpenForm(true) 
    }  
    

    return( 
        <>
          <ModalConfirmDelete/>
          { openForm && <CurrencyForm
              openForm={openForm} 
              callback={callback}
              rowSelect={rowSelect}
              form_currency={form_currency}
          /> }     
          <div className='currency_section currency_section_container'>                                  
              <Row
                 className='global_title_section'  
              >
                  <h3>MONEDA</h3>                
              </Row>
              <Row 
                className='global_button_box'
              >
                <Button
                  type='primary'
                  className='movements_section_btn'
                  onClick={()=>{setOpenForm(true)}}
                  >
                    Nueva moneda
                </Button>                
              </Row>
              <Row 
                style={{
                    display:'flex',
                    justifyContent: 'center',
                    width: '100%',
                    height: '80%',
                    padding: '0px 30px',                        
                    border: '1px solid   #645fca6c'
                }}
              >
                {
                  loadingTableData
                  ? <SpinerHorizontal/> 
                  : <Table 
                      style={{
                          width: '80%',
                          marginTop: '2%'
                      }}
                          columns={columns} 
                          dataSource={dataTable}  
                          scroll={{ y: adjustedTableHeigthScroll }}                                   
                          pagination={{
                              pageSize: 50,
                              showSizeChanger: false,                                      
                          }}
                      />    
                  } 
              </Row>             
          </div>
        </>
        
        
)};

export default CurrencySection;