import { axiosBackEndInstance } from '../Axios';
import { END_POINT_CURRENCY, END_POINT_CURRENCY_QUOTE_BY_CURRENCY } from '../Axios/ENDPOINTS';
import { notification } from 'antd';
import { useErrorHandling } from '../hooks/useErrorHandling';
import { CurrencyProps, useGetCurrency } from './useGetCurrency';

interface ResponseExchangeRate {
    id: number,
    main_currency: CurrencyProps,
    secondary_currency: CurrencyProps,
    buy_price: number,
    sell_price: number,  
}

export const useCurrency = () => {

    const {errorHandling} = useErrorHandling()
    const { mutate } = useGetCurrency()

    const getExchangeRate = async(
      main_currency_id: number, 
      secondary_currency_id: number      
    ): Promise<ResponseExchangeRate | undefined> =>{
      try{
        const  data = await axiosBackEndInstance.get(
           `${END_POINT_CURRENCY_QUOTE_BY_CURRENCY}/${main_currency_id}/${secondary_currency_id}`
        )
        const status =  errorHandling(data).request.status
        if(status === 200){
          return data.data        
        }
      }catch(newError: any){
        if( newError && newError.response && newError.response.data && newError.response.data.detail && newError.response.data.detail==='Currency quote not found'){
          notification.warning({
            message: 'Notificación', 
            description:'No hay un tipo de cambio creado para esa moneda. Cree uno en Menú => Administración => Tipo de cambio', 
            placement: 'topRight'
          })
        }
        console.log(newError);
      }
    }
     
    const createCurrency = async (
      callback: Function,
      values:{
        name: string,
        sign: string
      }
      
      ): Promise<any> => {      
    try {
      const  data = await axiosBackEndInstance.post(
        END_POINT_CURRENCY, values
      )
      const status =  errorHandling(data).request.status
      if(status === 200){
        mutate()
        callback()
        notification.success({
          message: 'Éxito', 
          description:'Moneda creada con éxito', 
          placement: 'topRight'
        })
      }
    } catch (newError) {
      callback()
      console.log(newError);
    }
  };

  const editCurrency = async (
    callback: Function,
    id: number,
    values:{
      name: string,
      sign: string
    }
                            
  ): Promise<void> => {
              
    try {
      const data  = await axiosBackEndInstance.put(
        `${END_POINT_CURRENCY}${id}`, values
      );
      callback()   
      if(data.status === 200 || data.status === 201){
        mutate()
        notification.success({
          message: 'Éxito', 
          description:'Moenda editada con éxito', 
          placement: 'topRight'
        })
      }    
    } catch (newError) {    
      callback()
      let status = errorHandling(newError).request.status
      console.log('Status ' + status);
      console.log(newError);
    }
  };

  const deleteCurrency = async (
    callback: Function,
    id: number                            
  ): Promise<void> => {
      
    try {
      const data  = await axiosBackEndInstance.delete(
        `${END_POINT_CURRENCY}${id}`, 
      );
      callback()   
      if(data.status === 200){
        mutate()
        notification.success({
          message: 'Éxito', 
          description:'Moneda eliminada con éxito', 
          placement: 'topRight'
        })
      }    
    } catch (newError) {
      console.log(newError);
      callback()
      let status = errorHandling(newError).request.status
    }
  };

  return {
    editCurrency,
    createCurrency,
    deleteCurrency,
    getExchangeRate
  };
};
