import React, {  useContext, useEffect, useState } from "react";
import './styles.scss';
import { Checkbox, CheckboxProps,   Col,   Form, Input, InputNumber, Modal, Row, Select } from "antd";
import { DepositExtractionFormProps } from "./types";
import { useGetAllClients } from "../../../Services/useGetAllClients";
import { useGetCashDesk } from "../../../Services/useGetCashDesk";
import { useGetOperationType } from "../../../Services/useGetOperationType";
import { useOperations } from "../../../Services/useOperations";
import { VariablesContext } from "../../../Contexts/UserContext/VariablesContext";
import { useGetCheckoutBank } from "../../../Services/useGetCheckoutBank";
import { ResponseAccounts, useAccounts } from "../../../Services/useAccounts";
import { UserContext } from "../../../Contexts/UserContext/UsersContext";
import { useGetBranch } from "../../../Services/useGetBranch";
import { Transaction } from "../../../Services/useGetMovements";
import ModalTitle from "../../ModalTitle";
import { OperationsContext } from "../../../Contexts/UserContext/OperationsContext";
import NewCustomer from "../../NewCustomer";

export interface FilterValues{
    value: string,
    label: string,
    key: number,
}


interface inputsForms{
    date: {$d:string}
    operation_type: FilterValues,
    client: FilterValues,
    cash: FilterValues,
    amount: number,
    currency: FilterValues
    commission: string,
    description: string,
    transfer_costs: string,
    client_pays_costs: boolean  
}

type OperationData = {
    description: string;
    type_id: number;
    customer_id: number;
    user_id: number;
    approved: boolean;
    commission?: number;     
    debit?: number;
    credit?: number;
    destination_checkout_id?: number,    
    origin_checkout_id?: number;
    transfer_costs?: number,
    client_pays_costs?: boolean
};

export const DepositExtractionForm: React.FC<DepositExtractionFormProps> =({
    form, 
})=>{
    
    const { data: clients } = useGetAllClients()
    const { data: cashs } = useGetCashDesk()
    const { setRowSelect } = useContext(OperationsContext)
    const { userData } =useContext(UserContext)
    const { data: cashs_bank } = useGetCheckoutBank()
    const { data: operation_type } = useGetOperationType()
    const { collapsedFilsters, setCollapsedFilters, setSelectedRowId, selectedRowId} = useContext(VariablesContext)
    const { setShowForms, showForms } = useContext(VariablesContext)
    const { createOperation, editOperation, getOperation } = useOperations()
    const { getAccountsCurrencyAndCustomer } = useAccounts()  
    const { Option } = Select;
    const {data: branches } = useGetBranch()
 
    const filtered = operation_type && operation_type.filter(item => [1, 2, 3, 4].includes(item.id));
    const [ loadingBtn, setLoadingBtn ] = useState(false)
    const [ isTransferer, setIsTransferer ] = useState(false)
    const [ aproved, setAproved ] = useState(false)
    const [ clientPayCost, setClientPayCost ] = useState(false)
    const [ operationBank, setOperationBank ] = useState<boolean>(false)
    const [ operationType, setOperationType ] = useState<number | undefined>()
    const [ spanError, setSpanError ] = useState<string>()
    const [ client, setClient ] = useState<number | undefined>()
    const [ currency, setCurrency ] = useState<{id: number, sign: string} | undefined>()
    const [ balance, setBalance ] = useState<ResponseAccounts>()
    const [ amountInput, setAmountInput] = useState<number>()
    const [results, setResults] = useState<number>()
    const [comission, setComission] = useState<number>(3)
    const [costBank, setCostBank] = useState<number>(0)
    const [typeTrasnfer, setTypeTransfer] = useState<'deposit' | 'extraction'>()
    const [transactionsData, setTransactionsData] = useState<Transaction>()
    const [branchSelected, setBranchSelected] = useState<number | undefined>()    

    const handleCancel =()=>{
        setLoadingBtn(false)
        setShowForms(Object.fromEntries(Object.keys(showForms).map(key => [key, false])))// pone todos en false
        if(selectedRowId){
            setSelectedRowId(undefined)  
        }       
        setRowSelect(undefined) 
    }

    const mandatory = [
        {
            required: true,
            message:'Este campo es obligatorio'
        }
    ]    
    useEffect(() => {
        if(selectedRowId){
            uploadForm(selectedRowId) 
        }else{
            form.resetFields(undefined) 
            setComission(3)
            setTypeTransfer(undefined)
        }       
    }, []);

    useEffect(()=>{
        if(currency && currency?.id && client ){            
            getBalance()
        }    
    },[currency, client])

    useEffect(()=>{
        if (
            amountInput != undefined &&
            comission != undefined &&
            costBank != undefined
        ){            
            if(typeTrasnfer === 'deposit'){
                let result = clientPayCost
                    ? Number(amountInput)-(amountInput*(comission/100))-Number(costBank)
                    : Number(amountInput)-(amountInput*(comission/100))
                setResults((result))
            }
            if(typeTrasnfer === 'extraction'){
                let result =  clientPayCost
                    ? (amountInput*(1+comission/100))+Number(costBank)
                    : (amountInput*(1+comission/100))
                setResults(-(result))
            }            
        }
    },[amountInput, comission, typeTrasnfer, costBank, clientPayCost])


    useEffect(()=>{
        if(amountInput){
            if(balance && (operationType===2 || operationType===4)){ //depósitos
                if( amountInput > balance.balance ){            
                    let value = new Intl.NumberFormat('es-AR', { style: 'decimal', minimumFractionDigits: 2 }).format(balance.balance)
                    setSpanError('La extraccón supera el monto de cuenta: ' + currency?.sign + ' ' + value  )  
                }
            }else{
                if(spanError){
                    setSpanError(undefined)
                }
            }
        }        
    },[balance])

    const uploadForm = async(data:{id:string}) =>{
            const response = await getOperation(+data.id)
            if(response){
                let cash_data
                let amount_data
    
                setTransactionsData(response)
                setAproved(response.approved) 
                if(response?.type.id===3 || response?.type.id===4){
                    setOperationBank(true)
                    if(response?.type.id===3 ){
                        setTypeTransfer('deposit')                    
                    }
                    if( response?.type.id===4){
                        setTypeTransfer('extraction')
                    }
                }
                setClient(response.customer.id)
                setCostBank(response.transfer_costs  ? response.transfer_costs : 0)
                setClientPayCost(response.client_pays_costs || false)  
                
                switch(response.type.id){
                    case 1: // Depósito efectivo
                    case 3: // Depósito bancario
                        
                        let cash_destination = cashs.filter((cash)=> cash.id===response.destination_checkout.id)[0]
    
                        cash_data ={
                            label: response.destination_checkout.name,
                            value: response.destination_checkout.name,
                            key: response.destination_checkout.id,
                        }                    
                        amount_data = response.credit 
                       
                        setCurrency({id: cash_destination.currency.id, sign: cash_destination.currency.sign})                    
                        setAmountInput(response.credit)
                        if(response.destination_checkout.branch.id){
                            setBranchSelected(response.destination_checkout.branch.id)
                        }
                        form.setFieldsValue({
                            branch_cahs_extraction:{
                                value: response.destination_checkout.branch.id || undefined,
                                label: response.destination_checkout.branch.name || undefined,
                                key: response.destination_checkout.branch.name || undefined,
                            },
                        })
                        break;
                    case 2: // Extracción efectivo
                    case 4:  // Extracción Bancaria
                        let cash_origin = cashs.filter((cash) => cash.id===response.origin_checkout?.id)[0];
                        cash_data = {
                            label: response.origin_checkout?.name ,
                            value: response.origin_checkout?.name,
                            key: response.origin_checkout?.id,
                        };                    
                        amount_data = response.debit;

                        setCurrency({ id: cash_origin.currency.id, sign: cash_origin.currency.sign });
                        setAmountInput(response.debit);
                        if(response.origin_checkout.branch.id){
                            setBranchSelected(response.origin_checkout?.branch?.id)
                        }
                        form.setFieldsValue({
                            branch_cahs_extraction:{
                                value: response.origin_checkout?.branch?.id || undefined,
                                label: response.origin_checkout?.branch?.name || undefined,
                                key: response.origin_checkout?.branch?.name || undefined,
                            },
                        })
                        
                        break;
                    default:
                        break
                }
                switch(response.type.id){
                    case 3:
                    case 4:
                        setIsTransferer(true)
                        break;
                    case 1:
                    case 2:
                        setIsTransferer(false)
                }                                   

                if(cash_data ){
                    form.setFieldsValue({
                        operation_type: {
                            label: response.type.name,
                            value: response.type.name,
                            key: response.type.id
                        },
                        client: {
                            label: response.customer.first_name + ' ' +  response.customer.last_name,
                            value: response.customer.first_name + ' ' +  response.customer.last_name,
                            key: response.customer.id
                        },                    
                        comission:  response.commission,
                        cash: cash_data,
                        amount: amount_data,
                        description: response.description,
                        transfer_costs: response.transfer_costs,
                        client_pays_costs: response.client_pays_costs                      
                    });  
                }  
                if(response.commission){
                    setComission(response.commission)
                }
                
                setAmountInput(amount_data)  
                               
            }            
                        
    }

    const confirmSave = async(operationData: OperationData)=>{        
        let newData
        if(selectedRowId){
            newData = await editOperation(handleCancel, selectedRowId.id!, operationData)
        }else{
            newData = await createOperation(handleCancel, operationData)
        }  
        setCollapsedFilters(!collapsedFilsters)            
        
    }
    const getBalance = async() =>{  
        if(client){
            const data = await getAccountsCurrencyAndCustomer(client, Number(currency?.id))
            if(data){
                setBalance(data)
    
            }
        }                             
    }

    const handleSave = async (e: inputsForms)=>{
        //setLoadingBtn(true)
        let operationData: OperationData | undefined;  
        if(userData){
            let operation_type_id = Number(e.operation_type.key)
            switch (operation_type_id) {
                case 1: // Depósito efectivo
                case 3: // Depósito bancario
                    operationData = {                                     
                        description: e.description,
                        type_id: Number(e.operation_type.key),
                        destination_checkout_id: Number(e.cash.key),
                        customer_id: Number(e.client.key),
                        user_id: userData?.id,
                        credit: Number(e.amount),  
                        approved: aproved,
                        transfer_costs: e.transfer_costs ? Number(e.transfer_costs) : undefined, 
                        client_pays_costs: clientPayCost                                                                               
                    };
                    if(operation_type_id===3){
                        operationData.commission = Number(e.commission);
                    }
                    break;
                case 2: // Extracción efectivo
                case 4: // Extracción bancaria
                    operationData = {
                        description: e.description,
                        type_id: Number(e.operation_type.key),                
                        origin_checkout_id: Number(e.cash.key),
                        customer_id: Number(e.client.key),
                        user_id: userData?.id,
                        debit: Number(e.amount),   
                        approved: aproved,     
                        transfer_costs: e.transfer_costs ? Number(e.transfer_costs) : undefined, 
                        client_pays_costs: clientPayCost               
                    };
                    if(operation_type_id===4){
                        operationData.commission = Number(e.commission);
                    }
                    break;
                default:
                    handleCancel()
                    break;
            }
        }     
        if(operationData){
            Modal.confirm({
                title: 'Confirmar operación',
                okText: 'Confirmar',
                cancelText: 'Cancelar',
                onOk:()=> operationData && confirmSave(operationData),
                content: (
                    <div>
                        <p><strong>{e.operation_type.label}</strong></p>
                        {operationData.customer_id && <p><strong>Cliente: </strong> {e.client.label}</p>}
                        <p><strong>Sucursal - Caja: </strong>{branchSelected && branches && branches?.find((x)=> x.id === branchSelected)?.name} {e.cash.label}</p>                                               
                        <p><strong>Monto: </strong>{currency && currency.sign} {Number(e.amount).toLocaleString("es-AR",{maximumFractionDigits:2, minimumFractionDigits:2})}</p>
                        {
                            operationData.commission && <p><strong>Comisión: </strong>{operationData.commission } %</p>
                        }
                        {
                            (operationData.transfer_costs ?? 0) > 0 && (
                                <p><strong>Costos bancarios: </strong>{currency?.sign} {operationData.transfer_costs}</p>
                            )
                        }
                        {
                            (operationData.transfer_costs ?? 0) > 0 && (
                                <p><strong>Cliente paga los costos: </strong>{operationData.client_pays_costs ? 'Si' : 'No'} </p>
                            )
                        }
                        <p>
                            <strong>Estado: </strong>
                            <strong style={{ color: operationData.approved ? 'green' : 'orange' }}>
                                {operationData.approved ? 'Aprobado' : 'Sin aprobar'}
                            </strong>
                        </p>                                                                                                                  
                    </div>
                ),
            });  
        }
              
               
    }    

    const changeTransferer = (e: FilterValues ) =>{
        
        if(Number(e.key)===3 || Number(e.key)===4){
            setOperationBank(true)
        }else{
            setOperationBank(false)
        }
        setSpanError(undefined)
        setOperationType(e.key)        
        setAproved(false)
        setAmountInput(undefined)
        setResults(undefined)
        form.resetFields([
            'client', 
            'branch_cahs_extraction', 
            'cash', 
            'sell_currency', 
            'amount', 
            'commission', 
            'aproved', 
            'amount', 
            'description'
        ]);
        if(Number(e.key)===3 || Number(e.key)===4){//is transferer
            setIsTransferer(true)
            if(Number(e.key)===3){
                setTypeTransfer('deposit')                    
            }
            if(Number(e.key)===4){
                setTypeTransfer('extraction')
            }
            
        }else{
            setIsTransferer(false)
            setTypeTransfer(undefined)
            
        }         
    }

    const onChangeClient = async(e: FilterValues) =>{
        setClient(Number(e.key))        
    }

    const onChangeCash = async (e: FilterValues, values: any) =>{
        if(spanError){
            setSpanError(undefined)
        }
        if(values?.currency){
            setCurrency({id: values.currency, sign: values.sign})            
        }        
    }
 
    const onChangeAproved: CheckboxProps['onChange'] = async (e) => {
        setAproved(e.target.checked); 
    };

    const onChangePayCost: CheckboxProps['onChange'] = async (e) => {
        setClientPayCost(e.target.checked); 
    };

    const onChangeAmount = (value: number | null) =>{
        if(value){
            setAmountInput(value)
            if(balance && (operationType===2 || operationType===4)){ //depósitos
                if(value > balance.balance ){            
                    let value = new Intl.NumberFormat('es-AR', { style: 'decimal', minimumFractionDigits: 2 }).format(balance.balance)
                    setSpanError('La extraccón supera el monto de cuenta: ' + currency?.sign + ' ' + value  )  
                }
            }
        }
    }            

    const onChangeComission = (value: number | null) => {       
        if (value !== null && value !== undefined) {
            setComission(value);
        }    
    }

    const onChangeCostBank = (value: number | null) =>{       
        if(value || value == 0){
            setCostBank(value)
        }    
    }

    return(
        <div>
            <Modal
                style={{ top: 10 }}
                title= {
                    <ModalTitle 
                        title={'Depósito / Extracción: Cta cte clientes'} 
                        isApproved={transactionsData && transactionsData.approved ? true : false}
                        isDeleted={transactionsData && transactionsData.deleted ? true : false}
                        operationNumber={transactionsData ? transactionsData.operation_id: undefined}
                    />
                }
                open={showForms.deposit_extraction }
                onCancel={handleCancel}  
                //confirmLoading={loadingBtn}                   
                okText="Guardar"
                cancelText="Cancelar" 
                onOk={()=>form.submit()}
                footer={transactionsData?.approved ? null : undefined} 
            >
                <NewCustomer justify={"end"} positions= {'leftTop'} />
                <Form
                    form={form}
                    id='form_deposit_extraction'
                    onFinish={handleSave}
                    layout="vertical"
                    
                >                   
                    <Form.Item
                        label='Tipo de operación'
                        name='operation_type'                
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                    >
                        <Select
                            disabled={transactionsData?.approved}
                            labelInValue
                            placeholder="Seleccione el tipo de movimiento"   
                            onChange={changeTransferer}                         
                        >
                            {filtered && filtered.map((item) => (
                                <Option value={item.name} key={item.id} >
                                    {item.name}
                                </Option>
                            ))} 
                        </Select>
                    </Form.Item>                       
                    <Form.Item
                        label='Cliente'
                        name='client'
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                    >
                        <Select
                            disabled={transactionsData?.approved}                           
                            labelInValue  
                            loading={!clients }
                            placeholder={!clients ? 'Cargando campos...' : 'Cliente'}      
                            style={{minWidth: '200px'}} 
                            showSearch   
                            onChange={onChangeClient}                                 
                        >
                            {clients && clients.map((item) => (
                                <Option value={item.first_name + ' ' + item.last_name} key={item.id} >
                                    {item.first_name + ' ' + item.last_name}
                                </Option>
                            ))} 
                        </Select>
                    </Form.Item> 
                    
                    <Row style={{ display: 'flex', gap: '10px', width: '100%' }}>
                        <Col style={{ flex: 1 }}>
                            <Form.Item
                                label='Sucursal'
                                name='branch_cahs_extraction'
                                rules={mandatory}
                            >
                                <Select
                                    disabled={transactionsData?.approved}
                                    style={{minWidth: 120}}
                                    labelInValue  
                                    onChange={(e)=>{
                                        setBranchSelected(+e.key)
                                        form.setFieldValue('extraction_cash', undefined)
                                    }}
                                    placeholder= 'Sucursal'
                                >
                                    {branches && branches.map((item) => (
                                        item.id !== 0 &&
                                        <Option value={item.name} key={item.id}>
                                            {item.name}
                                        </Option>
                                    ))} 
                                </Select> 
                            </Form.Item>
                        </Col>
                        <Col style={{ flex: 1 }}>
                            <Form.Item
                                label='Caja'
                                name='cash'
                                rules={[
                                    {
                                        required: true,
                                        message:'Este campo es obligatorio'
                                    }
                                ]}                                
                            >
                            {
                                operationBank ?
                                <Select  
                                    disabled={transactionsData?.approved}                         
                                    labelInValue  
                                    loading={!cashs_bank }
                                    placeholder={!cashs_bank ? 'Cargando cajas...' : 'Seleccione una caja'}      
                                    style={{minWidth: '200px'}}  
                                    showSearch   
                                    onChange={onChangeCash}                          
                                >
                                    {cashs_bank && cashs_bank
                                        .filter((item) => item.branch.id === branchSelected || item.branch.id===0)
                                        .map((item) => (
                                        <Option value={item.name} key={item.id} currency={item.currency.id} sign={item.currency.sign}>
                                            {item.name}
                                        </Option>
                                    ))} 
                                </Select> :
                                <Select
                                    disabled={transactionsData?.approved}                           
                                    labelInValue  
                                    loading={!cashs }
                                    placeholder={!cashs ? 'Cargando cajas...' : 'Seleccione una caja'}      
                                    style={{minWidth: '200px'}}  
                                    showSearch    
                                    onChange={onChangeCash}                          
                                >
                                    {cashs && cashs
                                        .filter((item) => item.branch.id === branchSelected || item.branch.id === 0)
                                        .map((item) => (
                                        <Option value={item.name} key={item.id}  currency={item.currency.id} sign={item.currency.sign}>
                                            {item.name}
                                        </Option>
                                    ))} 
                                </Select>                                
                            }                       
                        </Form.Item>  
                        </Col>
                    </Row>                                                                            
                    {
                        isTransferer &&
                        <Form.Item
                            label='Comisión'
                            name='commission'
                            initialValue={comission}
                            rules={[
                                {
                                    required: true,
                                    message:'Este campo es obligatorio'
                                }
                            ]}
                        >
                            <InputNumber
                                disabled={transactionsData?.approved}
                                addonBefore={'%'} 
                                onChange={onChangeComission}
                                placeholder="Comisión %"
                                type="number"
                            />
                        </Form.Item>
                    } 
                    {
                        isTransferer &&
                        <Form.Item
                            label='Costos Bancarios'
                            name='transfer_costs'
                            initialValue={costBank}                                                        
                        >
                            <InputNumber
                                disabled={transactionsData?.approved}
                                onChange={onChangeCostBank}
                                placeholder="0"
                                type="number"
                                addonBefore={currency ? currency.sign : ' ¤ '} 
                                min={0}
                            />
                        </Form.Item>
                    }

                    <Form.Item
                        label='Monto'
                        name='amount'
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                    >
                        <InputNumber
                            disabled={transactionsData?.approved}
                            addonBefore={currency ? currency.sign : ' ¤ '} 
                            placeholder="Monto"
                            type="number"
                            onChange={onChangeAmount}
                        />
                    </Form.Item>
                    {
                        isTransferer &&
                        <Row style={{marginBottom: 15, fontWeight: 700}}>
                            <span style={{marginRight:10}}>
                                {typeTrasnfer === 'deposit' && 'Acreditación en cuenta cliente:'}
                                {typeTrasnfer === 'extraction' && 'Débito en cuenta cliente:'} 
                            </span>
                            <span>{currency && currency.sign}</span>
                            <span>{results?.toLocaleString("es-AR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>  
                        </Row>                                                                       
                    }
                    {
                        isTransferer &&                        
                        <Checkbox
                            style={{width: '100%', marginBottom: 15}}
                            disabled={transactionsData?.approved}
                            checked={clientPayCost}
                            onChange={onChangePayCost}
                                
                        >
                            Cliente paga los costos
                        </Checkbox>                        
                    }
                    <Checkbox
                        
                        style={{width: '100%', marginBottom: 15}}
                        disabled={transactionsData?.approved}
                        checked={aproved}
                        onChange={onChangeAproved}
                    >
                        Aprobado
                    </Checkbox>
                    <Form.Item
                        label='Descripción'
                        name='description'
                    >
                        <Input
                            disabled={transactionsData?.approved}
                            placeholder="Comentario"
                        />
                    </Form.Item>                                        
                    <span style={{color: 'red'}}>{spanError && spanError} </span>                              
                </Form>
            </Modal>
        </div>
    )
}

export default DepositExtractionForm;
