import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { IntlProvider } from 'react-intl';
import { createRoot } from 'react-dom/client';
import './index.css'
//import es from './i18n/es.json'
//import eng from './i18n/eng.json'


// const messages: any = {
//   'es': es,
//   'en': eng,
// };

const language: string = navigator.language.split(/[-_]/)[0];
const container = document.getElementById('root');


if (container) {
  const root = createRoot(container);
  root.render(
   // <React.StrictMode>
      <IntlProvider locale={language + "-AR"} >
        <App/>
      </IntlProvider>
   // </React.StrictMode>
  );
} else {
  // Manejar el caso donde 'container' es null
}


reportWebVitals();
