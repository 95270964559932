import React, {  useContext, useEffect, useState } from "react";
import './styles.scss';
import { Checkbox, CheckboxProps,   Col,   Form, Input, InputNumber, Modal, Row, Select } from "antd";
import { FundsMovementFormProps } from "./types";
import { useGetCashDesk } from "../../../Services/useGetCashDesk";
import { useGetOperationType } from "../../../Services/useGetOperationType";
import { useOperations } from "../../../Services/useOperations";
import { VariablesContext } from "../../../Contexts/UserContext/VariablesContext";
import { UserContext } from "../../../Contexts/UserContext/UsersContext";
import { useGetBranch } from "../../../Services/useGetBranch";
import { Transaction } from "../../../Services/useGetMovements";
import ModalTitle from "../../ModalTitle";
import Operation from "antd/es/transfer/operation";
import { OperationsContext } from "../../../Contexts/UserContext/OperationsContext";

export interface FilterValues{
    value: string,
    label: string,
    key: number,
}

interface inputsForms{
    date: {$d:string}
    operation_type: FilterValues,
    client: FilterValues,
    cash: FilterValues,
    amount: number,
    currency: FilterValues
    commission: string,
    description: string,
    transfer_costs: string,
    client_pays_costs: boolean  
}

type OperationData = {
    description: string;
    type_id: number;
    user_id: number;
    approved: boolean;    
    debit?: number;
    credit?: number;
    destination_checkout_id?: number,    
    origin_checkout_id?: number;
};

export const FundsMovementForm: React.FC<FundsMovementFormProps> =({
    form, 
})=>{
    
    const { data: cashs } = useGetCashDesk()
    const { userData } = useContext(UserContext)
    const { data: operation_type } = useGetOperationType()
    const { collapsedFilsters, setCollapsedFilters, setShowForms, showForms } = useContext(VariablesContext)
    const { setRowSelect} = useContext(OperationsContext)
    const { createOperation, editOperation, getOperation } = useOperations()
    const { Option } = Select;
    const {data: branches } = useGetBranch()
    const {setSelectedRowId, selectedRowId} = useContext(VariablesContext)
 
    const filtered = operation_type && operation_type.filter(item => [14,15].includes(item.id));
    const [ loadingBtn, setLoadingBtn ] = useState(false)
    const [ aproved, setAproved ] = useState(false)
    const [ spanError, setSpanError ] = useState<string>()
    const [ currency, setCurrency ] = useState<{id: number, sign: string} | undefined>()
    const [transactionsData, setTransactionsData] = useState<Transaction>()
    const [branchSelected, setBranchSelected] = useState<number | undefined>()    

    const handleCancel =()=>{
        setLoadingBtn(false)
        setShowForms(Object.fromEntries(Object.keys(showForms).map(key => [key, false])))// pone todos en false
        if(selectedRowId){
            setSelectedRowId(undefined)  
        } 
        setTransactionsData(undefined)   
        setRowSelect(undefined) 
    }

    const mandatory = [
        {
            required: true,
            message:'Este campo es obligatorio'
        }
    ]    

    const uploadForm = async(data:{id: string}) =>{
        const response = await getOperation(+data.id)
        if(response){
            let cash_data
            let amount_data    
            console.log(response)
            setTransactionsData(response)
            setAproved(response.approved)  
            switch(response.type.id){
                case 14: // Depósito fondo                                           
                    let cash_destination = cashs.filter((cash)=> cash.id===response.destination_checkout.id)[0]

                    cash_data ={
                        label: response.destination_checkout.name,
                        value: response.destination_checkout.name,
                        key: response.destination_checkout.id,
                    }                    
                    amount_data = response.credit                        
                    setCurrency({id: cash_destination.currency.id, sign: cash_destination.currency.sign})  
                    if(response.destination_checkout.branch.id){
                        setBranchSelected(response.destination_checkout.branch.id)
                    }
                    form.setFieldsValue({
                        branch_cahs_extraction:{
                            value: response.destination_checkout.branch.id || undefined,
                            label: response.destination_checkout.branch.name || undefined,
                            key: response.destination_checkout.branch.name || undefined,
                        },
                    })
                    break;
                case 15: // Extracción de fondos
                    let cash_origin = cashs.filter((cash) => cash.id===response.origin_checkout?.id)[0];
                    cash_data = {
                        label: response.origin_checkout?.name ,
                        value: response.origin_checkout?.name,
                        key: response.origin_checkout?.id,
                    };                    
                    amount_data = response.debit;

                    setCurrency({ id: cash_origin.currency.id, sign: cash_origin.currency.sign });
                    if(response.origin_checkout.branch.id){
                        setBranchSelected(response.origin_checkout?.branch?.id)
                    }
                    form.setFieldsValue({
                        branch_cahs_extraction:{
                            value: response.origin_checkout?.branch?.id || undefined,
                            label: response.origin_checkout?.branch?.name || undefined,
                            key: response.origin_checkout?.branch?.name || undefined,
                        },
                    })
                    
                    break;
                default:
                    break
            }                              
            if(cash_data ){
                form.setFieldsValue({
                    operation_type: {
                        label: response.type.name,
                        value: response.type.name,
                        key: response.type.id
                    },
                    cash: cash_data,
                    amount: amount_data,
                    description: response.description,                    
                });  
            }                   
        }                
    }

    useEffect(() => {
        if(selectedRowId){
            uploadForm(selectedRowId)
        }else{
            form.resetFields(undefined) 
        }        
    }, []);


    const confirmSave = async(operationData: OperationData) =>{
        let newData
        if(selectedRowId){
            newData = await editOperation(handleCancel, selectedRowId.id!, operationData)
        }else{
            newData = await createOperation(handleCancel, operationData)
        }  
     
        setCollapsedFilters(!collapsedFilsters)     
    }
    const handleSave = async (e: inputsForms)=>{
        setLoadingBtn(true)
        let operationData: OperationData | undefined;  
        if(userData){
            let operation_type_id = Number(e.operation_type.key)
            switch (operation_type_id) {
                case 14: // Depósito efectivo
                    operationData = {                                     
                        description: e.description,
                        type_id: Number(e.operation_type.key),
                        destination_checkout_id: Number(e.cash.key),
                        user_id: userData?.id,
                        credit: Number(e.amount),  
                        approved: aproved,                                                                            
                    };
                    break;
                case 15: // Extracción efectivo
                    operationData = {
                        description: e.description,
                        type_id: Number(e.operation_type.key),                
                        origin_checkout_id: Number(e.cash.key),
                        user_id: userData?.id,
                        debit: Number(e.amount),   
                        approved: aproved,                   
                    };
                    break;
                default:
                    handleCancel()
                    break;
            }
        }   
          if(operationData){
            Modal.confirm({
                title: 'Confirmar operación',
                okText: 'Confirmar',
                cancelText: 'Cancelar',                
                onOk:()=> operationData && confirmSave(operationData),
                content: (
                    <div>
                        <p><strong>{e.operation_type.label}</strong></p>                                   
                        <p><strong>Sucursal/es: </strong>{cashs.find((x)=>x.id === Number(e.cash.key))?.branch.name}</p>
                        <p><strong>Caja: </strong>{e.cash.label}</p>
                        <p><strong>{Number(e.operation_type.key )== 14 ? 'Crédito en caja' : 'Débito en caja'} </strong>{cashs.find((x)=>x.id === Number(e.cash.key))?.currency.sign} {e.amount.toLocaleString('es-AR',{minimumFractionDigits: 2, maximumFractionDigits: 2})}</p>
                        <p>
                            <strong>Estado: </strong>
                            <strong style={{ color: operationData.approved ? 'green' : 'orange' }}>
                                {operationData.approved ? 'Aprobado' : 'Sin aprobar'}
                            </strong>
                        </p>                                                                                                                  
                    </div>
                ),
            });  
        }                              
    }    

    const changeTransferer = (e: FilterValues ) =>{
        setSpanError(undefined)   
        setAproved(false)
        form.resetFields([
            'branch_cahs_extraction', 
            'cash', 
            'sell_currency', 
            'amount',  
            'aproved', 
            'amount', 
            'description'
        ]);      
    }

    const onChangeCash = async (e: FilterValues, values: any) =>{
        if(spanError){
            setSpanError(undefined)
        }
        if(values?.currency){
            setCurrency({id: values.currency, sign: values.sign})            
        }        
    }
 
    const onChangeAproved: CheckboxProps['onChange'] = async (e) => {
        setAproved(e.target.checked); 
    }; 

    return(
        <div>
            <Modal
                style={{ top: 10 }}
                title= {
                    <ModalTitle 
                        title={'Depósito / Extracción de fondos'} 
                        isApproved={transactionsData && transactionsData.approved ? true : false}
                        isDeleted={transactionsData && transactionsData.deleted ? true : false}
                        operationNumber={transactionsData ? transactionsData.operation_id: undefined}
                    />
                }
                footer={transactionsData?.approved ? null : undefined} 
                open={showForms.funds_movements }
                onCancel={handleCancel}  
                //confirmLoading={loadingBtn}                   
                okText="Guardar"
                cancelText="Cancelar" 
                onOk={()=>form.submit()}
            >
                <Form
                    form={form}
                    id='form_deposit_extraction'
                    onFinish={handleSave}
                    layout="vertical"
                    
                >                   
                    <Form.Item
                        label='Tipo de operación'
                        name='operation_type'                
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                    >
                        <Select
                            disabled={transactionsData?.approved}
                            labelInValue
                            placeholder="Seleccione el tipo de movimiento"   
                            onChange={changeTransferer}                         
                        >
                            {filtered && filtered.map((item) => (
                                <Option value={item.name} key={item.id} >
                                    {item.name}
                                </Option>
                            ))} 
                        </Select>
                    </Form.Item>                                                              
                    <Row style={{ display: 'flex', gap: '10px', width: '100%' }}>
                        <Col style={{ flex: 1 }}>
                            <Form.Item
                                label='Sucursal'
                                name='branch_cahs_extraction'
                                rules={mandatory}
                            >
                                <Select
                                    disabled={transactionsData?.approved}
                                    style={{minWidth: 120}}
                                    labelInValue  
                                    onChange={(e)=>{
                                        setBranchSelected(+e.key)
                                        form.setFieldValue('extraction_cash', undefined)
                                    }}
                                    placeholder= 'Sucursal'
                                >
                                    {branches && branches.map((item) => (
                                        item.id !== 0 &&
                                        <Option value={item.name} key={item.id}>
                                            {item.name}
                                        </Option>
                                    ))} 
                                </Select> 
                            </Form.Item>
                        </Col>
                        <Col style={{ flex: 1 }}>
                            <Form.Item
                                label='Caja'
                                name='cash'
                                rules={[
                                    {
                                        required: true,
                                        message:'Este campo es obligatorio'
                                    }
                                ]}                                
                            >                           
                                <Select   
                                    disabled={transactionsData?.approved}                        
                                    labelInValue  
                                    loading={!cashs }
                                    placeholder={!cashs ? 'Cargando cajas...' : 'Seleccione una caja'}      
                                    style={{minWidth: '200px'}}  
                                    showSearch    
                                    onChange={onChangeCash}                          
                                >
                                    {cashs && cashs
                                        .filter((item) => item.branch.id === branchSelected || item.branch.id===0)
                                        .map((item) => (
                                        <Option value={item.name} key={item.id}  currency={item.currency.id} sign={item.currency.sign}>
                                            {item.name}
                                        </Option>
                                    ))} 
                                </Select>                                                                               
                        </Form.Item>  
                        </Col>
                    </Row>                                                                                              
                    <Form.Item
                        label='Monto'
                        name='amount'
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                    >
                        <InputNumber
                            disabled={transactionsData?.approved}
                            min={0}
                            addonBefore={currency ? currency.sign : ' ¤ '} 
                            placeholder="Monto"
                            type="number"
                        />
                    </Form.Item>     
                    <Checkbox       
                        style={{marginBottom: 10}}                 
                        disabled={transactionsData?.approved}
                        checked={aproved}
                        onChange={onChangeAproved}
                    >
                        Aprobado
                    </Checkbox>
                    <Form.Item
                        label='Descripción'
                        name='description'
                    >
                        <Input
                            disabled={transactionsData?.approved}
                            placeholder="Comentario"
                        />
                    </Form.Item>                                        
                    <span style={{color: 'red'}}>{spanError && spanError} </span>                              
                </Form>
            </Modal>
        </div>
    )
}

export default FundsMovementForm
