import { Button, Col, Input, InputNumber, message, Modal, Row, Tooltip } from "antd"
import FundsMovementForm from "../../Forms/FundsMovementForm"
import { useForm } from "antd/es/form/Form"
import { useContext, useEffect, useRef, useState } from "react"
import { VariablesContext } from "../../../Contexts/UserContext/VariablesContext"
import {
    ExclamationCircleOutlined,
    CaretRightOutlined
  } from '@ant-design/icons';
import { DataType, DataTypeCheckout, InitializationSectionProps } from "./types"
import { StylesContext } from "../../../Contexts/UserContext/StylesContext"
import Table, { ColumnsType } from "antd/es/table"
import { Accounts, useGetAllAccount } from "../../../Services/useGetAllAccount"
import { useAccounts } from "../../../Services/useAccounts"
import { CashDeskProps, useGetCashDesk } from "../../../Services/useGetCashDesk"
import { useCashDesk } from "../../../Services/useCashDesk"
import { BalancesContext } from "../../../Contexts/UserContext/BalancesContext"
import { useFeatureFlags } from "../../../Services/useFeatureFlags"

const RenderBalance: React.FC<
    {
        id: string,
        balance: string, 
        sign: string,
        update: (id: number, balance: number) =>void
    }
> = ({
    id,
    balance,
    sign,
    update
}) =>{

    const inputRef = useRef<HTMLInputElement | null>(null);
    const [showInput, setShowInput] = useState(false)
    const [amount, setAmount] = useState<number>(Number(balance));

  
    const onUpdate = (value: number)=>{     
        setAmount(value); 
        setShowInput(!showInput)
        update(Number(id),  value )
    }

    const handleKeyPress = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            if(amount !== undefined && amount !== null){
                onUpdate(amount);
            }
        }        
    }

    const onChangeAmount = (value: number | null) => {
        if(value !== undefined && value !== null){
            setAmount(value)
        }
    };

    return(
        <Row>
            {
                showInput 
                ? <InputNumber
                    ref={inputRef}
                    placeholder="Saldo"
                    addonBefore={sign}
                    onChange={onChangeAmount}
                    value={amount}
                    onBlur={() =>{ 
                        setShowInput(false)
                        setAmount(Number(balance))
                    }}
                    style={{width: 200}}
                    onKeyDown={handleKeyPress}
                />
                : <div
                    style={{
                        width: '100%', 
                        color: 'rgb(55, 189, 224)'
                    }}
                    onClick={()=>{
                        setShowInput(true)
                        setTimeout(() => {
                            if (inputRef.current) {
                                inputRef.current.focus(); // Establecer el enfoque en el input
                            }
                        }, 0);
                    }}
                >
                    {sign} {Number(balance).toLocaleString('es-AR', {minimumFractionDigits: 2, maximumFractionDigits:2})}
                </div>
            }            
        </Row>
    )
}

export const InitializationSection: React.FC<InitializationSectionProps> = () =>{

    const { showForms } = useContext(VariablesContext)
    const { setMutateBalance } = useContext(BalancesContext) 
    const {data: dataApi} = useGetAllAccount()     
    const {data: dataApiCheckouts} = useGetCashDesk()
    const {adjustedTableHeigthScroll} = useContext(StylesContext) 
    const {editAccount} = useAccounts()
    const {editCashDeskBalance} = useCashDesk()
    const {initialize} = useFeatureFlags()
    

    const [form_funds_movements] = useForm()
    const [loading, setLoading] = useState<boolean>(false) 
    const [loadingTableData, setLoadingTableData] = useState<boolean>(false)
    const [dataTable, setDataTable] = useState<DataType[]>([])    
    const [dataTableCheckout, setDataTableCheckout] = useState<DataTypeCheckout[]>([])     
    
    useEffect(()=>{
        setLoadingTableData(true)
        if(dataApi){
            rowData() 
            setLoadingTableData(false)
        }
        if(dataApiCheckouts){
            rowDataCheckout()
        }
    },[dataApi])  

    const rowData = (data? : Accounts[]) =>{
        let filterData: DataType[]= []
        let list: Accounts[] = data ? data : dataApi
    
        if(list && list){
        list.map((data, index)=>{  
            if(data.id !== 0){
                filterData.push({
                    index: index.toString(),
                    id: data.id?.toString() || '',
                    first_name: data?.customer?.first_name || '',
                    last_name: data?.customer?.last_name || '',
                    balance: data?.balance?.toString() || '',
                    currency: data.currency?.sign || '',
                    account: data.currency?.name || '',
                })
            }                     
        })                  
        }       
        setDataTable(filterData)
    }

    const rowDataCheckout = (data? : CashDeskProps[]) =>{
        let filterData: DataTypeCheckout[]= []
        let list: CashDeskProps[] = data ? data : dataApiCheckouts
    
        if(list && list){
        list.map((data, index)=>{            
            filterData.push({
            index: index.toString(),
            id: data.id?.toString() || '',
            balance: data.balance?.toLocaleString('es-AR', {minimumFractionDigits: 2, maximumSignificantDigits: 2}) || '',
            name: data.name || '',
            sign: data.currency?.sign || '',    
            branch: data.branch?.name || ''                    
            })
        })}       
        filterData.sort((a, b) => a.branch.localeCompare(b.branch));
        setDataTableCheckout(filterData)
    }

    const updateAccount = async (id: number, balance: number) =>{
       const response = await editAccount(Number(id), {balance: balance} )
       if(response){
        let updatedDataTable = dataTable.map((item) => {
            if (Number(item.id) === response.id) {
              return { ...item, balance: response.balance.toString() }; 
            }
            return item; 
        });
        setDataTable(updatedDataTable)
       }        
    }

    const updateCheckout = async (id: number, balance: number) =>{
        const response = await editCashDeskBalance(Number(id), {balance: balance} )
        if(response){
            let updatedDataTable = dataTableCheckout.map((item) => {
                if (Number(item.id) === response.id) {
                return { ...item, balance: response.balance.toString() }; 
                }
                return item; 
            });
            setMutateBalance(true)
            setDataTableCheckout(updatedDataTable)
        }        
     }
    
    const filters = (
        type: 'name' | 'account' | 'is_customer' 
      ) => {
        let textAndValue: { text: string, value: string }[] = [];
        let uniqueValues = new Set();
  
        dataApi?.forEach((item) => {
          let code = ''
          let value = ''
          switch(type){
            case 'name':
                code = item.customer?.first_name + ' ' + item?.customer.last_name
                value =  item.id.toString()
                break;    
            case 'account':
                code = item.currency?.name
                value =  item.currency?.name
                break;                              
            default:
                code ='';
                value = ''
                break               
          }
        
          if (code && !uniqueValues.has(code)) {
              uniqueValues.add(code);
              textAndValue.push({
                  text: code,
                  value: value
              });
          }
  
        });
        textAndValue.sort((a, b) => {
        if (!isNaN(Number(a.text)) && !isNaN(Number(b.text))) {
            return Number(a.text) - Number(b.text); // Para números
        } else {
            return a.text.localeCompare(b.text); // Para strings
        }
        });
        return textAndValue;
    }   
    

    const columns: ColumnsType<DataType> = [
        {
          title: 'Nombre Cliente',
          dataIndex: 'name',
          key: '1',
          className:'cursor-pointer',
          render: (text: string, reccord: DataType) => <div>{reccord.first_name} {reccord.last_name}</div>,
          filters: filters('name'),
          onFilter: (value, record) => typeof value === 'string' && record.id === value,     
        },
        {
            title: 'Cuenta',
            dataIndex: 'account',
            key: '2',
            className:'cursor-pointer',
            filters: filters('account'),
            onFilter: (value, record) => typeof value === 'string' && record.account === value,
                 
        },
        {
          title: 'Saldo',
          dataIndex: 'balance',   
          sorter: (a, b) => Number(a.balance) - Number(b.balance),            
          render:  (text, record )=>
            <RenderBalance 
                id={record.id} 
                balance={record.balance} 
                sign={record.currency}
                update={updateAccount}
            />,
          key: '3',
          width: 310,      
        }, 
      ];

    const filtersCheckout = (
        type: 'name' | 'branch'
      ) => {
        let textAndValue: { text: string, value: string }[] = [];
        let uniqueValues = new Set();
  
        dataApiCheckouts?.forEach((item) => {
          let code = ''
          let value = ''
          switch(type){
            case 'name':
                code = item.name
                value =  item.id.toString()
                break; 
            case 'branch':
                    code = item.branch.name
                    value =  item.branch.name
                    break;                               
            default:
                code ='';
                value = ''
                break               
          }
        
          if (code && !uniqueValues.has(code)) {
              uniqueValues.add(code);
              textAndValue.push({
                  text: code,
                  value: value
              });
          }
  
        });
        textAndValue.sort((a, b) => {
        if (!isNaN(Number(a.text)) && !isNaN(Number(b.text))) {
            return Number(a.text) - Number(b.text); // Para números
        } else {
            return a.text.localeCompare(b.text); // Para strings
        }
        });
        return textAndValue;
    } 

    const confirmOk = () =>{
        initialize({initialization_mode: false}) //cambia el estado de 'modo inicializacion' porque está en edición a 'inicializado' 
    }

    const columnsCheckouts: ColumnsType<DataTypeCheckout> = [
        {
            title: 'Sucursal',
            dataIndex: 'branch',
            key: '1',
            className:'cursor-pointer',
            filters: filtersCheckout('branch'),
            onFilter: (value, record) => typeof value === 'string' && record.branch === value,        
        },
        {
          title: 'Caja',
          dataIndex: 'name',
          key: '2',
          className:'cursor-pointer',
          filters: filtersCheckout('name'),
          onFilter: (value, record) => typeof value === 'string' && record.id === value,
        },        
        {
            title: 'Saldo',
            dataIndex: 'balance',          
            render:  (text, record )=>
            <RenderBalance 
                id={record.id} 
                balance={record.balance} 
                sign={record.sign}
                update={updateCheckout}
            />,
            sorter: (a, b) => Number(a.balance) - Number(b.balance),  
            key: '3',
            width: 310,      
        }, 
      ];

    const confirm = () =>{
        Modal.confirm({
            title: 'Iniciar apliación',
            okText: 'Guardar y confirmar',
            cancelText: 'Cancelar',
            onOk:()=> confirmOk(),
            content:(
                <div>
                    <p><strong>Una vez que inicie la aplicación, esta cambiará a modo productivo y podrá comenzar a operar. Tenga en cuenta que, una vez iniciada, no podrá modificar los saldos.</strong></p>
                    <p>¿Está seguro que desea comenzar?</p>
                </div>
            )
        })
    }

    return(
        <>    
            { 
                showForms.funds_movements && 
                <FundsMovementForm        
                    form={form_funds_movements}
                />
            }    
        <div className="allCheckout_section allCheckout_section_container hover_row">
            <Row
                className='global_title_section'  
            >
                <Col
                    style={{
                        display: 'flex',
                        alignItems: ' center',
                    }}
                    >
                    <h3> <CaretRightOutlined style={{fontSize: 18, marginRight: '10px', color: '#37bde0'}}/>INICIALIZAR SALDOS</h3> 
                    
                </Col>
            </Row>   
            <Row 
                className='global_button_box'
                style={{
                display:'flex',
                justifyContent: 'space-between',
                alignItems:'center'
                }}
            >              
            <Col >           
                <Button 
                    type='primary'
                    className='allCheckout_section_btn'
                    onClick={confirm}
                    style={{marginRight: 10}}
                    loading={loading}
                >                    
                    INICIAR                        
                    <CaretRightOutlined style={{fontSize: 18, marginRight: '0px', color: 'white'}}/>
                </Button>                                                                     
                <Tooltip
                    title= 'Si haces click en iniciar, cambiara el estado de la aplicación a modo productivo y podrá comenzar a operar. Una vez iniciada la aplicación, no podrá volver a editar los saldos.'
                     placement="right"
                    className='cursor-pointer'
                >
                    <ExclamationCircleOutlined style={{color: 'orange'}}/>
                </Tooltip>
                                        
            </Col>                          
            </Row>
            <Row 
                style={{
                    display:'flex',
                    justifyContent: 'center',
                    width: '100%',
                    height: '85%',
                    padding: '0px 30px',                        
                    border: '1px solid   #645fca6c',
                    overflowY: 'auto'
                }}
            >
                <Row
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '100%'
                    }}
                >
                    <h3 style={{color: 'white'}}>
                        CUENTAS CLIENTES
                    </h3>
                    <Table 
                        style={{
                            width: '70%',
                            marginTop: '1%',
                        }}                    
                        columns={columns} 
                        dataSource={dataTable}  
                        scroll={{ y: adjustedTableHeigthScroll, x: 500 }}                                   
                        pagination={{
                            pageSize: 50,
                            showSizeChanger: false,                                      
                        }}
                
                    /> 
                </Row> 
                <Row
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '100%'
                    }}
                >  
                    <h3 style={{color: 'white'}}>
                        CAJAS
                    </h3>            
                    <Table 
                        style={{
                            width: '70%',
                            marginTop: '1%',
                        }}                    
                        columns={columnsCheckouts} 
                        dataSource={dataTableCheckout}  
                        scroll={{ y: adjustedTableHeigthScroll, x: 500 }}                                   
                        pagination={{
                            pageSize: 50,
                            showSizeChanger: false,                                      
                        }}
                
                    /> 
                
                </Row>
          </Row>
      </div>

  </>
    )
}