import React, {
    createContext,
    useState,
  } from 'react';
import { DataType_AllOperation } from '../../Components/Sections/AllCheckouts/types';

  class VoidContext implements VariablesContext {
    get collapsedFilsters(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setCollapsedFilters(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get showForms(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setShowForms(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get selectedRowId(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setSelectedRowId(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get modalManager(): never {
      throw new Error('Cannot consume context outside of provider');
    }
  }

  interface VariablesContext {
    collapsedFilsters: boolean,
    setCollapsedFilters: Function,
    showForms: ShowFormsProps 
    setShowForms: Function,
    selectedRowId: {id: string} | undefined,
    setSelectedRowId: Function,
    modalManager: Function
  }

  interface propsWithChildren {
    children: React.ReactNode
  }

  export interface ShowFormsProps{
    deposit_extraction: boolean,
    transferer: boolean,
    buy_and_sell: boolean,
    transfers_between_cashs: boolean,
    transfer_to_suppliers: boolean,
    funds_movements: boolean,
    isEdition: boolean,    
    current_account_commission: boolean
  }
  
  export const VariablesContext = createContext<VariablesContext>(new VoidContext());
  export const VariablesContextProvider= ({ children }: propsWithChildren): JSX.Element => {

    const [collapsedFilsters, setCollapsedFilters] = useState(false)
    const [selectedRowId, setSelectedRowId] = useState<{id: string}>()
    const [showForms, setShowForms] = useState<ShowFormsProps>({
      deposit_extraction: false,
      transferer: false,
      buy_and_sell: false,
      transfers_between_cashs: false,
      transfer_to_suppliers: false,
      funds_movements: false,
      isEdition: false,    
      current_account_commission: false
    })

    const modalManager = (record: DataType_AllOperation) =>{
      if(record && record.id){
        switch(record.operation_type_id){
          case '1':
          case '2':
          case '3':
          case '4':
          case '7':
            if(record.related_operation_id && record.main_operation_id){
              setSelectedRowId({id: record.main_operation_id})             
            }else{
              setSelectedRowId({id: record.id})            
            }
            break;
          default:
            setSelectedRowId({id: record.id})
            break

        }
        
      }
          
      const isAccountValid = record.origin_account_customer_id !== '0' && record.destination_account_customer_id !== '0';
      const isRelated = !!record.related_operation_id;

      let formStates: ShowFormsProps | undefined
      // Define el estado del formulario
      if(record.operation_type_id){
        formStates = {
          deposit_extraction: ['1', '2', '3', '4'].includes(record.operation_type_id) && !isRelated,
          transfers_between_cashs: record.operation_type_id === '5' && isAccountValid,
          transferer: record.operation_type_id === '6' && isAccountValid,
          buy_and_sell: ['7', '9'].includes(record.operation_type_id) || isRelated,
          transfer_to_suppliers: ['10', '11'].includes(record.operation_type_id) && isAccountValid, 
          funds_movements: ['14', '15'].includes(record.operation_type_id), 
          isEdition: true,
          current_account_commission: ['16'].includes(record.operation_type_id)
        };
      } 
      
      if(formStates){
        setShowForms(formStates);
      }                                                       
    }
 
    return (
      <VariablesContext.Provider value={{
        collapsedFilsters, 
        setCollapsedFilters,
        showForms, 
        setShowForms,
        selectedRowId,
        setSelectedRowId,
        modalManager
      }}>{children}</VariablesContext.Provider>
    );
  };



  