import React, { useContext, useEffect, useState } from 'react';
import './styles.scss'
import {  Button, Col, Modal, Row, Select, Table, Form, Tooltip, Flex } from 'antd';
import {
    DeleteOutlined,
    ExclamationCircleOutlined,
    UserSwitchOutlined,
    WarningOutlined,
  } from '@ant-design/icons';
import { useForm } from 'antd/es/form/Form';
import  { ColumnsType } from 'antd/es/table';
import { SpinerHorizontal } from '../../SpinerHorizontal/SpinerHorizontal';
import { UsersSectionProps, ValuesForm } from './types';
import { Transaction } from '../../../Services/useGetMovements';
import { useGetAllClients } from '../../../Services/useGetAllClients';
import { useGetCurrency } from '../../../Services/useGetCurrency';
import { useOperations } from '../../../Services/useOperations';
import { RenderApproved } from '../../RenderApproved';
import {  ResponseAccounts, useAccounts } from '../../../Services/useAccounts';
import moment from "moment";
import { StylesContext } from '../../../Contexts/UserContext/StylesContext';
import { VariablesContext } from '../../../Contexts/UserContext/VariablesContext';
import { DataType, MovementAccountContext } from '../../../Contexts/UserContext/MovementAcountContext';
import RenderNumOperations from '../../RenderNumOperations/RenderNumOperations';
import RenderOperationType from '../../RenderOperationType';

const MovementsAccount: React.FC<UsersSectionProps> = () => {
 
  const { data: clients } = useGetAllClients()
  const { data: currencies } = useGetCurrency()
  const { Option } = Select;
  const { getOperationAmount, deleteOperation } = useOperations()
  const { getAccountsCurrencyAndCustomer } = useAccounts()    
  const { adjustedTableHeigthScroll } = useContext(StylesContext) 
  const { modalManager } = useContext(VariablesContext)
  const {
    dataTable, 
    setDataTable,
    mutate, 
    setMutate,
    showingAccount, 
    setShowingAccount,
    balance, 
    setBalance
  } = useContext(MovementAccountContext)

  const [form_movements_amount] = useForm()
  const [loadingTableData, setLoadingTableData] = useState<boolean>(false)
  const [rowSelect, setRowSelect] =useState<DataType | undefined>()    
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)
  const [loadingBtn, setLoadingBtn] = useState<{
      search: boolean,
      refresh: boolean
  }>({
      search: false,
      refresh: false
  })

  const errorCustomers = 'NO HAY CLIENTES CREADOS PARA HACER UNA BÚSQUEDA. CREE UNO EN: ADMINISTRACIÓN => CLIENTES.'
  const errorMoney = 'NO HAY MONEDAS CREADAS PARA HACER UNA BÚSQUEDA. CREE UNA EN: ADMINISTRACIÓN => MOEDAS.'

  const mandatory = [
    {
      required: true,
      message:'Este campo es obligatorio'
    }
  ] 

  useEffect(()=>{
    if(mutate){
      handleSearch(false, false)
      setMutate(false)
    }
  },[mutate])

  useEffect(()=>{
    if(showingAccount){
      let newData = {
        customer:{
            value: showingAccount.user,
            label: showingAccount.user,
            key: showingAccount.user_id
          },
          currency:{
            value: showingAccount.sign,
            label:  showingAccount.sign,
            key: showingAccount.sign_id
          }
      }
      form_movements_amount.setFieldsValue(newData)
    }
  },[])

  const handleSearch = async(loading: boolean, loadingRefresh: boolean ) =>{
    if(loading){
      setLoadingTableData(true)
    }   
    if(loadingRefresh){
      setLoadingBtn({
        search: false,
        refresh: true
      })
    }  
      
    let formData:{customer: ValuesForm, currency: ValuesForm}  = form_movements_amount.getFieldsValue()
    let currency =  formData.currency   
    let customer =  formData.customer    
    if(formData && formData.currency && formData.customer){
        
      checkAccountBalance()
      let values ={
        customer_id: customer.key,
        currency_id: currency.key, 
        checkout_id: undefined,   
        start_date: undefined,
        end_date: undefined,
        operation_type: undefined,
        branch_id: undefined
      }
      const data = await getOperationAmount(callbackEliminate, values )
      if(data){
        setShowingAccount({
          user_id: customer.key,
          user: customer.label,
          sign: currency.label,
          sign_id: currency.key
        })
        rowData(data)
      }   
    } 
    if(loadingRefresh){
      setLoadingBtn({
        search: false,
        refresh: false
      })
    }  
  }

  const checkAccountBalance =  React.useCallback(async()=>{             
    let formData:{customer: ValuesForm, currency: ValuesForm}  = form_movements_amount.getFieldsValue()
    if(formData){
      let currency_id = formData && formData.currency &&  formData.currency.key   
      let customer_id = formData && formData.customer &&  formData.customer.key   
      if(customer_id && currency_id){
          const data = await getAccountsCurrencyAndCustomer(customer_id, currency_id)
          if(data && data){
              setBalance((prevData: ResponseAccounts) => {
                  if (JSON.stringify(prevData) !== JSON.stringify(data)) {
                      return data;
                  }
                  return prevData; // No actualizar si son iguales
              });
          }
      }   
    }        
  },[form_movements_amount, getAccountsCurrencyAndCustomer])  

  const getBySData = (list: Transaction)=>{    
    let client_balance = 0;
    let debit 
    let credit
    let formData: { customer: ValuesForm, currency: ValuesForm } = form_movements_amount.getFieldsValue();

    if (formData) {
      let currency = formData.currency;
      let customer = formData.customer
      if (list.destination_account?.currency && (list.destination_account.currency.id === Number(currency?.key) && list.destination_account.customer.id === Number(customer?.key))   ) {
        client_balance = list.destination_account_balance;
        credit = list.credit
      } else if (list.origin_account?.currency && (list.origin_account.currency.id === Number(currency?.key) && list.origin_account.customer.id === Number(customer?.key))  ) {
        client_balance = list.origin_account_balance;
        debit = list.debit
      }
    }

    return{
      client_balance,
      debit,
      credit
    }
  }

  const getDataDetails = async(list: Transaction) =>{
   let accountData = {
      debit: getBySData(list).debit || '',
      credit: getBySData(list).credit || '',
      balance: getBySData(list).client_balance || '',
    }; 
    
    return(accountData)
  }

  const rowData = async (data: Transaction[]) => {
    if (!data) return; 
    const promises = data.map(async (transaction) => {
      const accountData = await getDataDetails(transaction);
      let created_by: string | undefined
      let updated_by: string | undefined
      let created_at_hr: string | undefined = moment(transaction.created_at).format('HH:mm:ss') || undefined
      let updated_at_hr: string | undefined = moment(transaction.updated_at).format('HH:mm:ss') || undefined

        return {
          associated_operation: transaction.related_operation_user_id?.toString() || undefined,
          id: transaction.id.toString(),
          operation_id: transaction.operation_id?.toString() || '',
          operation_type_id: transaction.type.id.toString(),
          operation_type_name: transaction.type.name,       
          credit: transaction.credit || '',        
          debit: transaction.debit || '',
          account_credit: accountData.credit || '',   
          account_debit: accountData.debit || '',
          origin_account_id: transaction.origin_account?.id?.toString() || '',
          origin_account_descriptrion: transaction.origin_account?.description || '',
          origin_account_currency_name: transaction.origin_account?.currency?.name || '',
          origin_account_currency_sign: transaction.origin_account?.currency?.sign || '', 
          origin_account_currency_id: transaction.origin_account?.currency?.id?.toString() || '',
          origin_balance: transaction.origin_account?.balance?.toString() || '',           
          destination_account_id: transaction.destination_account?.id?.toString() || '',
          destination_account_descriptrion: transaction.destination_account?.description || '',
          destination_account_currency_id: transaction.destination_account?.currency?.id?.toString() || '',
          balance: accountData?.balance,
          destination_account_currency_name: transaction.destination_account?.currency?.name || '',
          destination_account_currency_sign: transaction.destination_account?.currency?.sign || '',   
          destination_balance: transaction.destination_account?.balance?.toString() || '',                                    
          customer_id: transaction.customer?.id?.toString() || '',
          customer_first_name: transaction.customer?.first_name || '',
          customer_last_name: transaction.customer?.last_name || '',            
          user_id: transaction.user?.id?.toString() || '',
          user_name: transaction.user?.first_name || '',
          user_last_name: transaction.user?.last_name || '',          
          description: transaction.description || '',
          currency_sale: transaction.currency_sale ? 'true' : 'false',
          approved: transaction.approved ? 'true' : 'false',
          hours: updated_at_hr ? updated_at_hr : created_at_hr,
          is_deleting_operation: transaction.is_deleting_operation ? 'true' : 'false',
          is_sale: transaction.currency_sale ? 'true' : 'false',
          main_currency_id: transaction.main_currency?.id.toString() || '',
          main_currency_sign: transaction.main_currency?.sign || '',
          main_operation_id: transaction.main_operation_id?.toString() || '',
          deleted: transaction.deleted ? 'true' : 'false',
          date: moment(transaction.updated_at).format('DD-MM-YYYY') || moment(transaction.created_at).format('DD-MM-YYYY'),        
          destination_checkout_name: transaction.destination_checkout?.name || '',
          destination_checkout_id: transaction.destination_checkout?.id?.toString() || '',
          destination_checkout_balance: transaction.destination_checkout?.balance?.toString() || '',  
          destination_checkout_currency_id: transaction.destination_checkout?.currency.id.toString() || '',
          destination_checkout_currency_sign: transaction.destination_checkout?.currency?.sign || '',
          destination_checkout_currency_name: transaction.destination_checkout?.currency?.name|| '', 
          origin_checkout_branch_id: transaction.origin_checkout?.branch?.id?.toString() || '',
          origin_checkout_branch: transaction.origin_checkout?.branch?.name || '',                   
          origin_checkout_name: transaction.origin_checkout?.name || '',
          origin_checkout_id: transaction.origin_checkout?.id.toString() || '',
          origin_checkout_balance: transaction.origin_checkout?.balance?.toString() || '',
          origin_checkout_currency_id: transaction.origin_checkout?.currency?.id?.toString() || '',
          origin_checkout_currency_sign: transaction.origin_checkout?.currency?.sign || '',
          origin_checkout_currency_name: transaction.origin_checkout?.currency?.name|| '',
          credit_number: transaction.credit?.toString() || '',
          debit_number: transaction.debit?.toString() || '',           
          destination_account_customer_name: transaction.destination_account?.customer?.first_name || '',
          destination_account_customer_last_name: transaction.destination_account?.customer?.last_name || '',
          destination_account_customer_id: transaction.destination_account?.customer?.id?.toString() || '',
          origin_account_customer_name: transaction.origin_account?.customer?.first_name || '',
          origin_account_customer_last_name: transaction.origin_account?.customer?.last_name || '',
          origin_account_customer_id: transaction.origin_account?.customer?.id?.toString() || '',
          deposit_operation_approved: transaction.deposit_operation_approved ? 'true' : ' false',
          extraction_operation_approved: transaction.extraction_operation_approved ? 'true' : ' false',
          currency_quote_buy_price: transaction.currency_quote_buy_price || undefined,
          currency_quote_sell_price: transaction.currency_quote_sell_price || undefined,
          usdt_sale: transaction.usdt_sale === true ? 'true' : transaction.usdt_sale === false ? 'false' : undefined,
          usdt_quote_percentage: transaction.usdt_quote_percentage ?  transaction.usdt_quote_percentage : undefined,
          can_be_approved: transaction.can_be_approved ? 'true' : ' false',
          related_operation_id: transaction.related_operation_id?.toString() || undefined,
          secondary_currency_name: transaction.secondary_currency?.name ||undefined,
          secondary_currency_id: transaction.secondary_currency?.id.toString() || undefined,
          secondary_currency_sign: transaction.secondary_currency?.sign || undefined,        
          supplier_commission: transaction.supplier_commission,
          commission: transaction.commission, 
          transfer_costs: transaction.transfer_costs || undefined,   
          client_pays_costs: transaction.client_pays_costs === true ? 'true' : transaction.usdt_sale === false ? 'false' : undefined,          
          created_by: transaction.created_by?.first_name ||' ' + ' ' + transaction.created_by?.last_name ||' ',
            updated_by: transaction.updated_by?.first_name ||' '+ ' ' + transaction.updated_by?.last_name ||' ',
            show_user: updated_by 
              ? updated_by 
              : created_by
                  ? created_by
                  : '', 
        };
      }
    );
  
    try {
      const filterData = await Promise.all(promises);
      setDataTable([...filterData]);
    } catch (error) {
      console.error('Error processing row data:', error);
    }
  };  


  const filters = (type: 'operation_id' | 'operation_type_name' | 'date') => {
    let textAndValue: { text: string, value: string }[] = [];
    let uniqueValues = new Set();  
    let hasEliminated = false; // Bandera para saber si hay eliminados
  
    dataTable?.forEach((item) => {
      let code: string;
      let value: string;
      
      switch (type) {
        case 'operation_id':
          if (item.operation_id! === 'NC') {
            code = 'Nota de crédito';
            value = 'NC';
          } else if (item.operation_id!.includes('(E)')) {
            // Si contiene (E), es una operación eliminada
            if (!hasEliminated) {
              // Agregar opción general para "Eliminadas" si no está ya agregada
              textAndValue.push({
                text: 'Eliminadas',
                value: 'Eliminadas'
              });
              hasEliminated = true;
            }
            // Añadir la operación eliminada con su número, como "25 (E)"
            code = item.operation_id!;
            value = item.operation_id!;
          } else {
            code = item.operation_id!;
            value = item.operation_id!;
          }
          break;
        case 'operation_type_name':
          code = item.operation_type_name!;
          value = item.operation_type_name!;
          break;
        case 'date':
          code = item.date!;
          value = item.date!;
          break;
      }
  
      if (!uniqueValues.has(value)) {
        uniqueValues.add(value);
        textAndValue.push({
          text: code,
          value: value
        });
      }
    });
  
    // Insertar "Activos" al principio solo cuando el tipo sea 'operation_id'
    if (type === 'operation_id') {
      textAndValue.unshift({
        text: 'Activos',
        value: 'Activos'
      });
    }  
    return textAndValue;
  };

 

  const callbackEliminate = () =>{
      setLoadingTableData(false)
      setRowSelect(undefined)        
      setShowConfirmDelete(false)
  }

  const handleRenderer = (text: string, record: DataType) =>{
    return (
      <div className='currency_action_action_render'>
        {
          record.deleted === 'true' || 
          record.is_deleting_operation === 'true' ||
          record.related_operation_id 
            ? <></>
            :  <DeleteOutlined 
            onClick={()=>{
                setRowSelect(record)
                setShowConfirmDelete(true)
            }}
            style={{marginLeft:'30px', color: '#37bde0'}} /> 
        }          
      </div>  
    )      
  }
    
  const ModalConfirmDelete = React.memo( () => {
    return(
      <Modal
          className='movements_section_icon'
          title="Eliminar"
          open={showConfirmDelete}
          onOk={async()=>{
              if(rowSelect && rowSelect.id){
                const data = await deleteOperation(callbackEliminate, +rowSelect.id)
                if(data){
                  handleSearch(false, false)
                }
              }}}
          onCancel={()=>setShowConfirmDelete(false)}
          okText="Eliminar"
          cancelText="Cancelar"
      >
          <p>¿Esta seguro de que desea eliminar operación N°{rowSelect?.operation_id}?</p>
      </Modal>
    )
  })

  const handleRender = (text: string, record: DataType) => {
    const baseClass =
      record.deleted === 'true'
        ? 'class_deleted'
        : record.is_deleting_operation === 'true'
        ? 'class_is_deleting_operation'
        : 'class_false';
  
    return (
      <div className={`${baseClass} hover_row`}>
        {text}
      </div>
    );
  };


  const HandleRenderBalance = React.memo(({ text, record }: { text: string, record: DataType }) => {
    const baseClass =
      record.deleted === 'true'
        ? 'class_deleted'
        : record.is_deleting_operation === 'true'
        ? 'class_is_deleting_operation'
        : 'class_balance';
  
    const currencySign = currencies.find(x => x.id === balance?.currency_id)?.sign || '';
    const isNegative = text.startsWith('-');
    const cleanText = isNegative ? text.slice(1) : text;
  
    return (
      <div className={`${baseClass} hover_row`}>
        {isNegative && '-'}
        {cleanText !== '' &&  currencySign} {cleanText ? Number(cleanText).toLocaleString('es-AR', {maximumFractionDigits: 2, minimumFractionDigits: 2}) : ''}
      </div>
    );
  });

    const RenderCustomer = React.memo(({
      text,
      record, 
    }: {
      text: string, 
      record: DataType, 
    }) =>{
      let customer: string =  '' 
      let tooltip: string = ''
    
        switch(record.operation_type_id){
          case '6':
            if(showingAccount){
              if(record.destination_account_customer_id = showingAccount.user_id.toString()){
                customer = record.origin_account_customer_name + ' ' + record.origin_account_customer_last_name
                tooltip = 'Transferencia de ' + customer
              }else{
                customer = record.destination_account_customer_name + ' ' + record.destination_account_customer_last_name 
                tooltip = 'Trasnferencia a ' +  customer               
              }
            }
            break;
          case '10': //depósito proveedor  - cuenta destino coincide con proveedor      
            if(showingAccount){
                if(record?.destination_account_customer_id === showingAccount.user_id.toString()){
                  customer = record.origin_account_customer_name + ' ' + record.origin_account_customer_last_name
                  tooltip = 'Ttrasnferencia  de ' +record.origin_account_customer_name + ' ' + record.origin_account_customer_last_name
                }else{
                  customer = record?.destination_account_customer_name + ' ' + record?.destination_account_customer_last_name
                  tooltip = 'Transferencia  de ' + record?.destination_account_customer_name + ' ' + record?.destination_account_customer_last_name
                }
            } 
            break; 
          case '11':  //extraccion proveedor - cuenta origen coincide con proveedor
            if(showingAccount){
              if(record?.origin_account_customer_id===showingAccount.user_id.toString()){
                customer =record.destination_account_customer_name + ' ' + record.destination_account_customer_last_name
                tooltip = 'Ttransferencia  a ' + record.destination_account_customer_name + ' ' + record.destination_account_customer_last_name
              }else{
                customer = record?.origin_account_customer_name + ' ' + record?.origin_account_customer_last_name
                tooltip = 'Transferencia  a ' + record?.origin_account_customer_name + ' ' + record?.origin_account_customer_last_name
              }
            }   
            break; 
        }    
      return (
        <Tooltip
          title= {tooltip}
        >
          <span style={{cursor: 'pointer'}}>{customer}</span>
        </Tooltip>
      )             
    })
    
    
  const RenderDebitorCredit = React.memo(({text, record, type}:{text: string, record: DataType, type?: 'debit' | 'credit'}) => {
      const baseClass =
        record.deleted === 'true'
          ? 'class_deleted'
          : record.is_deleting_operation === 'true'
            ? 'class_is_deleting_operation'
            : '';
    
      const textColorClass = baseClass
        ? baseClass // Si hay un baseClass, usarlo
        : type === 'debit'
          ? 'text-orange'
          : type === 'credit'
            ? 'text-green'
            : 'class_false'; // Si no hay baseClass, usar el color por tipo
    
      return (
        <div className={`${textColorClass} hover_row`}>
          {text && text !== '0,00' && type === 'debit' && '- '}
          {text && currencies.filter(x => x.id === balance?.currency_id)[0]?.sign }
          {' '}
          {text ? Number(text).toLocaleString('es-AR',{minimumFractionDigits: 2, maximumFractionDigits: 2}): ''}
        </div>
      );
    })

  const columns: ColumnsType<DataType> = [
    {
      title: 'N° ',
      dataIndex: 'operation_id',
      width: 70,
      key: '1',
      fixed: 'left',
      className: 'cursor-pointer',
      render: (text, record)=> <RenderNumOperations text={text} record={record}/>,
      filters: filters('operation_id'),
      onFilter: (value, record) => {
        if (value === 'Eliminadas') {
          // Filtrar todas las operaciones que contengan "(E)" en el operation_id
          return record.operation_id!.includes('(E)');
        }
        if (value === 'Activos') {
          // Filtrar todos los que NO sean eliminados ni NC
          return !record.operation_id!.includes('(E)') && record.operation_id! !== 'NC';
        }
        // Filtrar por un número específico de operación eliminada o normal
        return typeof value === 'string' && (record.operation_id! === value || record.operation_id!.includes(value));
      },
      filterSearch: true,     
    },    
    {
      title: 'OA',
      key: '2',
      dataIndex: 'associated_operation',
      width: 70,
      className: 'cursor-pointer',
      fixed: 'left',
    },
    {
      title: 'Fecha',
      dataIndex: 'date',
      filters: filters('date'),
      onFilter: (value, record) => typeof value === 'string' && record.date === value,
      filterSearch: true,
      width: 120,
      key: '3',
      className:'cursor-pointer',   
      render: handleRender,  
    },    
    {
      title: 'Hora',
      key: '3',
      className:'cursor-pointer text-center',
      dataIndex: 'hours',
      width: 100,      
    },
    {
      title: 'Tipo de operación',
      dataIndex: 'operation_type_name',
      filters: filters('operation_type_name'),
      onFilter: (value, record) => typeof value === 'string' && record.operation_type_name === value,
      filterSearch: true,
       render: (text, record)=>{return<RenderOperationType text={text} record={record}/>},
      width: 200,
      key: '5',
      className:'cursor-pointer',    
    },
    {
      title: 'Cliente asociado',
      dataIndex: '6',
      width: 150,
      ellipsis: true,
      className: 'cursor-pointer',
      render: (text: string, record: DataType)=><RenderCustomer text={text} record={record} />
    },   

    {
      title: 'Descripción',
      dataIndex: 'description',
      render: handleRender,
      key: '7',
      className:'cursor-pointer',  
      ellipsis: true   
    },
    {
      title: 'Débito',
      dataIndex: 'account_debit',
      key: '8',
      width: 120,
      render: (text, record)=><RenderDebitorCredit text={text} record={record} type={'debit'}/>,
      className:'cursor-pointer'
    },
    {
      title: 'Crédito',
      dataIndex: 'account_credit',
      key: '9',      
      width: 120,
      render: (text, record)=><RenderDebitorCredit text={text} record={record} type={'credit'}/>,      
      className:'cursor-pointer'
    },    
    {
      title: 'Saldo',
      dataIndex: 'balance',
      key: '10',   
      width: 140,
      render: (text, record)=> <HandleRenderBalance text={text} record={record}/>,
      className:'cursor-pointer',
    },
    {
      title: 'Aprobado',
      dataIndex: 'approved',
      key: '12',
      width: 100,
      render: (text: string, record: DataType)=> 
        <RenderApproved 
          text={text} 
          record={record} 
          setDataTable={setDataTable} 
          dataTable={dataTable} 
          callback ={handleSearch}
        />,
      className:'cursor-pointer',      
     
    },
    {
      title: 'Eliminar',
      dataIndex: 'id',
      key: '13',        
      render:  handleRenderer,
      width: 100,  
      onCell: (record, rowIndex) => {
        return {
          onClick: () => {
            onClickRow(record)
          }
        };
      }    
    }, 
  ];

  const onClickRow = (record: DataType) =>{         
    if(record &&  record.approved === 'false'){
      setRowSelect(record);
    }  
  }   
  
 return( 
    <>
      <ModalConfirmDelete/>
      <div className='movements_section movements_section_container'>                                  
          <Row
            className='global_title_section'             
          >
            <Col
              style={{
                display: 'flex',
                alignItems: ' center'
              }}
            >
              <h3> <UserSwitchOutlined style={{fontSize: 18, marginRight: '10px', color: '#37bde0'}}/>MOVIMIENTOS CUENTA CLIENTE  </h3>   
              {
                showingAccount && 
                  <h3
                    style={{ margin: '7px 0px 0px 10px', padding: '0px', color: '#bdbdbd'}}
                  >/ {showingAccount?.user}  ({showingAccount?.sign})</h3> 
              }
            </Col>
            <Col
              style={{display: 'flex'}}
            >
              <div 
                style={{
                  display: 'flex', 
                  alignItems: 'center', 
                  backgroundColor: balance && balance?.balance < 0 ? 'red' : '#37bee042', 
                  borderBottom:`2px solid ${balance && balance?.balance < 0 ? '#ff7c7c' : '#37bde0'}`, 
                  paddingLeft: '15px', 
                  marginRight: '30px',
                  borderRadius: '5px',
                  height: '32px',
                  width: 'auto',
                  minWidth: '210px'
                }}>
                <h3 
                  style={{
                    margin: '0px',
                    
                  }}
                >
                  Saldo: {currencies && balance && currencies.filter(x => x.id === balance?.currency_id)[0]?.sign }  {' '}                                    
                </h3>
                <h3
                  style={{
                    marginLeft: '5px',
                    color: 'white'
                  }}
                >
                  {balance? new Intl.NumberFormat('es-AR', { style: 'decimal', minimumFractionDigits: 2 }).format(balance?.balance) : ''}
                </h3>
              </div>  
              <div 
                style={{
                  display: 'flex', 
                  alignItems: 'center', 
                  backgroundColor: balance && balance.future_balance > balance?.balance ? 'rgba(47, 202, 133, 0.50)' : balance &&  balance.future_balance < balance?.balance ? 'rgba(255, 166, 0, 0.70)' :'#80808098', 
                  borderBottom: `2px solid ${balance && balance.future_balance > balance?.balance ? 'rgb(47, 202, 133)': balance && balance.future_balance < balance?.balance ?  'orange' : 'gray'}`,
                  paddingLeft: '15px', 
                  marginRight: '30px',
                  borderRadius: '5px',
                  height: '32px',
                  width: 'auto',
                  minWidth: '220px'
                }}>
                <h3 
                  style={{
                    margin: '0px',
                    
                  }}
                >
                  S. Futuro: {currencies && balance && currencies.filter(x => x.id === balance?.currency_id)[0]?.sign }  {' '}                                    
                </h3>
                <h3
                  style={{
                    marginLeft: '5px',
                    color: 'white'
                  }}
                >
                  {balance? new Intl.NumberFormat('es-AR', { style: 'decimal', minimumFractionDigits: 2 }).format(balance.future_balance) : ''}
                </h3>
              </div> 
            </Col>                       
          </Row>
          <Row 
            className='global_button_box'
            style={{
              display:'flex',
              flexDirection: 'column'
              // justifyContent: 'space-between',
              // alignItems:'center'
            }}
          >
            <Col >
              <Form
                layout='inline'
                onFinish={()=>handleSearch(true, false)}
                id='form_movements_amount' 
                form={form_movements_amount}
              >
                <Form.Item
                    name='customer'
                    rules={mandatory}
                    initialValue={showingAccount ? {
                      value: showingAccount.user,
                      label: showingAccount.user,
                      key: showingAccount.user_id
                    }: undefined}
                >
                  <Select                           
                    labelInValue  
                    loading={!clients }
                    placeholder={!clients ? 'Cargando campos...' : 'Cliente'}      
                    style={{minWidth: '200px'}}   
                    showSearch                                   
                  >
                      {clients && clients.map((item) => (
                          <Option value={item.first_name + ' ' + item.last_name} key={item.id} >
                            {item.first_name + ' ' + item.last_name}
                          </Option>
                      ))} 
                  </Select>
                </Form.Item>
                <Form.Item
                  name='currency'
                  rules={mandatory}
                  initialValue={showingAccount ? {
                      value: showingAccount.sign,
                      label:  showingAccount.sign,
                      key: showingAccount.sign_id
                    }: undefined}
                >
                  <Select                           
                      labelInValue  
                      loading={!currencies || currencies.length === 0}
                      placeholder={!currencies || currencies.length === 0 ? 'Cargando monedas...' : 'Moneda'}                       
                      style={{minWidth: '105px'}}
                      showSearch
                  >
                      {currencies && currencies && currencies.map((item) => (
                          <Option value={item.name} key={item.id} sign={item.sign} >
                            {item.name}
                          </Option>
                      ))} 
                  </Select>
                </Form.Item>
                <Form.Item>
                  <Button
                    type='primary'
                    className='movements_section_btn'
                    htmlType='submit'
                    loading={loadingBtn.search}
                  >
                    Buscar
                  </Button>
                </Form.Item>
                <Button 
                    type='primary'
                    className='allCheckout_section_btn'
                    onClick={()=>handleSearch(false, true)}
                    //disabled={!branchSelected}
                    style={{marginRight: 10}}
                    loading={loadingBtn.refresh}
                >
                    Refresh                        
                </Button> 
                <Tooltip
                  title= 'Seleccione un cliente y la moneda para buscar los movimientos correspondientes a esa cuenta.'
                  className='cursor-pointer'
                >
                  <ExclamationCircleOutlined style={{color: 'orange'}}/>
                </Tooltip>
              </Form>                               
            </Col>          
            {
              clients && clients.length === 0 &&
              <span style={{color: '#ff6161', fontFamily: 'monospace'}}>
                <WarningOutlined style={{marginRight: '10px', marginTop: '10px', color: '#71e3ff '}}/>{errorCustomers}
              </span>
            } 
            { 
              currencies && currencies.length===0 &&
              <span style={{color: '#ff6161', fontFamily: 'monospace'}}>
                <WarningOutlined style={{marginRight: '10px', marginTop: '10px',color: '#71e3ff '}}/>{errorMoney}
              </span>
            }                                                                     
          </Row>
          <Row 
            style={{
                display:'flex',
                justifyContent: 'center',
                width: '100%',
                height: '85%',
                padding: '0px 30px',                        
                border: '1px solid   #645fca6c'
            }}
          >
            { loadingTableData && <SpinerHorizontal/>}
            { !loadingTableData && showingAccount
                ?
                  <Table 
                    style={{
                        width: '100%',
                        marginTop: '1%'
                    }}
                    columns={columns} 
                    dataSource={dataTable}  
                    scroll={{ y: adjustedTableHeigthScroll, x: 1500 }}                                   
                    pagination={{
                        pageSize: 50,
                        showSizeChanger: false,                                      
                    }}
                    onRow={(record, ) => ({
                      onClick: (event) => {
                        if(record.is_deleting_operation === 'true'){
                          return
                        }  
                        if (record.operation_type_id !== '12' && record.operation_type_id !== '13' && record.operation_type_id !== '8') {
                          const approvedCell = event.currentTarget.querySelector('td:nth-child(11)');
                          const deleteCell = event.currentTarget.querySelector('td:nth-child(12)');
                      
                          // Verifica si el clic ocurrió en las celdas "Aprobado" o "Eliminar"
                          if ((approvedCell && approvedCell.contains(event.target)) || (deleteCell && deleteCell.contains(event.target))) {
                            return;
                          }
                      
                          onClickRow(record);
                          modalManager(record)  
                        }
                      },
                    })}
                  /> 
                : 
                 <>
                 </>
            }                             
          </Row>             
      </div>
    </>
  )
};

export default MovementsAccount;

 