import { useEffect, useState } from "react";
import { useGetCurrency } from "../../../Services/useGetCurrency";
import { CashDeskProps } from "../../../Services/useGetCashDesk";
import { Checkbox, CheckboxProps, Form, FormInstance, Row, Select } from "antd";
import { useCashDesk } from "../../../Services/useCashDesk";
import './styles.scss';
import CheckoutTables from "./CheckoutTables";
import { FormItemsProps } from "./types";

interface FormCheckouts{
    extraction_cash:{
        value: string,
        label: string,
        key: number,
        title: string,
    },
    amount: number
}



export const FormItems: React.FC<FormItemsProps> = ({
    setCurrencies,
    formCheks,
    checked,
    setFormChecks,
    is_main,
    operationType,
    transactionsData,
    ListCheckout,
    setListCheckout,
    form_buy_and_sell
}) =>{   

    const { Option } = Select;
    const {data: currencies} = useGetCurrency() 
    const {getCashCurrency} = useCashDesk()     
    
    const [listCahs, setListCash] = useState<CashDeskProps[]>()
    const [spanError, setSpanError] = useState<string>() 

    useEffect(()=>{
        if(transactionsData){
            let buy = form_buy_and_sell.getFieldValue('buy_currency')
            let sell = form_buy_and_sell.getFieldValue('sell_currency')
            if(operationType==='Moneda compra'){
                if( buy?.key){
                    handleGetCashCurrency(buy.key)
                }
                    
            }else{
                if(sell?.key){
                    handleGetCashCurrency(sell.key)

                } 
            }
        }
       
    },[])
    

    const mandatory = [
        {
            required: true,
            message:'Este campo es obligatorio'
        }
    ]   

    const handleGetCashCurrency = async(currency_id: number)=>{               
        const data = await getCashCurrency(Number(currency_id)) 
        if(data){
            setListCash(data)
            if(data.length===0){
                setSpanError(`No existen cajas en moneda ${currencies.find((x)=>x.id===currency_id)?.name}. Cree una en Menú -> Administración -> Cajas`)
            }else{
                if(spanError){
                    setSpanError(undefined)
                }
            }
        }               
    }    

    const onChangeCurrency = async(values: {key:string, label: string}, record: any)=>{   
        let statecurrency = is_main ? 'main_currency' : 'secondary_currency'
        setListCheckout([])
        
        setCurrencies(prev => ({
            ...prev, // Mantiene secondary_currency (y otras posibles propiedades)
            [`${statecurrency}`]: {
                id: values?.key,
                sign: record?.sign || '',
                name: values?.label
            }
        }));
        handleGetCashCurrency(Number(values?.key))               
    } 

    const onChangeDeposit: CheckboxProps['onChange'] = async (e) => {     
        if(operationType == 'Moneda compra'){
            setFormChecks(prev => ({
                ...prev,
                deposit: e.target.checked // Se actualiza la clave correcta dentro del objeto
            }));
        }else if(operationType == 'Moneda venta'){
            setFormChecks(prev => ({
                ...prev,
                extraction: e.target.checked // Se actualiza la clave correcta dentro del objeto
            }));
        } 
                  
    };

    return(
    <Row className="section_buy_or_sell">       
        <div 
            className="section_buy_or_sell_title"
            style={{backgroundColor: operationType==='Moneda compra' ? 'green' : 'red'}}
        >{operationType} </div> 
        <Form.Item
            name={operationType==='Moneda compra' ? 'buy_currency' : 'sell_currency'}
            className='sell_and_buy_divide'
            rules={mandatory}
        >             
            <Select                           
                labelInValue  
                loading={!currencies}
                placeholder={'Moneda'}                       
                style={{
                    minWidth: 105,
                    marginBottom: 8
                }}
                onChange={(values, record)=>{
                    onChangeCurrency(values, record)
                }}
                disabled={transactionsData?.approved}                   
            >
                {currencies && currencies && currencies.filter((x)=> is_main ? true : x.id !== 3)
                    .map((item) => (
                        <Option value={item.name} key={item.id} sign={item.sign} >
                            {item.name}
                        </Option>
                ))} 
            </Select>
        </Form.Item>
        <Checkbox
            checked={checked}
            onChange={onChangeDeposit}
            disabled={transactionsData?.approved || formCheks.isWindow}
            style={{marginBottom: 8}}  
        >
            {operationType === 'Moneda compra' ? 'Depósito en caja' : 'Extracción en caja' }
        </Checkbox>
        {
            checked && 
            <CheckoutTables
                listCheckout={ListCheckout} 
                setListCheckout={setListCheckout}
                checked={checked}
                transactionsData={transactionsData}
                listCahs={listCahs}
            />
        }              
       {checked && spanError && <span style={{color: 'red'}}>{spanError}</span>}              
    </Row>)
}