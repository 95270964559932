import React, {  useContext, useEffect, useState } from "react";
import './styles.scss';
import { Checkbox, CheckboxProps,   Col,   Form, Input, InputNumber, Modal,  Select } from "antd";
import { CurrentAccountComissionProps } from "./types";
import { useGetAllClients } from "../../../Services/useGetAllClients";
import { useOperations } from "../../../Services/useOperations";
import { VariablesContext } from "../../../Contexts/UserContext/VariablesContext";
import { UserContext } from "../../../Contexts/UserContext/UsersContext";
import { Transaction } from "../../../Services/useGetMovements";
import ModalTitle from "../../ModalTitle";
import { OperationsContext } from "../../../Contexts/UserContext/OperationsContext";
import { useGetCurrency } from "../../../Services/useGetCurrency";
import NewCustomer from "../../NewCustomer";

export interface FilterValues{
    value: string;
    label: string;
    key: number;
}

interface inputsForms{
    date: {$d:string}
    client: FilterValues;
    amount: number;
    currency: number;
    description: string; 
}

type OperationData = {
    description: string;
    type_id: number;
    customer_id: number;
    user_id: number;
    approved: boolean;
    currency_id: number;
    commission?: number;     
    debit?: number;
    credit?: number;
    destination_checkout_id?: number;
    origin_checkout_id?: number;
    transfer_costs?: number;
    client_pays_costs?: boolean;
};

export const CurrentAccountComission: React.FC<CurrentAccountComissionProps> =({
    form, 
})=>{
    
    const { data: clients } = useGetAllClients()
    const { setRowSelect } = useContext(OperationsContext)
    const { userData } = useContext(UserContext)
    const { data: currencies} = useGetCurrency()
    const { collapsedFilsters, setCollapsedFilters, setSelectedRowId, selectedRowId} = useContext(VariablesContext)
    const { setShowForms, showForms } = useContext(VariablesContext)
    const { createOperation, editOperation, getOperation } = useOperations() 
    const { Option } = Select;
 
    const [ loadingBtn, setLoadingBtn ] = useState(false)
    const [ aproved, setAproved ] = useState(false)
    const [ spanError, setSpanError ] = useState<string>()
    const [ currency, setCurrency ] = useState<number>()
    const [transactionsData, setTransactionsData] = useState<Transaction>()

    const handleCancel =()=>{
        setLoadingBtn(false)
        setShowForms(Object.fromEntries(Object.keys(showForms).map(key => [key, false])))// pone todos en false
        if(selectedRowId){
            setSelectedRowId(undefined)  
        }       
        setRowSelect(undefined) 
    }

    const mandatory = [
        {
            required: true,
            message:'Este campo es obligatorio'
        }
    ]    

    useEffect(() => {
        if(selectedRowId){
            uploadForm(selectedRowId) 
        }else{
            form.resetFields(undefined)            
        }       
    }, []);

    const uploadForm = async(data:{id:string}) =>{
        const response = await getOperation(+data.id)
        if(response){    
            setTransactionsData(response)
            setAproved(response.approved)                
            setCurrency(response.origin_account?.currency?.id )        

            form.setFieldsValue({
                client: {
                    label: response.customer.first_name + ' ' +  response.customer.last_name,
                    value: response.customer.first_name + ' ' +  response.customer.last_name,
                    key: response.customer.id
                },       
                currency: response.origin_account?.currency?.id,
                amount: response.debit,

            }); 
        }            
                        
    }

    const confirmSave = async(operationData: OperationData)=>{        
        let newData
        if(selectedRowId){
            newData = await editOperation(handleCancel, selectedRowId.id!, operationData)
        }else{
            newData = await createOperation(handleCancel, operationData)
        }  
        setCollapsedFilters(!collapsedFilsters)                    
    }

    const handleSave = async (e: inputsForms)=>{
        if(userData){
            let operationData: OperationData = {
                description: e.description,
                type_id: 16, //Comisión en cuenta cliente
                customer_id: Number(e.client.key),
                currency_id: e.currency, 
                user_id: userData?.id,
                debit: Number(e.amount),  
                approved: aproved
            }
            Modal.confirm({
                title: 'Confirmar operación',
                okText: 'Confirmar',
                cancelText: 'Cancelar',
                onOk:()=> operationData && confirmSave(operationData),
                content: (
                    <div>
                        <p><strong>Comisión en cuenta corriente</strong></p>
                        {operationData.customer_id && <p><strong>Cliente: </strong> {e.client.label}</p>}                                         
                        <p><strong>Monto a debitar: </strong>{currency && currencies  && currencies.find((x)=>x.id === currency)?.sign} {Number(e.amount).toLocaleString("es-AR",{maximumFractionDigits:2, minimumFractionDigits:2})}</p>
                        <p>
                            <strong>Estado: </strong>
                            <strong style={{ color: operationData.approved ? 'green' : 'orange' }}>
                                {operationData.approved ? 'Aprobado' : 'Sin aprobar'}
                            </strong>
                        </p>                                                                                                                  
                    </div>
                ),
            }); 

        }               
    }    

    const onChangeCurrency = async(e: number) =>{
        setCurrency(e)        
    }
 
    const onChangeAproved: CheckboxProps['onChange'] = async (e) => {
        setAproved(e.target.checked); 
    };

    return(
        <div>
            <Modal
                style={{ top: 10 }}
                title= {
                    <ModalTitle 
                        title={'Comisión en cuenta corriente cliente'} 
                        isApproved={transactionsData && transactionsData.approved ? true : false}
                        isDeleted={transactionsData && transactionsData.deleted ? true : false}
                        operationNumber={transactionsData ? transactionsData.operation_id: undefined}
                    />
                }
                open={showForms.current_account_commission }
                onCancel={handleCancel}  
                //confirmLoading={loadingBtn}                   
                okText="Guardar"
                cancelText="Cancelar" 
                onOk={()=>form.submit()}
                footer={transactionsData?.approved ? null : undefined} 
            >
                <NewCustomer justify={"end"} positions= {'leftTop'} />
                <Form
                    form={form}
                    id='form_deposit_extraction'
                    onFinish={handleSave}
                    layout="vertical"
                    
                >                                                        
                    <Form.Item
                        label='Cliente'
                        name='client'
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                    >
                        <Select
                            disabled={transactionsData?.approved}                           
                            labelInValue  
                            loading={!clients }
                            placeholder={!clients ? 'Cargando campos...' : 'Cliente'}      
                            style={{minWidth: '200px'}} 
                            showSearch                                
                        >
                            {clients && clients.map((item) => (
                                <Option value={item.first_name + ' ' + item.last_name} key={item.id} >
                                    {item.first_name + ' ' + item.last_name}
                                </Option>
                            ))} 
                        </Select>
                    </Form.Item> 
                    <Form.Item
                        label='Moneda'
                        name='currency'
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                    >
                        <Select
                            disabled={transactionsData?.approved} 
                            loading={!currencies }
                            placeholder={!currencies ? 'Cargando campos...' : 'Moneda'}      
                            style={{minWidth: '200px'}} 
                            showSearch   
                            onChange={onChangeCurrency}                                 
                        >
                            {currencies && currencies.map((item) => (
                                <Option value={item.id}>
                                    {item.name}
                                </Option>
                            ))} 
                        </Select>
                    </Form.Item>        
                    <Form.Item
                        label='Monto a debitar'
                        name='amount'
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                    >
                        <InputNumber
                            disabled={transactionsData?.approved}
                            addonBefore={currency ? currencies.find((x)=>x.id == currency)?.sign : ' ¤ '} 
                            placeholder="Monto"
                            type="number"
                        />
                    </Form.Item>                                                                                                          
                                                     
                    <Checkbox
                        
                        style={{width: '100%', marginBottom: 15}}
                        disabled={transactionsData?.approved}
                        checked={aproved}
                        onChange={onChangeAproved}
                    >
                        Aprobado
                    </Checkbox>
                    <Form.Item
                        label='Descripción'
                        name='description'
                    >
                        <Input
                            disabled={transactionsData?.approved}
                            placeholder="Comentario"
                        />
                    </Form.Item>                                        
                    <span style={{color: 'red'}}>{spanError && spanError} </span>                              
                </Form>
            </Modal>
        </div>
    )
}

export default CurrentAccountComission;
