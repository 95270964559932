import { axiosBackEndInstance } from '../Axios';
import { END_POINT_CASH_DESK_GET_ALL, END_POINT_CASH_DESK, END_POINT_CASH_GET_ALL_BY_CURRENCY } from '../Axios/ENDPOINTS';
import { message, notification } from 'antd';
import { useErrorHandling } from '../hooks/useErrorHandling';
import { CashDeskProps, useGetCashDesk } from './useGetCashDesk';
import { BranchProps } from './useGetBranch';
import { CurrencyProps } from './useGetCurrency';

export interface CheckoutBalance {
  id: number,
  name: string,
  description: string,
  branch: BranchProps,
  currency: CurrencyProps,
  balance: number,
  future_balance: number,
  type: {
    id: number,
    name: string
  }
}


export const useCashDesk= () => {

    const {errorHandling} = useErrorHandling()
    const { mutate } = useGetCashDesk()

    const getCheckout = async (
      id: number,
      setLoadingTableData?: Function
    ): Promise<CashDeskProps | undefined>=>{
     
      try {
        const  data = await axiosBackEndInstance.get(
          `${END_POINT_CASH_DESK}${id}`,  
        )
        if(data.status === 200 || data.status === 201){
          if(setLoadingTableData){
            setLoadingTableData(false)
          }
          return data.data        
        }
        if(setLoadingTableData){
          setLoadingTableData(false)
        }
      } catch (newError) {
        let status = errorHandling(newError).request.status
        console.log('status: ' + status + ' ' + newError);
        if(setLoadingTableData){
          setLoadingTableData(false)
        }
      }
    }

    const getAllCheckout = async (
      callback: Function,
    ): Promise<CashDeskProps[] | undefined>=>{
     
      try {
        const  data = await axiosBackEndInstance.get(
          `${END_POINT_CASH_DESK_GET_ALL}`,  
        )
        if(data.status === 200 || data.status === 201){
          callback(false)
          return data.data        
        }
        callback(false)
      } catch (newError) {
        let status = errorHandling(newError).request.status
        console.log('status: ' + status + ' ' + newError);
        callback(false)
      }
    }
     
     const createCashDesk = async (
      callback: Function,
      values:{
        name: string,
        description: string,
        branch_id: string,
        currency_id: string,
        balance: string,
        type_id: string
      }
      
      ): Promise<any> => {      
    try {
      const  data = await axiosBackEndInstance.post(
        END_POINT_CASH_DESK, values
      )
      const status =  errorHandling(data).request.status
      if(status === 200){
        callback()
        mutate()
        notification.success({
          message: 'Éxito', 
          description:'Caja creada con éxito', 
          placement: 'topRight'
      })
      }
    } catch (newError) {
      console.log(newError);
      callback()
      
    }
  };

  const editCashDesk = async (
    callback: Function,
    id: number,
    values:{
      name?: string,
    }
                            
  ): Promise<void> => {
              
    try {
      const data  = await axiosBackEndInstance.put(
        `${END_POINT_CASH_DESK}${id}`, values
      );
      callback()   
      if(data.status === 200 || data.status === 201){
        mutate()
        notification.success({
          message: 'Éxito', 
          description:'Caja editada con éxito', 
          placement: 'topRight'
        })
        return data.data
      }    
    } catch (newError) {    
      callback()
      let status = errorHandling(newError).request.status
      console.log('Status ' + status);
      console.log(newError);
    }
  };

  const editCashDeskBalance = async (
    id: number,
    values:{
      name?: string,
      balance?: number
    }                            
  ): Promise<CashDeskProps | undefined> => {
              
    try {
      const data  = await axiosBackEndInstance.put(
        `${END_POINT_CASH_DESK}${id}`, values
      );  
      if(data.status === 200 || data.status === 201){
        mutate()
        message.success({
          content: 'Guardado'
        })
        return data.data
      }    
    } catch (newError) {  
      let status = errorHandling(newError).request.status
      console.log('Status ' + status);
      console.log(newError);
    }
  };

  const deleteCashDesk = async (
    callback: Function,
    id: number                            
  ): Promise<void> => {
      
    try {
      const data  = await axiosBackEndInstance.delete(
        `${END_POINT_CASH_DESK}${id}`, 
      );
      callback()   
      if(data.status === 200){
        mutate()
        notification.success({
          message: 'Éxito', 
          description:'Caja eliminada con éxito', 
          placement: 'topRight'
        })
      }    
    } catch (newError) {
      console.log(newError);
      callback()
      let status = errorHandling(newError).request.status
    }
  };

  const getCashCurrency = async (currency_id: number): Promise<CashDeskProps[] | undefined> =>{
    try{
      const data = await axiosBackEndInstance.get(
        `${END_POINT_CASH_GET_ALL_BY_CURRENCY}/${currency_id}`
      )
      if(data.status === 200 || data.status === 201){      
        return data.data        
      }
    }catch(newError){
      let status = errorHandling(newError).request.status
      console.log('status: ' + status + ' ' + newError);
    }
  }

  const getCheckoutBalance = async (checkout_id: number): Promise<CheckoutBalance | undefined> =>{
    try{
      const data = await axiosBackEndInstance.get(
        `${END_POINT_CASH_DESK}${checkout_id}`
      )
      if(data.status === 200 || data.status === 201){      
        return data.data        
      }
    }catch(newError){
      let status = errorHandling(newError).request.status
      console.log('status: ' + status + ' ' + newError);
    }
  }



  return {
    editCashDesk,
    editCashDeskBalance,
    createCashDesk,
    deleteCashDesk,
    getCashCurrency,
    getCheckout,
    getCheckoutBalance,
    getAllCheckout
  };
};
