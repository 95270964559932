import React from 'react';
import {  Popover, Row, Tooltip } from 'antd';
import {
    CloseOutlined,
    UndoOutlined,
    QuestionCircleOutlined
  } from '@ant-design/icons';

import { DataType } from '../../Contexts/UserContext/MovementAcountContext';

export const RenderAccountBalance = React.memo(({text, record, data}:{text: any, record: DataType, data: 'destination' | 'origin'})=>{
  let balance: number | undefined 
  let sign = ''  
  let colorTxt = '#37bde0'

  if(record.deleted === 'true' || record.is_deleting_operation === 'true'){
    colorTxt = 'rgb(114, 114, 114)'
  }

  if (
    record?.customer_id !== '0' && 
    record?.[`${data}_account_balance`] !== undefined &&
    record[`${data}_account_balance`] !== ''
  ) {
    balance = +(record[`${data}_account_balance`] as string);
    sign =  record[`${data}_account_currency_sign`] ?? '';
  }

  const formattedBalance = balance !== undefined 
    ? Math.abs(balance).toLocaleString("es-AR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    : '';

  return (
    <span style={{color: colorTxt}}>
      {balance !== undefined && balance < 0 ? '- ' : ''}
      {sign}
      {' '}
      {formattedBalance}
    </span>
  )
})


export const RenderNumOperations = React.memo((value: {text: any, record: DataType})=>{
  return(
      <Row
          justify={
            value.record.deleted === 'true' || value.record.is_deleting_operation === 'true'
              ? 'space-between'
              : 'start'
          }
          style={{fontSize: 12}}
      >
          <span >{value.text}</span>            
          {value.record.deleted === 'true' && 
              <Tooltip
                  title='Eliminado'
              >
                  <CloseOutlined style={{color: 'red'}}/>
              </Tooltip>                
          }
           {value.record.is_deleting_operation === 'true' && 
              <Tooltip
                  title='Nota de crédito'
              >
                  <UndoOutlined style={{color: 'red'}}/>  
              </Tooltip>                
          }
      </Row>
      
  )
})

export const RenderUsers = React.memo((value: {text: any, record: DataType})=>{

  return(
      <Row
          justify={
            value.record.deleted === 'true' || value.record.is_deleting_operation === 'true'
              ? 'space-between'
              : 'start'
          }
          style={{fontSize: 12}}
      >          
        <Popover
          placement="right"
          content={ 
            <>
              <div 
                style={{
                  display: 'flex', 
                  flexDirection: 'column',
                  padding: 5
                }}>
                <span style={{fontSize: 11}}><strong>Creador: </strong>{value.record.created_by}</span>
                <span style={{fontSize: 11}}><strong>Fecha: </strong>{value.record.created_at} {value.record.created_at_hr}</span>   
              </div>
             
              {value.record.updated_by  && 
                <div 
                  style={{
                    borderTop:'1px solid gray', 
                    display: 'flex', 
                    flexDirection: 'column',
                    padding: 5
                  }}>
                  <span style={{fontSize: 11}}><strong>{value.record.approved === 'true' ? 'Aprobador: ' : 'Último editador: '} </strong>{value.record.updated_by}</span>    
                  <span style={{fontSize: 11}}><strong>Fecha: </strong>{value.record.updated_at} {value.record.updated_at_hr}</span>   
                </div>            
              }
            </>
          }  
        >
          <span >{value.text}</span> 
        </Popover>           
      </Row>
      
  )
})


export const RenderCustomerDestination = React.memo((value:{text: any, record: DataType})=>{ 
  let data =  value.record
  let customer = ''
 
  if(data.destination_account_customer_id && data.destination_account_customer_id !== '0'){
    customer = data.destination_account_customer_name + ' ' + data.destination_account_customer_last_name  +' -  [  ' + data.destination_account_currency_sign + ' ]  '
  }
  return(
    <Tooltip title={customer}>
      <span>{customer}</span> 
    </Tooltip>                 
  )
  }
)

export const RenderCustomerOrigin = React.memo((value:{text: any, record: DataType})=>{ 
  let data =  value.record
  let customer = ''
  if(data.origin_account_customer_id && data.origin_account_customer_id !== '0'){
    customer = data.origin_account_customer_name + ' ' + data.origin_account_customer_last_name + ' -  [  ' + data.origin_account_currency_sign + ' ]  '
  }
  return(
    <Tooltip title={customer}>
      <span>{customer}</span> 
    </Tooltip>               
  )
  }
)


export const formatted = (value: string)=>{

  const number = parseFloat(value);

  let data =  new Intl.NumberFormat('es-AR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(number);

  return  data
}

export const RenderAmount = React.memo(({ text, record, data, type }: { text: string, record: DataType, data: 'destination' | 'origin', type: 'credit' | 'debit' }) => {
  let sing = record.operation_type_id == '7' || record.operation_type_id =='9'
  ?   record?.[`${data}_operation_details_sing`]
  :   record?.[`${data}_account_currency_sign`]

    let txt = text &&`${sing} ${Number(text).toLocaleString('es-AR', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}`

    let tooltip: string | undefined = record.operation_type_id === '7' || record.operation_type_id == '9'
      ? record?.[`tooltip_${data}` as 'tooltip_destination' | 'tooltip_origin'] || ''
      : undefined;    

    return (
      <Tooltip title={ tooltip 
          ? <Row style={{ width: 'auto', display: 'inline-block' }}>
              {tooltip?.split('-').map((item, index) => (
                <div 
                  style={{
                    whiteSpace: 'nowrap'
                  }}
                  key={index}>{item.trim()}</div> ))}
            </Row>
          : ''
        }
        >
        
        <Row style={{ width: 'auto', display: 'inline-block' }}>
            <div 
              style={{
                fontSize: 12,
                whiteSpace: 'nowrap'
              }}
            >{txt && type === 'debit' &&'- '}{txt}</div> 
        </Row>
      </Tooltip>)
    ;
  }
);

export const RenderAmountWithObjet = React.memo(({ text, record, type }: { text: string, record: DataType, type: 'destination' | 'origin' }) => { //NO BORRAR
  let txt = text?.split(" - ")
  let tooltip: string = record?.[`tooltip_${type}` as 'tooltip_destination' | 'tooltip_origin'] || '';
  console.log(tooltip)  

  return <Tooltip title={
      <Row style={{ width: 'auto', display: 'inline-block' }}>
        {tooltip?.split('-').map((item, index) => (
          <div 
            style={{
              whiteSpace: 'nowrap'
            }}
            key={index}>{item.trim()}</div> ))}
      </Row>}
    >
    
    <Row style={{ width: 'auto', display: 'inline-block' }}>
      {txt.map((item, index) => (
        <div 
          style={{
            fontSize: 12,
            whiteSpace: 'nowrap'
          }}
          key={index}>{item.trim()}</div> ))}
    </Row>
  </Tooltip>
  ;
}
);

export const RenderAccountAmount = React.memo(
  ({ text, record, type }: { text: string; record: DataType; type: 'credit' | 'debit' }) => {
    let sign = '';

    if(record.customer_id == '0'){
      return null
    }

    if (type === 'credit') {
      sign = record.destination_account_currency_sign || ''      
    } else if (type === 'debit') {
      sign = record.origin_account_currency_sign || ''      
    }

    return <span >{text && type === 'debit' && '- '} {text !== '' && sign} {text !== '' ? formatted(text) : ''}</span>;
  }
);

export const RenderCheckout = React.memo((
  value:{
    text: string, 
    record: DataType, 
    type?: 'destination' | 'origin'
  }, )=>{
    let txt = value?.text?.split(" - ")

    return( 
      <Row style={{ width: 'auto', display: 'inline-block' }}>
         {txt.map((x, index)=>
          <div
            style={{fontSize: 12}}
            key={index}
          >
            {x}
          </div>
        )}
      </Row>)
}) 

export const RenderBalance = React.memo(({ text, record, type }: { text: string, record: DataType, type: 'destination' | 'origin' }) => {
  let sing =record.operation_type_id == '7' || record.operation_type_id =='9'
    ? `${record?.[`${type}_operation_details_sing`]}`
    : `${record?.[`${type}_checkout_currency_sign`]}`
 
  let txt = text && `${sing} ${Number(text).toLocaleString('es-AR', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}`

  let tooltip: string | undefined = record.operation_type_id === '7' || record.operation_type_id == '9'
    ? record?.[`tooltip_balance_${type}` as 'tooltip_balance_destination' | 'tooltip_balance_origin'] || ''
    : undefined;    
  let colorTxt = '#37bde0'

  if(record.deleted === 'true' || record.is_deleting_operation === 'true'){
    colorTxt = 'rgb(114, 114, 114)'
  }

  return (
    <Tooltip title={ tooltip 
        ? <Row style={{ width: 'auto', display: 'inline-block' }}>
            {tooltip?.split('-').map((item, index) => (
              <div 
                style={{
                  whiteSpace: 'nowrap'
                }}
                key={index}>{item.trim()}</div> ))}
          </Row>
        : ''
      }
      >
      
      <Row style={{ width: 'auto', display: 'inline-block' }}>
          <div 
            style={{
              color: colorTxt,
              fontSize: 12,
              whiteSpace: 'nowrap'
            }}
          >{txt}</div> 
      </Row>
    </Tooltip>)
  ;
});

export const RenderBalanceWhitObjet = React.memo((
  value:{
    text: string, 
    record: DataType, 
    type?: 'destination' | 'origin'
  }, )=>{

    if(!value.text){
      return <span></span>
    }

    let txt = value.text?.split(" - ")
    let colorTxt = '#37bde0'
    let tooltip: string = value.record?.[`tooltip_balance_${value.type}` as 'tooltip_balance_destination' | 'tooltip_balance_origin'] || '';
    console.log(tooltip)  

    if(value.record.deleted === 'true' || value.record.is_deleting_operation === 'true'){
      colorTxt = 'rgb(114, 114, 114)'
    }

   
    
    return( 
      <Tooltip title={
        <Row style={{ width: 'auto', display: 'inline-block' }}>
          { (tooltip?.split('-') || []).map((item, index) => (
            <div 
              style={{
                whiteSpace: 'nowrap'
              }}
              key={index}>{item.trim()}</div> ))}
        </Row>
        }
      >
        {txt?.map((x, index)=>
          <div
          style={{color: colorTxt}}
            key={index}
          >
            {x}
          </div>
        )}        
      </Tooltip>)
})

export const RenderCustomer = React.memo((value: {text: any, record: DataType}) => {
    let colorTxt='#ADADAD'
    let customer = value.text

    
    if(value.record.full_name){
        customer = value.record.full_name
        colorTxt = '#FFC107'
    }

    if(value.record.deleted === 'true' || value.record.is_deleting_operation === 'true'){
      colorTxt = 'rgb(114, 114, 114)'
    }
    if(value.record.operation_type_id==='5' ){
      customer = ''
    }
    
    return(
      <>
        {
          value.record.full_name
            ?
              <Tooltip
                title='Ventanilla '
              >
                <span
                  style={{
                      display: 'inline-block',
                      width: '100%', 
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      color:colorTxt ,
                  }}
              >
                  {customer}  <QuestionCircleOutlined/>
              </span>
              </Tooltip>
            
            : <span>{customer}</span>
        }
      </> 
    )
})

export const RenderVentanilla = React.memo((value: {text: any, record: DataType}) => {
  let colorTxt='#FFC107'
  let customer = value?.record?.full_name ? value.record.full_name : ''

  if(value.record.deleted === 'true' || value.record.is_deleting_operation === 'true'){
      colorTxt = 'rgb(114, 114, 114)'
  }else if(value.record.full_name){
      customer = value.record.full_name
      colorTxt = '#FFC107'
  }
  
  return(
    <>
      {
        value.record.full_name
          &&
            <Tooltip
              title={value.record.full_name}
            >
              <span
                style={{
                    display: 'inline-block',
                    width: '100%', 
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    color:colorTxt ,
                }}
            >
                {customer}  
            </span>
            </Tooltip>                 
      }
    </> 
  )
})

