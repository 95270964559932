import React from "react"
import { DataType } from "../../Contexts/UserContext/MovementAcountContext"
import { Col, Row } from "antd"

const calculateResult = (record: DataType) =>{
 // if (record.main_currency_id && record.secondary_currency_id) 
    
  let isUSDT = record.main_currency_id === '3'
  let isSecondaryCurrencyNotUSD = record.secondary_currency_id !== '2'
  let comission = record.usdt_quote_percentage || undefined
  let typeChange = record.currency_quote_buy_price 
    ?  record.currency_quote_buy_price
    : record.currency_quote_sell_price
      ? record.currency_quote_sell_price
      : undefined
  let amount = record.currency_sale === 'true' || record.usdt_sale === 'true'
    ? record.credit
    :  record.currency_sale === 'false' || record.usdt_sale === 'false'
      ? record.debit
      : undefined

  let calculate: number = 0;

  if (isUSDT) {
      if (isSecondaryCurrencyNotUSD && typeChange && comission && amount) {
          calculate = ((Number(comission) / 100 + 1) * Number(amount)) * Number(typeChange);
      } else if (comission !== undefined) {
          calculate = (Number(comission) / 100 + 1) * Number(amount);
      }
  } else if (typeChange) {
      calculate = Number(typeChange) * Number(amount);
  }

  return calculate     
}

export const RenderConfirm = React.memo(({ record, isGetMain, operation }: { record: DataType, isGetMain: boolean, operation: string }) =>{
    let type: 'destination' | 'origin' | undefined
    let sellOrBuy: 'sell' | 'buy' | undefined
    let isUSDT: boolean
    let currency_quote =  record.currency_quote_buy_price || 
      record.currency_quote_sell_price ||
    undefined
    switch(record.operation_type_id){
      case '1':
      case '3':
        type = 'destination'  
        break
      case '2':  
      case '4':  
        type = 'origin'  
        break
      case '7':
        isUSDT = false
        sellOrBuy = record.currency_sale === 'true'
          ? 'sell' 
          : record.currency_sale === 'false'
            ? 'buy'
            : undefined
        break
      case '9':
        isUSDT = true
        sellOrBuy = record.usdt_sale === 'true'
          ? 'sell' 
          : record.usdt_sale === 'false'
            ? 'buy'
            : undefined
        
    }
    switch(record.operation_type_id){
      case '1': 
      case '3':     
      case '2': 
      case '4':       
        if(type){
          let sign = record[`${type}_account_currency_sign`]
          return(
            <div style={{fontSize: 13}}>
             {record.approved == 'true' && <span style={{
                backgroundColor: record.deleted === 'true' ? 'red' : 'rgb(48, 203, 133)',
                borderRadius: 4,
                padding: '2px 5px',
                color: 'white',
                width: 'auto'
              }}><strong>{operation} N° {record.operation_id}</strong></span>}
              {record.deleted == 'true'  || record.is_deleting_operation === 'true'&& <span style={{
                backgroundColor:  'red' ,
                borderRadius: 4,
                padding: '2px 5px',
                color: 'white',
                width: 'auto'
              }}><strong>{
                record.is_deleting_operation === 'true' 
                ? 'Nota de crédito' 
                : record.deleted === 'true'
                  ? 'Eliminada'
                  : ''  
              }</strong></span>}            
              <p><strong>{record.operation_type_name}</strong></p>
              {record.customer_id && record.customer_id !== '0' && <p><strong>Cuenta cliente: </strong>{`${record.customer_first_name} ${record.customer_last_name} - [${sign}]`}</p>}
              {record.customer_id && record.customer_id === '0' &&<p><strong>Ventanilla: </strong>{record.full_name}</p>}
              <p><strong>Sucursal - Caja: </strong>{record[`${type}_checkout_branch`] + ' - ' + record[`${type}_checkout_name`]}</p>
              <p>
                <strong>{type =='origin' ? 'Extracción': 'Depósito'} </strong>
                {sign} {Number( type =='origin' 
                  ? record.debit 
                  : record.credit).toLocaleString('es-AR', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
              </p>
              {record.commission && <p><strong>Comisión: </strong>{Number(record.commission).toLocaleString('es-AR', {minimumFractionDigits: 2, maximumFractionDigits: 2})} %</p>}
              {record.transfer_costs !== '0.00' && <p><strong>Costos bancarios: </strong>{sign} {Number(record.transfer_costs).toLocaleString('es-AR', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</p>}
              {record.transfer_costs !== '0.00' && <p><strong>Cliente paga los costos: </strong>{record.client_pays_costs == 'true' ? 'Si' : 'No'}</p>}
              <p>
                <strong>Estado: </strong>
                <strong 
                  style={{color: record.approved === 'true' ? 'rgb(48, 203, 133)' : 'orange'}}
                >{record.approved === 'true' ? 'Aprobada' : 'Sin aprobar'}</strong>
              </p>
            </div>      
          )
        }
        return <span/> 
      
      case '5': //TRANSFERENCIA ENTRE CAJAS
        return(
          <div style={{fontSize: 13}}>
             {record.approved == 'true' && <span style={{
              backgroundColor:( record.deleted === 'true' || record.is_deleting_operation === 'true' )? 'red' : 'rgb(48, 203, 133)',
              borderRadius: 4,
              padding: '2px 5px',
              color: 'white',
              width: 'auto'
            }}><strong>{operation} N° {record.operation_id}</strong></span>}
            {(record.deleted === 'true'  || record.is_deleting_operation === 'true') && <span style={{
                backgroundColor:  'red' ,
                borderRadius: 4,
                padding: '2px 5px',
                color: 'white',
                width: 'auto',
                marginLeft: 5,
                marginRight: 10
              }}><strong>{
                record.is_deleting_operation === 'true' ? 'Nota de crédito' : 'Eliminada'}</strong></span>}  
            <p><strong>{record.operation_type_name}</strong></p>  
            <p
              style={{paddingTop: 10, borderTop: '1px solid rgb(202, 202, 202)'}}
            ><strong>Caja origen: </strong>{record.origin_checkout_branch} - {record.origin_checkout_name}</p>        
            <p><strong>Monto: </strong>
              {record.origin_checkout_currency_sign}  {Number(record.debit).toLocaleString('es-AR', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
            </p>
            <p
              style={{paddingTop: 10, borderTop: '1px solid rgb(202, 202, 202)'}}
            ><strong>Caja destino: </strong>{record.destination_checkout_branch} {record.destination_checkout_name}</p>
            <p><strong>Monto: </strong>
              {record.destination_checkout_currency_sign} {Number(record.credit).toLocaleString('es-AR', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
            </p>
            <p style={{paddingTop: 10, borderTop: '1px solid rgb(202, 202, 202)'}}>
              <strong>Estado: </strong>
              <strong 
                style={{color: record.approved === 'true' ? 'rgb(48, 203, 133)' : 'orange'}}
              >{record.approved === 'true' ? 'Aprobada' : 'Sin aprobar'}</strong>
            </p>
          </div>
          
        )      
      case '6': //TRANSFERENCIA ENTRE CUENTAS      
        return(
          <div style={{fontSize: 13}}>
             {record.approved == 'true' && <span style={{
              backgroundColor:( record.deleted === 'true' || record.is_deleting_operation === 'true' )? 'red' : 'rgb(48, 203, 133)',
              borderRadius: 4,
              padding: '2px 5px',
              color: 'white',
              width: 'auto'
            }}><strong>{operation} N° {record.operation_id}</strong></span>}
            {(record.deleted === 'true'  || record.is_deleting_operation === 'true') && <span style={{
                backgroundColor:  'red' ,
                borderRadius: 4,
               // padding: '2px 5px',
                color: 'white',
                width: 'auto',
                marginLeft: 5,
                marginRight: 10
            }}><strong>{
              record.is_deleting_operation === 'true' ? 'Nota de crédito' : 'Eliminada'}</strong></span>}    
            <p><strong>{record.operation_type_name}</strong></p>
            <p><strong>Cuenta origen: </strong>{`${record.origin_account_customer_name}  ${record.origin_account_customer_last_name} - [${record.origin_account_currency_sign}]`}</p>                  
            <p><strong>Cuenta destino: </strong>{`${record.destination_account_customer_name} ${record.destination_account_customer_last_name} - [${record.destination_account_currency_sign}]`}</p>
            <p><strong>Monto: </strong>
              {record.origin_account_currency_sign}  {Number(record.debit).toLocaleString('es-AR', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
            </p>
            <p>
              <strong>Estado: </strong>
              <strong 
                style={{color: record.approved === 'true' ? 'rgb(48, 203, 133)' : 'orange'}}
              >{record.approved === 'true' ? 'Aprobada' : 'Sin aprobar'}</strong>
            </p>
          </div>
        )  
        case '7':
        case '9':
          return(
            <div style={{fontSize: 13}}>
              {record.approved == 'true' && <span style={{
                backgroundColor:( record.deleted === 'true' || record.is_deleting_operation === 'true' )? 'red' : 'rgb(48, 203, 133)',
                borderRadius: 4,
                padding: '2px 5px',
                color: 'white',
                width: 'auto'
              }}><strong>{operation} N° {record.operation_id}</strong></span>}
              {(record.deleted === 'true'  || record.is_deleting_operation === 'true') && <span style={{
                  backgroundColor:  'red' ,
                  borderRadius: 4,
                  padding: '2px 5px',
                  color: 'white',
                  width: 'auto',
                  marginLeft: 5,
                  marginRight: 10
                }}><strong>{
                  record.is_deleting_operation === 'true' ? 'Nota de crédito' : 'Eliminada'}</strong></span>}     
              <p><strong>{sellOrBuy == 'sell' 
                ? `Venta de moneda` 
                : `Compra de moneda`}
              </strong></p>
              {record.customer_id && record.customer_id  !== '0' && <p><strong>Cliente: </strong>{record.customer_first_name + ' ' + record.customer_last_name}</p>}
              {record.full_name && <p><strong>Ventanilla: </strong>{record.full_name}</p>}
              {sellOrBuy == 'sell' && 
                <>
                  <p><strong>Venta: </strong>{record.main_currency_sign} {Number(record?.credit).toLocaleString('es-AR', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</p>   
                  <p><strong>Compra: </strong>{record.secondary_currency_sign} {calculateResult(record).toLocaleString('es-AR', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</p>
                </>
              }
              {sellOrBuy == 'buy' &&
                <>
                  <p><strong>Compra: </strong>{record.main_currency_sign} {Number(record?.debit).toLocaleString('es-AR', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</p>
                  <p><strong>Venta: </strong>{record.secondary_currency_sign} {calculateResult(record).toLocaleString('es-AR', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</p>              
                </>
              }    
              {record.usdt_quote_percentage &&  
                <p><strong>% de cotizacion: </strong>
                  {Number(record.usdt_quote_percentage).toLocaleString('es-AR', {minimumFractionDigits: 2, maximumFractionDigits: 2})} %
                </p>
              }      
              {currency_quote &&  
                <p><strong>Tipo de cambio: </strong>
                  {sellOrBuy == 'sell' ? record.origin_account_currency_sign : record.destination_account_currency_sign} 
                  {' '}
                  {Number(currency_quote).toLocaleString('es-AR', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                </p>
              }
              { (record.tooltip_destination || record.tooltip_origin) &&
                <>                           
                  <Row
                    style={{
                      minWidth: 370,
                      borderTop: '1px solid rgb(202, 202, 202)',
                      paddingTop: 2,
                    }}
                  >
                      <Col    
                        span={12}    
                        style={{ 
                          fontSize: 11,
                          padding: 7,
                        }}>
                        <div style={{marginBottom: 5}}>
                          <strong> Cajas de Depósito</strong>
                        </div>
                        {record.tooltip_destination?.split('-').map((item, index) => (                        
                          <div 
                            style={{whiteSpace: 'nowrap'}}
                            key={index}>{item.trim()}
                          </div>))
                        }                     
                      </Col>
                      <Col                       
                        span={12}
                        style={{ 
                          fontSize: 11,                        
                          padding: 7,     
                        }}>
                          <div style={{marginBottom: 5}}>
                            <strong>Cajas de Extracción</strong>
                          </div>
                        {record.tooltip_origin?.split('-').map((item, index) => (
                          <div 
                            style={{ whiteSpace: 'nowrap' }}
                            key={index}>{item.trim()}
                          </div> ))
                        } 
                      </Col>                  
                  </Row>
                  <Row
                    style={{
                      borderTop: '1px solid rgb(202, 202, 202)',
                      paddingTop: 2,
                      width: '100%',
                    }}
                  >
                      <Col  
                        span={12}
                        style={{ 
                          fontSize: 11,
                          padding: 7
                        }}>
                        <div style={{marginBottom: 5}}>
                          <strong>Total: </strong>
                          {sellOrBuy === 'sell' ?  record.secondary_currency_sign : record.main_currency_sign}
                          {Number(record.total_credit_operations).toLocaleString('es-AR',{minimumFractionDigits:2, maximumFractionDigits: 2})}
                        </div>
                                             
                      </Col>
                      <Col 
                        span={12}
                        style={{ 
                          fontSize: 11,                        
                          padding: 7
                        }}>
                          <div style={{marginBottom: 5}}>                        
                            <strong>Total: </strong>
                            {sellOrBuy === 'sell' ?  record.main_currency_sign :record.secondary_currency_sign }
                            {Number(record.total_debit_operations).toLocaleString('es-AR',{minimumFractionDigits: 2, maximumFractionDigits: 2})}                      
                          </div>                        
                      </Col>                  
                  </Row>
                </>
              }
              <p>
                <strong>Estado: </strong>
                <strong 
                  style={{color: record.approved === 'true' ? 'rgb(48, 203, 133)' : 'orange'}}
                >{record.approved === 'true' ? 'Aprobada' : 'Sin aprobar'}</strong>
              </p>
            </div>
          )       
      case '8':  
      case '12':
      case '13':
        return(
          <div style={{fontSize: 13}}>
             {record.approved == 'true' && <span style={{
              backgroundColor:( record.deleted === 'true' || record.is_deleting_operation === 'true' )? 'red' : 'rgb(48, 203, 133)',
              borderRadius: 4,
              padding: '2px 5px',
              color: 'white',
              width: 'auto'
            }}><strong>{operation} N° {record.operation_id}</strong></span>}
            {(record.deleted === 'true'  || record.is_deleting_operation === 'true') && <span style={{
                backgroundColor:  'red' ,
                borderRadius: 4,
               // padding: '2px 5px',
                color: 'white',
                width: 'auto',
                marginLeft: 5,
                marginRight: 10
            }}><strong>{
              record.is_deleting_operation === 'true' ? 'Nota de crédito' : 'Eliminada'}</strong></span>}    
            <p><strong>{record.operation_type_name}</strong></p>
            {record.operation_type_id == '13' && <p><strong>Sucursal - Caja: </strong>{record.origin_checkout_branch} - {record.origin_checkout_name}</p>}
            {record.origin_account_customer_id && <p><strong>Cuenta cliente: </strong>{`${record.origin_account_customer_name}  ${record.origin_account_customer_last_name} - [${record.origin_account_currency_sign}]`}</p>}
            {record.operation_type_id == '13' && <p><strong>Costos bancarios: </strong>{record.origin_checkout_currency_sign} {Number(record.debit).toLocaleString('es-AR', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</p>}
            {record.commission && <p><strong>Comisión por transferencia: </strong>{record.origin_account_currency_sign} {Number(record.debit).toLocaleString('es-AR', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</p>}
            {record.supplier_commission &&
              <>
                <p><strong>Cuenta proveedor: </strong>{`${record.destination_account_customer_name} ${record.destination_account_customer_last_name} - [${record.destination_account_currency_sign}]`}</p>
                <p><strong>Comisión por transferencia: </strong>{record.supplier_commission} %</p>
                <p><strong>Monto: </strong>{record.destination_account_currency_sign} {Number(record.credit).toLocaleString('es-AR', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</p>
                
              </>
            }   
            <p>
              <strong>Estado: </strong>
              <strong 
                style={{color: record.approved === 'true' ? 'rgb(48, 203, 133)' : 'orange'}}
              >{record.approved === 'true' ? 'Aprobada' : 'Sin aprobar'}</strong>
            </p>                   
          </div>
        )
      case '10':
      case '11':
        const isDeposit = record.operation_type_id === '10';
  
        const clientName = isDeposit 
          ? `${record.destination_account_customer_name} ${record.destination_account_customer_last_name} - [${record.destination_account_currency_sign}]`
          : `${record.origin_account_customer_name} ${record.origin_account_customer_last_name} - [${record.origin_account_currency_sign}]`;
  
        const providerName = isDeposit 
          ? `${record.origin_account_customer_name} ${record.origin_account_customer_last_name} - [${record.origin_account_currency_sign}]`
          : `${record.destination_account_customer_name} ${record.destination_account_customer_last_name} - [${record.destination_account_currency_sign}]`;
  
        return(
          <div style={{fontSize: 13}}>
             {record.approved == 'true' && <span style={{
              backgroundColor:( record.deleted === 'true' || record.is_deleting_operation === 'true' )? 'red' : 'rgb(48, 203, 133)',
              borderRadius: 4,
              padding: '2px 5px',
              color: 'white',
              width: 'auto'
            }}><strong>{operation} N° {record.operation_id}</strong></span>}
            {(record.deleted === 'true'  || record.is_deleting_operation === 'true') && <span style={{
                backgroundColor:  'red' ,
                borderRadius: 4,
               // padding: '2px 5px',
                color: 'white',
                width: 'auto',
                marginLeft: 5,
                marginRight: 10
            }}><strong>{
              record.is_deleting_operation === 'true' 
                ? 'Nota de crédito' 
                : record.deleted === 'true'
                  ? 'Eliminada'
                  : ''
              }</strong></span>}    
            <p><strong>{record.operation_type_name}</strong></p>            
            <p><strong>Cuenta Cliente: </strong>{clientName}</p>
            <p><strong>Cuenta Proveedor: </strong>{providerName}</p>              
            {record.commission && <p><strong>Comisión a cliente: </strong>
              {record.origin_account_currency_sign} {Number(record.commission).toLocaleString('es-AR', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
            </p>}           
            {record.supplier_commission && <p><strong>Comisión de proveedor: </strong>{record.destination_account_currency_sign} {Number(record.supplier_commission).toLocaleString('es-AR', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</p>}
            {<p><strong>Monto de transferencia: </strong>{record.destination_account_currency_sign} {Number(record.debit).toLocaleString('es-AR', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</p>}
            <p>
              <strong>Estado: </strong>
              <strong 
                style={{color: record.approved === 'true' ? 'rgb(48, 203, 133)' : 'orange'}}
              >{record.approved === 'true' ? 'Aprobada' : 'Sin aprobar'}</strong>
            </p>
          </div>
        )  
      case '14': //DEPÓSITO DE FONDOS
      case '15': //EXTRACCIÓN DE FONDOS
        let typeCheckout: 'destination' | 'origin' = record.operation_type_id === '14' ? 'destination' : 'origin'
        let amount = record.operation_type_id === '14' ? record.credit : record.debit
        return(
          <div style={{fontSize: 13}}>
             {record.approved == 'true' && <span style={{
              backgroundColor:( record.deleted === 'true' || record.is_deleting_operation === 'true' )? 'red' : 'rgb(48, 203, 133)',
              borderRadius: 4,
              padding: '2px 5px',
              color: 'white',
              width: 'auto'
            }}><strong>{operation} N° {record.operation_id}</strong></span>}
            {(record.deleted === 'true'  || record.is_deleting_operation === 'true') && <span style={{
                backgroundColor:  'red' ,
                borderRadius: 4,
                padding: '2px 5px',
                color: 'white',
                width: 'auto',
                marginLeft: 5,
                marginRight: 10
              }}><strong>{
                record.is_deleting_operation === 'true' ? 'Nota de crédito' : 'Eliminada'}</strong></span>}  
            <p><strong>{record.operation_type_name}</strong></p>  
            <p><strong>Sucursal -Caja: </strong>{record[`${typeCheckout}_checkout_branch`]} - {record[`${typeCheckout}_checkout_name`]}</p>        
            <p><strong>Monto: </strong>
              {record[`${typeCheckout}_checkout_currency_sign`]}  {Number(amount).toLocaleString('es-AR', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
            </p>    
            <p>
                <strong>Estado: </strong>
                <strong 
                  style={{color: record.approved === 'true' ? 'rgb(48, 203, 133)' : 'orange'}}
                >{record.approved === 'true' ? 'Aprobada' : 'Sin aprobar'}</strong>
              </p>     
          </div>
        )  
      case '16':
          let sign = record.origin_account_currency_sign
          return(
            <div style={{fontSize: 13}}>
            {record.approved == 'true' && <span style={{
                backgroundColor: record.deleted === 'true' ? 'red' : 'rgb(48, 203, 133)',
                borderRadius: 4,
                padding: '2px 5px',
                color: 'white',
                width: 'auto'
              }}><strong>{operation} N° {record.operation_id}</strong></span>}
              {record.deleted == 'true'  || record.is_deleting_operation === 'true'&& <span style={{
                backgroundColor:  'red' ,
                borderRadius: 4,
                padding: '2px 5px',
                color: 'white',
                width: 'auto'
              }}><strong>{
                record.is_deleting_operation === 'true' 
                ? 'Nota de crédito' 
                : record.deleted === 'true'
                  ? 'Eliminada'
                  : ''  
              }</strong></span>}            
              <p><strong>{record.operation_type_name}</strong></p>
              {record.customer_id && record.customer_id !== '0' && <p><strong>Cuenta cliente: </strong>{`${record.customer_first_name} ${record.customer_last_name} - [${sign}]`}</p>}
              <p>
                <strong>Débito: </strong>
                {sign} {record.debit && Number(record.debit).toLocaleString('es-AR', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
              </p>             
              <p>
                <strong>Estado: </strong>
                <strong 
                  style={{color: record.approved === 'true' ? 'rgb(48, 203, 133)' : 'orange'}}
                >{record.approved === 'true' ? 'Aprobada' : 'Sin aprobar'}</strong>
              </p>
            </div>      
          )
        
      return <span/> 
      default:
        return(
          <span></span>
        )
      
    }  
  })




  export default RenderConfirm