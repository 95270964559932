
import React, { useContext, useEffect, useState } from 'react';
import './styles.scss'
import {   Row, Table } from 'antd';
import  { ColumnsType } from 'antd/es/table';
import { SpinerHorizontal } from '../../SpinerHorizontal/SpinerHorizontal';
import { StylesContext } from '../../../Contexts/UserContext/StylesContext';
import { BalanceByAccountProps } from './types';
import { Accounts } from '../../../Services/useGetAllAccount';


export interface DataType {
  index: string,
  customer_name: string,
  customer_id: string,
  currency_name: string,
  currency_sign: string,
  currency_id: string,
  balance: string,
  future_balance: string,
}

const HandleRender = React.memo<{
    text: string, 
    record: DataType,   
    color: string
  }>(({text, record, color}) => {

  let set_color = color;
  const cleanedText = text.replace(/\./g, '').replace(',', '.');
  if (cleanedText && !isNaN(Number(cleanedText)) && Number(cleanedText) < 0) {
    set_color = 'red';
  }
  return(
    <span 
      style={{
        color: set_color,
        fontWeight: 600
      }}
    >
      {text && text !== '' && record.currency_sign} {text}
    </span>
  )
})

const BalanceByAccount: React.FC<BalanceByAccountProps> = ({
  dataApi
}) => {

  const [loadingTableData, setLoadingTableData] = useState<boolean>(false)
  const [dataTable, setDataTable] = useState<DataType[]>([])  

  useEffect(()=>{
    setLoadingTableData(true)
    if(dataApi){
      rowData(dataApi) 
      setLoadingTableData(false)
    }
  },[dataApi])  

  const rowData = (data: Accounts[]) => {
    let list: Accounts[] = data ?? dataApi; // Usa nullish coalescing operator (??)
    let filterData: DataType[] = []
    if (list) {
      list.map((data, index) => {
        if(data.customer.id !== 0){
          filterData.push({
            index: index.toString(),
            customer_name: data.customer.first_name + ' ' + data.customer.last_name,
            customer_id: data.customer?.id?.toString() || '',        
            currency_name: data.currency.name || '',
            currency_sign: data.currency.sign || '',
            currency_id: data.currency.id.toString(),
            balance: Number(data.balance)?.toLocaleString("es-AR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || '',
            future_balance: Number(data.future_balance)?.toLocaleString("es-AR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || '',
          })
        }
        }); 
      setDataTable(filterData);
    }
  };

    const filters = (type: 'customer'  ) => {
      let textAndValue: { text: string, value: string }[] = [];
      let uniqueValues = new Set();
  
      dataApi?.forEach((item) => {
          const code =  item.customer.first_name + ' '  + item.customer.last_name
          const value =  item.customer.id.toString() 
  
          if (!uniqueValues.has(code) ) {
              uniqueValues.add(code);
              textAndValue.push({
                  text: code,
                  value: value
              });
          }
      });
      return textAndValue;
    }
    
    const columns: ColumnsType<DataType> = [
        {
            title: 'Cliente',
            dataIndex: 'customer_name',
            filters: filters('customer'),
            onFilter: (value, record) => typeof value === 'string' && record.customer_id===value,
            filterSearch: true,
        },      
        {
          title: 'Cuenta',
          dataIndex: 'currency_sign'
        },
        {
          title: 'Saldo',
          dataIndex: 'balance',
          render: (text, record)=><HandleRender text={text} record={record} color='#37bde0'/>,
        },
        {
          title: 'Saldo futuro',
          dataIndex: 'future_balance',
          render: (text, record)=><HandleRender text={text} record={record} color='#FF69B4'/>,
        },      
    ];
    

    return( 
      <>                                                         
        <Row 
          className='balance_by_account'
          style={{
              display:'flex',
              justifyContent: 'center',
              width: '100%',
              height: '100%',
              paddingRight: 16,                
          }}
        >
          {
            loadingTableData
            ? <SpinerHorizontal/> 
            : <Table 
                style={{
                    width: '100%',
                }}
                columns={columns} 
                dataSource={dataTable} 
                pagination={{
                  pageSize: 20,
                  showSizeChanger: false,                                      
              }}
            />    
          } 
        </Row>  
      </>                
)};

export default BalanceByAccount
