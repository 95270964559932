import React, {
    createContext,
    useEffect,
    useState,
  } from 'react';
import { useNavigate  } from "react-router-dom";
import { useIntl } from 'react-intl';
import { axiosBackEndInstance } from '../../Axios'; 
import { useLocalStorageState } from '../../hooks/useLocalStorageState';
import { ProfileProps } from '../../Services/useGetProfile';
import { END_POINT_FEATURE_FLAGS, END_POINT_USER_PROFILE } from '../../Axios/ENDPOINTS';

  class VoidContext implements UserContext {
    get changeAccessPageForm(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setChangeAccesPageForm(): Function {
      throw new Error('Cannot consume context outside of provider');
    }
    get showAnimated(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setShowAnimated(): Function {
      throw new Error('Cannot consume context outside of provider');
    }
     get loading(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setLoading(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get showError(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setShowError(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get statusRequest():never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setStatusRequest(): Function {
      throw new Error('Cannot consume context outside of provider');
    }
    get accesToken():never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setAccesToken(): Function {
      throw new Error('Cannot consume context outside of provider');
    }
    get userData(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get unlog():never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setUnlog(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get collapsed():never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setCollapsed(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get getProfile(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get initializationMode(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setInitializationMode(): never {
      throw new Error('Cannot consume context outside of provider');
    }
  }

  interface UserContext {
    changeAccessPageForm: String,
    setChangeAccesPageForm: Function,
    loading: boolean,
    setLoading: Function,
    showError: string,
    setShowError: Function,
    statusRequest: number,
    setStatusRequest: Function,
    accesToken : string | undefined
    setAccesToken: Function,
    unlog: boolean,
    setUnlog: Function,
    userData: ProfileProps | undefined,
    collapsed: boolean,
    setCollapsed: Function,
    getProfile: Function,
    initializationMode: boolean | undefined,
    setInitializationMode: Function
  }

  export interface UserData {
    firstName: string,
    lastName: string,
    userId: number,
  }

  interface propsWithChildren {
    children: React.ReactNode
  }

  export interface accesToken{
    access: string,
    refresh:string
  }
  
  export const UserContext = createContext<UserContext>(new VoidContext());
  export const UserContextProvider= ({ children }: propsWithChildren): JSX.Element => {

    const intl = useIntl();
    const navigate = useNavigate();

    const [changeAccessPageForm, setChangeAccesPageForm]= useState<string>('login')
    const [loading, setLoading]= useState<boolean>(false)
    const [initializationMode, setInitializationMode]= useState<boolean | undefined>()
    const [showError, setShowError]= useState<string>('')
    const [statusRequest, setStatusRequest] = useState<number>(0)
    const [unlog, setUnlog] = useState(true)
    const [collapsed, setCollapsed] = useState(true);
    const [accesToken, setAccesToken] = useLocalStorageState<string | undefined>(
      'financial-Token', undefined
    ); 
    const [userData, setUserData] = useState<ProfileProps>()
    


    useEffect(()=>{
      if(!userData){
        getProfile()
      }      
    },[])

    const getProfile = async() =>{
      try {
        let response =  await axiosBackEndInstance.get(END_POINT_FEATURE_FLAGS)
        if(response && response.status == 200){
          setInitializationMode(response.data.initialization_mode)
        }
        let data = await axiosBackEndInstance.get(END_POINT_USER_PROFILE)
        if(data && data.status === 200){
          setUserData(data.data)
        }        
      } catch (error) {
        console.log(error);
      } 
    }

    // Tipos de movimientos
    //  1 Depósito cuenta corriente
    //  2 Extracción cuenta corriente
    //  3 Depósto bancario
    //  4 Extracción bancaria
    //  5 transferencia entre cajas
    //  6 Transferencia entre cuentas
    //  7 compra venta
    //  8 Comision por trasnferencia bancaria
    //  9 Compra / venta de USDT
    //  10 Depósito por trasnferencia a proveedor
    //  11 Extracción por Transferencia a Proveedor
    //  12 Comisión por Transferencia a Proveedor 
 
    return (
      <UserContext.Provider value={{
        changeAccessPageForm, 
        setChangeAccesPageForm,
        showError,
        setShowError,
        loading,
        setLoading,
        setStatusRequest,
        statusRequest,
        accesToken, 
        setAccesToken,
        unlog, 
        setUnlog,
        userData,
        collapsed, 
        setCollapsed,
        getProfile,
        initializationMode, 
        setInitializationMode
      }}>{children}</UserContext.Provider>
    );
  };



  