import { Button, Flex, Row, Table } from 'antd'
import './styles.scss'
import { DepositExtractionBoxProps } from './types'
import { ColumnsType } from 'antd/es/table'
import { CashDeskProps, useGetCashDesk } from '../../../Services/useGetCashDesk'
import { useContext, useEffect, useState } from 'react'
import { useCashDesk } from '../../../Services/useCashDesk'
import { SpinerHorizontal } from '../../SpinerHorizontal/SpinerHorizontal'
import { BalancesContext, DataType } from '../../../Contexts/UserContext/BalancesContext'


export const DashboardBox: React.FC<DepositExtractionBoxProps> = ({

}) =>{

  const { dataTable, setDataTable, mutateBalance, setMutateBalance } = useContext(BalancesContext) 
  const { getAllCheckout, } = useCashDesk()
  const {data: cashs} = useGetCashDesk()

  const [loadingTableData, setLoadingTableData] = useState<boolean>(false)
  const [heightTable, setHeightTable] = useState<number>(window.innerHeight - 170)
  const [loadingRefresh, setLoadingRefresh] = useState(false)
 
  const updateHeight = () => {
    const newHeight = window.innerHeight - 170;
    setHeightTable(newHeight);
  };

  useEffect(() => {
    // Escucha eventos de cambio de tamaño
    window.addEventListener('resize', updateHeight);

    return () => {
      // Limpia el evento al desmontar el componente
      window.removeEventListener('resize', updateHeight);
    };
  }, []);

   
  useEffect(()=>{
    handleSearch(false, false)
    if(mutateBalance){
      setMutateBalance(false)
    }
  },[mutateBalance])

  const rowData = (data: CashDeskProps[]) => {
    let filterData: DataType[] = [];
    
    if (data) {
      filterData = data.map((data, index) => ({
        index,
        id: data.id ,
        checkout: data.name || '',
        balance: data.balance?.toString() || '',
        future_balance: data.future_balance.toString() || '',
        branch_name: data.branch?.name || '',
        branch_id: data.branch.id.toString() || '',
        currency_sign: data.currency?.sign || '',
        currency_id: data.currency?.id?.toString() || '',
      }));
  
      // Ordenar por branch_id de menor a mayor
      filterData.sort((a, b) => Number(a.branch_id) - Number(b.branch_id));
    }
  
    setDataTable(filterData);
  };


  const filters = (type: 'branch' | 'currency' ) => {
    let textAndValue: { text: string, value: string }[] = [];
    let uniqueValues = new Set();

    cashs?.forEach((item) => {
        let code =  ''
        let value = ''

        switch(type){
          case 'branch':
            code = item.branch.name 
            value = item.branch.name 
            break;
          case 'currency':
            code = item.currency.name
            value = item.currency.id.toString()
            break;
        }

        if (!uniqueValues.has(code) ) {
            uniqueValues.add(code);
            textAndValue.push({
                text: code,
                value: value
            });
        }
    });

      return textAndValue;
    }
   
    const columns: ColumnsType<DataType> = [
        {
          title: 'Sucursal',
          dataIndex: 'branch_name',
          key: '1',
          width: 100,
          filters: filters('branch'),
          onFilter: (value, record) => typeof value === 'string' && record.branch_name===value,
          filterSearch: true, 
        },
        {
            title: 'Caja',
            dataIndex: 'checkout',
            key: '2',
            width: 100,
            className:'cursor-pointer',
            filters: filters('currency'),
            onFilter: (value, record) => typeof value === 'string' && record.currency_id===value,
            filterSearch: true, 
        },
        {
            title: 'Saldo actual',
            dataIndex: 'balance',
            render:(text, record)=><div
              className='hover_row'
              style={{
                color: Number(text) < 0 ? 'red' : '#37bde0',
                display: 'flex',
                justifyContent: 'flex-end'

              }}
            >
              {record.currency_sign}{' '}
              { new Intl.NumberFormat('es-AR', { style: 'decimal', minimumFractionDigits: 2 }).format(text)}
            </div>,  
            key: '3',
        },
        {
            title: 'Saldo Futuro',
            dataIndex: 'future_balance',
            render:(text, record)=><div
              className='hover_row text_right'
              style={{
                color:Number(record.balance) > Number(text) ? 'orange' : Number(record.balance) < Number(record.future_balance) ? 'rgb(47, 202, 133)' : '#ADADAD',
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >{new Intl.NumberFormat('es-AR', { style: 'decimal', minimumFractionDigits: 2 }).format(text)}</div>,
            key: '4',
            className:'cursor-pointer',
            ellipsis: true,           
        },       
    ];    
 
    const handleSearch = async(loading: boolean, loadingRefresh: boolean) =>{
      if(loading){
        setLoadingTableData(true)
      }      
      const data = await getAllCheckout(setLoadingTableData) 
      if(data){
        rowData(data)
      }       
    }       

    return(
        <div className='dashboard-box'>
          <Row style={{
            display: 'flex',
           // justifyContent: 'center',
            alignItems: 'center'
          }}>
            <p>SALDO DE CAJAS</p>
            <Button 
                  type='primary'
                  className='allCheckout_section_btn'
                  onClick={()=>handleSearch(false, true)}
                  style={{marginLeft: 10}}
                  loading={loadingRefresh}
              >
                  Refresh                        
              </Button>  
          </Row>
            
            <div 
                className='dashboard-box_container'  
                style={{
                    marginTop:'15px'
                }}          
            >  
            <div
              style={{
                padding: '5px 15px',
               
              }}
            >
              {
                !loadingTableData ?
                <Table 
                  style={{
                      width: 530,
                  }}
                    columns={columns} 
                    dataSource={dataTable}                                
                    pagination={false}
                    scroll={{ y: heightTable }} 
                /> :
                <SpinerHorizontal/>            
              }
            </div>                                                               
            </div>   
        </div>
         
    )
}