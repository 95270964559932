import React, { useContext, useEffect, useState } from 'react';
import {
  ContainerOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  ShopOutlined,
  SwapOutlined,
  UserSwitchOutlined,
  DollarOutlined,
  DatabaseOutlined,  
  CaretRightOutlined 
} from '@ant-design/icons';
import type { MenuProps } from 'antd';
import {   Menu as MenuAntd} from 'antd';
import './styles.scss'
import { useNavigate } from 'react-router';
import { MenuAppProps } from './types';
import { UserContext } from '../../Contexts/UserContext/UsersContext';

type MenuItem = Required<MenuProps>['items'][number];

const Menu: React.FC<MenuAppProps> = () => {

  
  const {userData, initializationMode} = useContext(UserContext)
  const [collapsed, setCollapsed] = useState(true);
  const [MenuItems, setMenuItems] = useState<MenuItem[]>() 
  
  useEffect(()=>{
    if(userData){
      setMenuItems( [
        getItem('Menu', 'collapsed', collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />),
        ...(initializationMode
          ? 
          [getItem('Inicializar saldos', 'initialization', <CaretRightOutlined style={{ fontSize: 17}}/>)] : []
        ),
        ...(!initializationMode
          ? 
        [
          
          getItem('Ingreso y egreso Cajas', 'all_checkout', <ShopOutlined style={{ fontSize: 20 }} />),        
          getItem('Operaciones', 'operations', <DollarOutlined style={{ fontSize: 17}} />),        
          getItem('Transacciones', 'transactions', <DatabaseOutlined style={{ fontSize: 17 }} />),    
          getItem('Movimientos por cajas', 'cash_movements', <SwapOutlined style={{ fontSize: 17 }} />),
          getItem('Movimientos cuenta cliente', 'account_movements', <UserSwitchOutlined style={{ fontSize: 19 }} />)
        ] 
        : []),            
        //  getItem('Saldos de Cajas', 'balance_sheets', <TruckOutlined style={{ fontSize: 20 }} />),
        ...(userData?.role =='Administrador'
          ? [
              getItem('Administración', 'admin', <ContainerOutlined />, [
                getItem('Usuarios', 'user'),
                getItem('Clientes', 'customers'),
                getItem('Moneda', 'currency'),
                getItem('Tipo de cambio', 'exchange_rate'),
                getItem('Sucursales', 'branch'),
                getItem('Cajas', 'checkout'),
                getItem('Operadores de cajas', 'operation_cash_sections'),
                getItem('Reporte de balances', 'general_balance'),
              ]),
            ]
          : []),
      ])
    }
    
  },[userData, initializationMode])
  
  const navigate = useNavigate();

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group',
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}


const onClick: MenuProps['onClick'] = (e) => {
    switch(e.key){
      case 'collapsed':  
        toggleCollapsed();
        break;
      case 'account_movements':       
        navigate('/dashboard/account_movements');
        break;
      case 'cash_movements':       
        navigate('/dashboard/cash_movements');
        break;  
      case 'extraction_deposit':       
        navigate('/dashboard/extraction_deposit');
        break;
      case 'purchase_sale_currency':       
        navigate('/dashboard/purchase_sale_currency');
        break;  
      case 'extraction_deposit_banc':       
        navigate('/dashboard/extraction_deposit_banc');
        break;          
      case 'user':       
        navigate('/dashboard/user');
        break;
      case 'currency':       
        navigate('/dashboard/currency');
        break;  
      case 'branch':       
        navigate('/dashboard/branch');
        break;   
      case 'checkout':       
        navigate('/dashboard/checkout');
        break;     
      case 'exchange_rate':       
        navigate('/dashboard/exchange_rate');
        break;  
      case 'operations':       
        navigate('/dashboard/operations');
        break;   
      case 'balance_sheets':       
        navigate('/dashboard/balance_sheets');
        break; 
      case 'customers':       
        navigate('/dashboard/customers');
        break;    
      case 'operation_cash_sections':       
        navigate('/dashboard/operation_cash_sections');
        break; 
      case 'all_checkout':       
        navigate('/dashboard/all_checkout');
        break;   
      case 'transactions':       
        navigate('/dashboard/transactions');
        break;
      case 'general_balance':       
        navigate('/dashboard/general_balance');
        break; 
      case 'initialization':       
        navigate('/dashboard/initialization');
        break;          
      default:
        break;   
    }
  };
  
  return (
    <div  className='menu_container'>
        <MenuAntd
            style={{
                zIndex: 200,
                borderRadius: '7px',
                width: collapsed ? '72px' : '256px'
            }}
            onClick={onClick}
            defaultSelectedKeys={['1']}
            defaultOpenKeys={[]}
            mode="inline"
            theme="dark"
            inlineCollapsed={collapsed}
            items={MenuItems}
        />
    </div>
  );
};

export default Menu;