
import {
  END_POINT_OPERATION_GET_ALL, 
  END_POINT_OPERATION, 
  END_POINT_OPERATION_DEPOSIT_AND_EXTRACTIONS,
  END_POINT_OPERATION_CONFIRM,
  END_POINT_GET_MAIN_OPERATIONS,
  
} from '../Axios/ENDPOINTS';
import { axiosBackEndInstance } from "../Axios";
import { useErrorHandling } from "../hooks/useErrorHandling";
import { notification } from "antd";
import { useContext } from 'react';
import { BalancesContext } from '../Contexts/UserContext/BalancesContext';
import { Transaction } from './useGetMovements';
import { MovementCashContext } from '../Contexts/UserContext/MovementCashContext';
import { OperationsContext } from '../Contexts/UserContext/OperationsContext';
import { MovementAccountContext } from '../Contexts/UserContext/MovementAcountContext';
import { checkoutDetails } from '../Components/Forms/PucrchaseSaleCurrenciesForm/types';

interface Currency {
  id: number;
  name: string;
  sign: string;
}

interface Customer {
  id: number;
  created_at: string;
  updated_at: string;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
}

interface AccountType {
  id: number;
  created_at: string;
  updated_at: string;
  name: string;
}

interface Account {
  id: number;
  created_at: string;
  updated_at: string;
  description: string;
  balance: number;
  currency: Currency;
  customer: Customer;
  type: AccountType;
}

interface Checkout {
  id: number;
  created_at: string;
  updated_at: string;
  description: string;
  branch_id: number;
  currency: Currency;
  balance: number;
  name: string;
  future_balance: number
}

interface OperationType {
  id: number;
  name: string;
}

interface User {
  id: number;
  created_at: string;
  updated_at: string;
  email: string;
  first_name: string;
  last_name: string;
  role: string;
}


export interface OperationTypeProps {
  type_id: number;
  date?: string;
  origin_checkout_id?: number;
  destination_checkout_id?: number;
  origin_account_id?: number;
  destination_account_id?: number;
  customer_id?: number;
  main_currency_id?: number;
  secondary_currency_id?: number;
  user_id?: number;
  credit?: number;
  debit?: number;
  currency_sale?: boolean;
  related_operation_id?: number;
  currency_quote_buy_price?: number;
  currency_quote_sell_price?: number;
  commission?: number;  
  description?: string;  
  approved?: boolean;  
  currency_id?: number;
  destination_customer_id?: number,
  destination_currency_id?: number,   
  origin_customer_id?: number,
  origin_currency?: number,  
  usdt_sale?: boolean,
  usdt_quote_percentage?: number,
  supplier_id?: number,
  supplier_commission?: number,
  transfer_costs?:  number,   
  client_pays_costs?: boolean

  destination_operation_details?: checkoutDetails[], 
  origin_operation_details?: checkoutDetails[],
  origin_checkout?: checkoutDetails[];
  destination_checkout?: checkoutDetails[];
}


export const useOperations= () => {

    const {errorHandling} = useErrorHandling()
    
  const { setMutateBalance } = useContext(BalancesContext) 
  const { setMutate: setMutateCash }= useContext(MovementCashContext)
  const { setMutate: setMutateAccount } = useContext(MovementAccountContext)
  const { 
    setMutate: setMutateOperationsSections,
    setMutateAllCheckout,
    setMutateDataSummary
   }= useContext(OperationsContext)
     
  const getOperationAmount = async (      
    callback: Function,
    values:{
      customer_id: number | undefined,
      currency_id: number | undefined, 
      checkout_id: number | undefined,   
      start_date: string | undefined,
      end_date: string | undefined,
      operation_type: string | undefined,
      branch_id: number | undefined,
    }     
  ): Promise<Transaction[] | undefined> => {      
    try {

      var params = new URLSearchParams();
      values.currency_id && params.append("currency_id", values.currency_id.toString())
      values.customer_id && params.append("customer_id", values.customer_id.toString())
      values.checkout_id && params.append("checkout_id",  values.checkout_id.toString())
      values.branch_id && params.append("branch_id",  values.branch_id.toString())
      values.start_date && params.append("start_date",  values.start_date.toString())
      values.end_date && params.append("end_date",  values.end_date.toString())
      values.operation_type && params.append("types_id",  values.operation_type.toString())

      const data = await axiosBackEndInstance.get(
          END_POINT_OPERATION_GET_ALL,
          { 
              params
          }
      )

      callback()
      if(data.data){
          return data.data
      }
    } catch (newError) {
      callback()
      const status =  await errorHandling(newError).request.status      
      console.log('status ' + status + ' ' + newError);      
    }
  };

  const getOperation = async(
    operation_id: number
  ): Promise<Transaction | undefined>=>{
    try{
      const data = await axiosBackEndInstance.get(
        `${END_POINT_OPERATION}${operation_id}`        
      )
      if(data){
        return data.data
      }
    }catch(e){

    }    
  }

  const getMainOperations = async (
    callback: Function,
    values:{
      customer_id?: number,
      currency_id?: number, 
      checkout_id?: number,   
      start_date?: string,
      end_date?: string,
      operation_type?: string ,
      branch_id?: number,
      include_accounts_movements?: boolean 
    }    
  ):Promise<Transaction[] | undefined> =>{

    var params = new URLSearchParams();
    values.currency_id && params.append("currency_id", values.currency_id.toString())
    values.customer_id && params.append("customer_id", values.customer_id.toString())
    values.checkout_id && params.append("checkout_id",  values.checkout_id.toString())
    values.branch_id && params.append("branch_id",  values.branch_id.toString())
    values.start_date && params.append("start_date",  values.start_date.toString())
    values.end_date && params.append("end_date",  values.end_date.toString())
    values.operation_type && params.append("types_id",  values.operation_type.toString())
    values.include_accounts_movements &&  params.append("include_accounts_movements",  values.include_accounts_movements.toString())

   // params.append("skip", '0')
    //params.append("limit", "1000000")

    try{
      const data = await axiosBackEndInstance.get(
        END_POINT_GET_MAIN_OPERATIONS,
        { 
            params
        }
    )

    if(data){
      callback()
      return data.data
    }

    }catch(newError){
      callback()
      const status =  await errorHandling(newError).request.status      
      console.log('status ' + status + ' ' + newError); 
    }
  } 

  const createOperation = async (
    callback: Function,
    values: OperationTypeProps    
    ): Promise<Transaction | void | 'error'> => {      
  try {
    const  response = await axiosBackEndInstance.post(
      END_POINT_OPERATION, values
    )
    const status =  errorHandling(response).request.status
    if(status === 200 && response){
      callback()
      notification.success({
        message: 'Éxito', 
        description:'Operación guardada con éxito', 
        placement: 'topRight'
      })
      setMutateBalance(true)
      setMutateCash(true)
      setMutateOperationsSections(true)
      setMutateAllCheckout(true)
      setMutateDataSummary(true)
      setMutateAccount(true)
      return response.data
    }
    } catch (newError: any) {
      if(newError && newError.response && newError.response.data?.detail === "Account doesn't have the sufficient funds"){
        notification.warning({
          message: 'Info.', 
          description:'La cuenta no tiene suficientes fondos para realizar la operación', 
          placement: 'topRight'
        }) 
      }else if(newError && newError.response && newError.response.data?.detail?.error_number === 12){
        let checkout = newError.response.data?.detail.checkout?.name || ''
        notification.warning({
          message: 'Info', 
          description:`La caja ${checkout} no tiene suficientes fondos para realizar la operación`, 
          placement: 'topRight'
        }) 
      }else{
        notification.error({
          message: 'Error', 
          description:`Hubo un error, por favor comuníquese con el administrador.`, 
          placement: 'topRight'
        })
      }
      console.log(newError); 
      callback()

      return 'error'          
    }
  };

  const editOperation = async (
    callback: Function,
    operation_id: string,
    values: OperationTypeProps    
    ): Promise<Transaction | void> => {      
  try {
    const  response = await axiosBackEndInstance.put(
      `${END_POINT_OPERATION}${operation_id}`, values
    )
    const status =  errorHandling(response).request.status
    if(status === 200 && response){
      callback()
      notification.success({
        message: 'Éxito', 
        description:'Operación editada con éxito', 
        placement: 'topRight'
      })
      setMutateBalance(true)
      setMutateCash(true)
      setMutateOperationsSections(true)
      setMutateAllCheckout(true)
      setMutateDataSummary(true)
      setMutateAccount(true)
      return response.data
    }
    } catch (newError: any) {
      if(newError && newError.response && newError.response.data?.detail === "Account doesn't have the sufficient funds"){
        notification.warning({
          message: 'Info', 
          description:'La cuenta no tiene suficientes fondos para realizar la operación', 
          placement: 'topRight'
        }) 
      }else if(newError && newError.response && newError.response.data?.detail?.error_number === 12){
        let checkout = newError.response.data?.detail.checkout?.name || ''
        notification.warning({
          message: 'Info', 
          description:`La caja ${checkout} no tiene suficientes fondos para realizar la operación`, 
          placement: 'topRight'
        }) 
      }
      console.log(newError);
      callback()      
    }
  };

  const deleteOperation = async (
    callbackEliminate: Function,
    operation_id: number
    ): Promise<Transaction | void> => {      
  try {
    const  response = await axiosBackEndInstance.delete(
      `${END_POINT_OPERATION}${operation_id}`
    )
    const status =  errorHandling(response).request.status
    if(status === 200 && response){
      callbackEliminate()
      setMutateBalance(true)      
      setMutateCash(true)
      setMutateOperationsSections(true)
      setMutateAllCheckout(true)
      setMutateDataSummary(true)
      setMutateAccount(true)
      notification.success({
        message: 'Éxito', 
        description:'Operación eliminada con éxito', 
        placement: 'topRight'
      })
      return response.data
    }
    } catch (newError) {
      console.log(newError);
      callbackEliminate()   
      notification.error({
        message: 'Error', 
        description:'Hubo un error, comuníquese con el administrador', 
        placement: 'topRight'
      })   
    }
  };

  const getDepositAndExtractions = async (
    callback: Function,
    customer_id: number        
    ): Promise<Transaction[] | void> => {      
  try {
    const  data = await axiosBackEndInstance.get(
      `${END_POINT_OPERATION_DEPOSIT_AND_EXTRACTIONS}`
      //`${END_POINT_OPERATION_DEPOSIT_AND_EXTRACTIONS}/${customer_id}`
    )
    const status =  errorHandling(data).request.status
    if(status === 200){
      callback()      
      return data.data
    }
    } catch (newError) {
      console.log(newError);
      callback()      
    }
  };  

  const confirmOperation = async(
    operation_id: number,
    setLoadingApproved: Function
  ): Promise<number | undefined> =>{
    try{
      const data = await axiosBackEndInstance.put(
      `${END_POINT_OPERATION_CONFIRM}/${operation_id}`
      )
      if(data){
        setLoadingApproved(false)
        setMutateBalance(true)
        setMutateCash(true)
        setMutateOperationsSections(true)
        setMutateAllCheckout(true)
        setMutateDataSummary(true)
        setMutateAccount(true)
        notification.success({
          message: 'Éxito', 
          description:'Operación aprobada con éxito', 
          placement: 'topRight'
        })
        return data.status
      }
    }catch(error: any){
      console.log(error)
      if(error?.response?.status===402){
        setLoadingApproved(false)
        notification.warning({
          message: 'Info', 
          description:'La caja no tiene suficientes fondos', 
          placement: 'topRight'
        })
      }
    }
  }
 
  return {
    getOperationAmount,
    createOperation,
    getDepositAndExtractions,
    confirmOperation,
    deleteOperation,
    editOperation,
    getMainOperations,
    getOperation
  };
};
