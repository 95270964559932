import React, {
    createContext,
    useEffect,
    useState,
  } from 'react';
import { ResponseAccounts } from '../../Services/useAccounts';

export interface DataType {
  approved: string | undefined;
  balance: string | undefined;
  balance_destination_checkout:string | undefined;
  balance_origin_checkout: string | undefined;
  can_be_approved: string | undefined;
  client_pays_costs: string | undefined;
  commission: string | undefined; 
  created_by: string | undefined;
  credit: string | undefined;
  credit_number: string | undefined;
  currency_quote_buy_price: string | undefined;
  currency_quote_sell_price: string | undefined;
  currency_sale: string | undefined;
  customer_first_name: string | undefined;
  customer_id: string | undefined;
  customer_last_name: string | undefined;
  date: string | undefined;
  debit: string | undefined;
  debit_number: string | undefined;
  description: string | undefined;
  deleted: string | undefined;
  deposit_operation_approved: string | undefined;
  destination_account_currency_id: string | undefined;
  destination_account_currency_name: string | undefined;
  destination_account_currency_sign: string | undefined;
  destination_account_customer_id: string | undefined;
  destination_account_customer_last_name: string | undefined;
  destination_account_customer_name: string | undefined;
  destination_account_descriptrion: string | undefined;
  destination_account_balance: string | undefined;
  destination_balance: string | undefined;
  destination_checkout_currency_id: string | undefined;
  destination_checkout_currency_name: string | undefined;
  destination_checkout_currency_sign: string | undefined;
  destination_checkout_id: string | undefined;
  destination_checkout_name: string | undefined;
  destination_checkout_sign: string | undefined;
  destination_checkout_branch: string | undefined;
  destination_checkout_branch_id: string | undefined;
  destination_account_id: string | undefined;
  destination_checkout_balance: string | undefined;
  destination_operation_details_sing: string | undefined;
  extraction_operation_approved: string | undefined;
  full_name: string | undefined;
  id: string | undefined;
  is_deleting_operation: string | undefined;
  is_sale: string | undefined;  
  main_currency_id: string | undefined;
  main_currency_sign: string | undefined;
  main_operation_id: string | undefined;
  operation_id: string | undefined;
  operation_type_id: string | undefined;
  operation_type_name: string | undefined;
  origin_account_id: string | undefined;
  origin_account_currency_id: string | undefined;
  origin_account_currency_name: string | undefined;
  origin_account_currency_sign: string | undefined;
  origin_account_customer_id: string | undefined;
  origin_account_customer_last_name: string | undefined;
  origin_account_customer_name: string | undefined;
  origin_account_descriptrion: string | undefined;  
  origin_account_balance: string | undefined;
  origin_balance: string | undefined;
  origin_checkout_currency_id: string | undefined;
  origin_checkout_currency_name: string | undefined;
  origin_checkout_currency_sign: string | undefined;
  origin_checkout_id: string | undefined;
  origin_checkout_name: string | undefined;
  origin_checkout_branch: string | undefined;
  origin_checkout_branch_id: string | undefined;
  origin_operation_details_sing: string | undefined;
  origin_checkout_balance: string | undefined;
  related_operation_id: string | undefined;
  secondary_currency_id: string | undefined;
  secondary_currency_name: string | undefined;
  secondary_currency_sign: string | undefined;
  supplier_commission: number | undefined;
  transfer_costs: string | undefined;
  usdt_sale: string | undefined;
  usdt_quote_percentage: string | undefined;
  user_id: string | undefined;
  user_last_name: string | undefined;
  user_name: string | undefined ;
  updated_by: string | undefined
  associated_operation: string | undefined;
  windows_operation: string | undefined;
  approved_date: string | undefined;
  create_date: string | undefined;
  approval_date: string | undefined; 
  show_user: string;
  tooltip_destination: string | undefined;  
  tooltip_origin: string | undefined;
  tooltip_balance_destination: string | undefined;
  tooltip_balance_origin: string | undefined;
  total_credit_operations: string | undefined;
  total_debit_operations: string | undefined;
  account_credit?: string | undefined;
  account_debit?: string | undefined;
  checkout_credit?: string| undefined;
  checkout_debit?: string | undefined;
  customer_name?: string | undefined;
  created_at?: string | undefined;
  updated_at?: string | undefined;
  created_at_hr?: string | undefined;
  updated_at_hr?: string | undefined;
}

  class VoidContext implements MovementAccountContext {
    get dataTable(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setDataTable(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get mutate(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setMutate(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get showingAccount(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setShowingAccount(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get balance(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setBalance(): never {
      throw new Error('Cannot consume context outside of provider');
    }
  }

  interface MovementAccountContext {
    dataTable: DataType[], 
    setDataTable: Function,
    mutate: boolean 
    setMutate: Function,
    showingAccount:{
      user_id: number,
      user: string,
      sign: string
      sign_id: number,
    } | undefined, 
    balance: ResponseAccounts | undefined,
    setBalance: Function
    setShowingAccount: Function
  }

  interface propsWithChildren {
    children: React.ReactNode
  }
  
  export const MovementAccountContext = createContext<MovementAccountContext>(new VoidContext());
  export const MovementAccountContextProvider= ({ children }: propsWithChildren): JSX.Element => {

    const [dataTable, setDataTable] = useState<DataType[]>([])
    const [mutate, setMutate] = useState<boolean>(false)
    const [balance, setBalance] = useState<ResponseAccounts | undefined>()

    const [ showingAccount, setShowingAccount ] = useState<{
      user_id: number,
      user: string,
      sign: string
      sign_id: number
    } | undefined>()

 
    return (
      <MovementAccountContext.Provider value={{
        dataTable, 
        setDataTable,
        mutate, 
        setMutate,
        showingAccount, 
        setShowingAccount,
        balance, 
        setBalance

      }}>{children}</MovementAccountContext.Provider>
    );
  };



  