import { useEffect, useState } from "react";
import { Button,  Col, Form, InputNumber, notification, Row, Select } from "antd";
import './styles.scss';
import { useGetBranch } from "../../../Services/useGetBranch";
import {PlusOutlined, DeleteOutlined} from "@ant-design/icons"
import { useForm } from "antd/es/form/Form";
import { CheckoutTablesProps, FormCheckouts, ListCheckout } from "./types";


export const CheckoutTables: React.FC<CheckoutTablesProps> = ({
    listCheckout, 
    setListCheckout,
    checked,
    transactionsData,
    listCahs
}) =>{
    
     
    const {data: branches } = useGetBranch()
    const {Option} = Select

    const [form_list_checkouts] = useForm()
    
    const [branchSelected, setBranchSelected] = useState<number | undefined>()    
    //const [cashSelected, setCashSelected] = useState<number | null>(null);

    useEffect(()=>{
        if(!transactionsData){
            form_list_checkouts.resetFields();
            setListCheckout([])
        }        
    },[checked])

    const addCheckOut = () => {
        let data: FormCheckouts = form_list_checkouts.getFieldsValue();

        if(data && data.checkout?.key){
            const newCheckout: ListCheckout = {
                name: data?.checkout?.label,
                id: data.checkout?.key,
                sign: data?.checkout?.title,
                amount: data?.amount,
                branch_name: listCahs?.find((x)=>x.id === Number(data.checkout.key))?.branch.name || ''
            };
        
            // Verificar si ya existe en la lista
            const exists = listCheckout.some(item => Number(item.id) === Number(newCheckout.id));
        
            if (!exists) {
                setListCheckout(prev => [...prev, newCheckout]);
                form_list_checkouts.resetFields(['checkout', 'amount']);
            }else{
                notification.warning({
                    message: 'Ya hay un movimiento agregado apra esa caja'
                })
            }
        }
    
       
    };

    const removeCheckOut = (index: number) => {        
        setListCheckout((prev) => prev.filter((x) => Number(x.id) !== Number(index)));
    };

    const calculateTotals = ()=>{
        let total: number = 0
        listCheckout.map((item)=>{total = total + Number(item.amount) })
        return total.toLocaleString('es-AR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    }

    return(
        <>
          {
            checked && !transactionsData?.approved &&
            <Form
                form={form_list_checkouts}
                id='form_list_checkouts'
                layout="vertical" 
            >
                <Row 
                    style={{ 
                        display: 'flex', 
                        gap: '10px', 
                        width: '100%',
                        marginBottom: 10 
                    }}                
                >
                    <Col >
                        <Form.Item
                            name='branch_cahs'
                            style={{margin:0}}
                        >
                            <Select
                                disabled={transactionsData?.approved} 
                                style={{width: 100}}
                                labelInValue  
                                onChange={(e)=>{
                                    setBranchSelected(+e.key)
                                   // setCashSelected(null);
                                    form_list_checkouts.setFieldValue('checkout', undefined)
                                }}
                                placeholder= 'Filtrar'
                            >
                                {branches && branches.map((item) => (
                                    item.id !== 0 &&
                                    <Option value={item.name} key={item.id}>
                                        {item.name}
                                    </Option>
                                ))} 
                            </Select> 
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            name='checkout' 
                            style={{margin:0}}
                        >
                            <Select
                                labelInValue
                                style={{ width: 150 }}
                                placeholder="Caja"
                                disabled={transactionsData?.approved}
                            >
                                {listCahs &&
                                    listCahs
                                        .filter(item => !branchSelected || item.branch?.id === branchSelected || item.branch?.id === 0)
                                        .map(item => (
                                            <Option value={item.name} key={item.id} title={item.currency.sign}>
                                                {item.name}
                                            </Option>
                                        ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col style={{ flex: 1 }}>
                        <Form.Item
                            name='amount' 
                            style={{margin:0}}
                            
                        >
                            <InputNumber   
                                disabled={transactionsData?.approved} 
                                min={0}                         
                                style={{width: '100%'}}
                                placeholder="0.00"
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') addCheckOut();
                                }}
                            /> 
                        </Form.Item>                                                  
                    </Col>
                    <Col
                        className="center_element"
                    >
                        <Button
                            disabled={transactionsData?.approved} 
                            onClick={(e: any)=>addCheckOut()} 
                            style={{
                                borderColor: 'rgb(185, 185, 185)',
                                height: 30,
                                width: 31,
                                padding:0
                            }}
                           
                        >
                            <PlusOutlined style={{color: 'gray', fontSize: 12, padding: 0, margin: 0}}/>
                        </Button>                        
                    </Col>                
                </Row>    
            </Form>       
        } 
        {
            listCheckout && listCheckout.length > 0 &&
                <Row
                    style={{
                        padding: 0,
                        overflowY:'auto',
                        maxHeight: 200,
                        marginBottom: 10
                    }}
                >
                    { listCheckout.map((item, index)=>
                        <Row 
                            key={index}
                            style={{ 
                                display: 'flex', 
                                width: '100%',
                                height: 25,
                                marginBottom: 3,
                                backgroundColor: 'white',
                                padding:'3px 8px',
                                borderRadius: 4
                            }}
                        >                               
                            <Col 
                                span={12}                                
                            >
                                <span style={{fontWeight: 700}}>{item.branch_name} {' / '} {item.name}</span>
                            </Col>
                            <Col
                             style={{
                                display: 'flex',
                                justifyContent: 'end'  
                              }}
                                span={10}
                            >
                            <span>{item.sign}{' '}{item.amount?.toLocaleString('es-AR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                            </Col>
                            <Col
                                className="cursor-pointer"
                                style={{
                                  display: 'flex',
                                  justifyContent: 'end',
                                  color:transactionsData?.approved ? 'rgb(173, 173, 173)' : '',
                                  cursor: transactionsData?.approved ? 'not-allowed' : 'pointer'
                                }}
                                span={2}
                            >
                                <DeleteOutlined 
                                    style={{cursor: transactionsData?.approved ? 'not-allowed' : 'pointer'}}
                                    disabled={transactionsData?.approved} 
                                    onClick={()=>{
                                        if(!transactionsData?.approved){
                                            removeCheckOut(item.id)
                                        }
                                    }}
                                />
                            </Col>
                        </Row>
                    )}
                </Row>              
            }
            {
                listCheckout && listCheckout.length > 0 &&
                <Row
                    style={{ 
                        display: 'flex', 
                        width: '100%',
                        marginBottom: 3,
                        marginTop: 5,
                        backgroundColor: 'white',
                        padding:'3px 8px',
                        borderRadius: 4
                    }}
                >
                    <Col 
                       span={4}
                    >
                    <span style={{fontWeight: 700}}>Total:</span>
                    </Col>
                    <Col
                        span={20}
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            color: '#37bde0',
                            fontWeight: 700
                        }}
                    >
                        <span>{listCheckout[0].sign}{' '}{calculateTotals()}</span>
                    </Col>
                </Row>
            }
        </>

    )
}

export default CheckoutTables