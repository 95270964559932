import React, { useContext, useEffect, useState } from "react";
import './styles.scss';
import {  Button, Checkbox, CheckboxProps, Col, Form, Input, InputNumber, Modal, notification, Row, Select } from "antd";
import { PucrchaseSaleCurrencyFormProps, selectedCurrenciesProps, ValuesForm } from "./types";
import { useGetAllClients } from "../../../Services/useGetAllClients";
import { ResponseAccounts, useAccounts } from "../../../Services/useAccounts";
import './styles.scss';
import { OperationTypeProps, useOperations } from "../../../Services/useOperations";
import { useCurrency } from "../../../Services/useCurrency";
import { VariablesContext } from "../../../Contexts/UserContext/VariablesContext";
import { UserContext } from "../../../Contexts/UserContext/UsersContext";
import { Transaction } from "../../../Services/useGetMovements";
import ModalTitle from "../../ModalTitle";
import { OperationsContext } from "../../../Contexts/UserContext/OperationsContext";
import { SwapOutlined } from "@ant-design/icons"
import { FormItems } from "./FormItems";
import { useGetCurrency } from "../../../Services/useGetCurrency";
import { ListCheckout } from "./types";
import NewCustomer from "../../NewCustomer";


export const PucrchaseSaleCurrencyForm: React.FC<PucrchaseSaleCurrencyFormProps> =({
    form_buy_and_sell,
})=>{

    const {data: clients} = useGetAllClients()    
    const {createOperation, editOperation, getOperation} = useOperations()
    const {getExchangeRate} = useCurrency()
    const {getAccountsCurrencyAndCustomer} = useAccounts() 
    const {data: allCurrency} = useGetCurrency() 
    const {collapsedFilsters, setCollapsedFilters, setShowForms, showForms, setSelectedRowId, selectedRowId} = useContext(VariablesContext) 
    const {setRowSelect} = useContext(OperationsContext)
    const { userData } =useContext(UserContext)
    const [ spanError, setSpanError ] = useState<string | undefined>()    
    const [ warning, setWarning ] = useState<string | undefined>()     
    const { Option } = Select;   
    
    const [loadingBtn, setLoadingBtn] = useState(false)
    const [operationType, setOperationType] = useState<'sell' | 'buy' | undefined>()
    const [aproved, setAproved] = useState(false)    
    const [balance, setBalance] = useState<ResponseAccounts | undefined>()
    const [count, setCount] = useState(0)
    const [result, setResult] = useState<number>()
    const [amount, setAmount] = useState<number>()
    const [typeChange, setTypeChange] = useState<number>()
    const [transactionsData, setTransactionsData] = useState<Transaction>()
    const [invested, setInvested] = useState<boolean>(false)
    const [isUSDT, setIsUSDT] = useState<boolean>(false)
    const [comission, setComission] = useState<number>()
    const [firstListCheckout, setFirstListCheckout] = useState<ListCheckout[]>([])
    const [secondListCheckout, setSecondListCheckout] = useState<ListCheckout[]>([])

    const [curriencies, setCurrencies] = useState<{
        main_currency?: selectedCurrenciesProps;
        secondary_currency?: selectedCurrenciesProps;
    } | undefined>();

    const [formCheks, setFormChecks] = useState<{
        deposit: boolean,
        extraction: boolean,
        isWindow: boolean
    }>({
        deposit: false,
        extraction: false,
        isWindow: false
    });  
    const [customerSelected, setCustomerSelected] = useState<{
        name: string,
        id: string,
    }>()


    const mandatory = [
        {
            required: true,
            message:'Este campo es obligatorio'
        }
    ]  

    useEffect(()=>{ 
        if(selectedRowId){
            uploadData(selectedRowId)
        }else{            
            form_buy_and_sell.resetFields()
            setCurrencies(undefined)
        }    
    },[])

    
    useEffect(()=>{
        if(curriencies?.main_currency && curriencies.secondary_currency){
            if(selectedRowId){
                setCount(2)
                if(count > 1){
                    checkExchangeRate()                 
                }
            }else{
                checkExchangeRate()               
            }            
        }     
        if(curriencies?.main_currency?.id==='3'){
            setIsUSDT(true)
        }else{
            setIsUSDT(false)
        } 

    },[curriencies])
    

    useEffect(() => {
        if (!curriencies?.main_currency?.id || !curriencies?.secondary_currency?.id) return;
        const isUSDT = curriencies.main_currency.id === '3';
        const isSecondaryCurrencyNotUSD = curriencies.secondary_currency.id !== '2';
    
        if (invested) {
            if (result) {
                let calculate: number | null = null;
                if (isUSDT) {
                    if (isSecondaryCurrencyNotUSD && typeChange && comission !== undefined) {
                        calculate = (Number(result) / Number(typeChange)) / ((comission / 100) + 1);
                    } else if (comission !== undefined) {
                        calculate = Number(result) / ((comission / 100) + 1);
                    }
                } else if (typeChange) {
                    calculate = Number(result) / typeChange;
                }
                if (calculate !== null) {
                    setAmount(Number(calculate.toFixed(2)));
                    form_buy_and_sell.setFieldValue('amount', Number(calculate.toFixed(2)));
                }
            }
        } else {
            if (amount) {
                let calculate: number | null = null;
    
                if (isUSDT) {
                    if (isSecondaryCurrencyNotUSD && typeChange && comission !== undefined) {
                        calculate = ((comission / 100 + 1) * Number(amount)) * typeChange;
                    } else if (comission !== undefined) {
                        calculate = (comission / 100 + 1) * Number(amount);
                    }
                } else if (typeChange) {
                    calculate = typeChange * Number(amount);
                }
    
                if (calculate !== null) {
                    setResult(calculate);
                    form_buy_and_sell.setFieldValue('result', Number(calculate.toFixed(2)));
                }
            }
        }                
    }, [amount, typeChange, result, comission]);

    const calculateAmount = () =>{        
        setInvested(false)
        if (!curriencies?.main_currency?.id || !curriencies?.secondary_currency?.id) return;

        const isUSDT = curriencies.main_currency.id === '3';
        const isSecondaryCurrencyNotUSD = curriencies.secondary_currency.id !== '2';
        let calculate
        if (isUSDT) {
            if (isSecondaryCurrencyNotUSD && typeChange && comission !== undefined) {
                calculate = ((comission / 100 + 1) * Number(amount)) * typeChange;
            } else if (comission !== undefined) {
                calculate = (comission / 100 + 1) * Number(amount);
            }
            
        } else if (typeChange) {
            calculate = typeChange * Number(amount);
        }
        if (calculate !== undefined) {
            setResult(calculate);
            form_buy_and_sell.setFieldValue('result', Number(calculate.toFixed(2)));
        }
    }
    
    useEffect(()=>{
        if(customerSelected){
            if(operationType === 'buy' && curriencies?.main_currency){
                 getBalanceCash(curriencies?.main_currency)                
            }else if(curriencies?.secondary_currency && operationType === 'sell'){
                getBalanceCash(curriencies.secondary_currency)
            }else{
                setBalance(undefined)
            }            
        }        
    },[curriencies, customerSelected])

    useEffect(()=>{
        if(formCheks.isWindow ){ 
            if(firstListCheckout.length > 0 && secondListCheckout.length > 0) {          
                setSpanError(undefined)            
                return
            }
        } 
    },[firstListCheckout, secondListCheckout])

    const getBalanceCash = async(currency: selectedCurrenciesProps) =>{//CUANDO MONEDA Y CLIENTE ESTÁN CAGADOS, CALCULA EL BALANCE                
        if(customerSelected){
            const data = await getAccountsCurrencyAndCustomer(Number(customerSelected.id), Number(currency.id))
            if(data){
                setBalance((prevData: ResponseAccounts | undefined) => {
                    if (JSON.stringify(prevData) !== JSON.stringify(data)) {
                        return data;
                    }
                    return prevData; // No actualizar si son iguales
                });
            }  
        }                             
    }

    const uploadData = async(data: {id: string}) =>{
        let operationAmount : string = ''
        let type_change: string = ''
        
        const response = await getOperation(+data.id) 
        
        if(response){     
            let isSale = response.type.id === 7 
                ? response.currency_sale
                : response.type.id === 9 
                    ? response.usdt_sale
                    : undefined

            setOperationType(isSale ? 'sell' : 'buy');

            let buy_currency = isSale 
            ?  {  value: response.secondary_currency?.name, label: response.secondary_currency?.name, key: response.secondary_currency?.id}
            :  {  value: response.main_currency?.name, label: response.main_currency?.name, key: response.main_currency?.id}

            let sell_currency = isSale                
                ?  {  value: response.main_currency?.name, label: response.main_currency?.name, key: response.main_currency?.id}
                :  {  value: response.secondary_currency?.name, label: response.secondary_currency?.name, key: response.secondary_currency?.id}

            type_change =  response.currency_quote_buy_price //TIPO DE CAMBIO
                ? type_change = response.currency_quote_buy_price.toString()
                : response.currency_quote_sell_price
                    ? response.currency_quote_sell_price.toString()
                    : ''                      

            operationAmount = isSale
                ? response.credit?.toString() 
                : response.debit?.toString()

            setTransactionsData(response)
            setAproved(response.approved)
            setComission(response.usdt_quote_percentage) 
            setFormChecks({
                deposit: response.destination_operation_details?.length > 0,
                extraction: response.origin_operation_details?.length > 0,
                isWindow: response.windows_operation,
            })
            setCurrencies({
                main_currency: {
                    id: response.main_currency?.id?.toString(),
                    sign: response.main_currency?.sign,
                    name: response.main_currency?.name
                },
                secondary_currency: {
                    id: response.secondary_currency?.id.toString(),
                    sign: response.secondary_currency?.sign,
                    name: response.secondary_currency?.name
                },                
            })
            if(response.destination_operation_details?.length > 0){
                const newCheckout: ListCheckout[] = [];
                response.destination_operation_details.map((x)=> newCheckout.push({
                    name: x.checkout?.name,
                    id: x.checkout?.id,
                    sign: x.checkout?.currency?.sign,
                    amount: x.credit,
                    branch_name: x.checkout?.branch?.name
                }) )
                isSale
                    ? setSecondListCheckout(newCheckout)
                    : setFirstListCheckout(newCheckout)                
            }
            
            if(response.origin_operation_details?.length > 0){
                const newCheckout: ListCheckout[] = [];
                response.origin_operation_details.map((x)=> newCheckout.push({
                    name: x.checkout?.name || '',
                    id: x.checkout?.id ,
                    sign: x.checkout?.currency?.sign || '',
                    amount: x.debit,                    
                    branch_name: x.checkout?.branch?.name || ''

                }) )
                isSale
                    ?  setFirstListCheckout(newCheckout)
                    :  setSecondListCheckout(newCheckout)  
            }
                
            form_buy_and_sell.setFieldsValue(
                { 
                    operation_type: {
                        value: operationType, 
                        label:  isSale ? 'Venta' : 'Compra',
                    },
                    client: {
                        value: response.customer.first_name + ' ' + response.customer.last_name,
                        label: response.customer.first_name + ' ' + response.customer.last_name,
                        key: response.customer.id,
                    },
                    comission: response.usdt_quote_percentage || '', 
                    type_change: type_change,
                    amount: operationAmount,
                    description: response.description,
                    buy_currency: buy_currency,
                    sell_currency: sell_currency,
                    full_name: response.full_name
                }
            )   
            
            if(type_change){
                setTypeChange(Number(type_change))
                if(spanError){
                    setSpanError(undefined)
                }
            }
            if(operationAmount){
                setAmount(Number(operationAmount))
            }
        }            
        setCount(1)                      
    }  

    const handleCancel =()=>{
        setShowForms(Object.fromEntries(Object.keys(showForms).map(key => [key, false])))// pone todos en false
        setLoadingBtn(false)
        form_buy_and_sell.resetFields()
        setSelectedRowId(undefined)            
        setTransactionsData(undefined)
        setRowSelect(undefined)
    }

    const checkExchangeRate = async () => {
        if (!curriencies?.main_currency?.id || !curriencies?.secondary_currency?.id) return;    
        if (operationType !== 'buy' && operationType !== 'sell') return;
    
        const isBuy = operationType === 'buy';            
    
        if(curriencies.main_currency.id==='3' && curriencies.secondary_currency.id==='2'){//main = USDT y secondary = USD
            setTypeChange(undefined)    
            return
        } 
            
        const baseCurrency = curriencies.main_currency.id === '3' ? 2 : +curriencies.main_currency.id; //Si es compra venta usdt, el tipo de cambio se compara con el dolar en main
        const data = await getExchangeRate(baseCurrency, +curriencies.secondary_currency.id);
    
        if (!data) {
            form_buy_and_sell.setFieldValue('type_change', undefined);
            setTypeChange(undefined);
            setSpanError('No hay tipo de cambio, cree uno o ingreselo en forma manual');
            return;
        }
    
        const price = isBuy ? data.buy_price : data.sell_price;
    
        form_buy_and_sell.setFieldValue('type_change', price);
        setTypeChange(price);
        if(spanError){
            setSpanError(undefined)
        }
    };

    const clearState = () =>{
        setCurrencies(undefined)
        setBalance(undefined)
        form_buy_and_sell.resetFields([
            'client', 
            'buy_currency', 
            'deposit_cash', 
            'sell_currency', 
            'extraction_cash', 
            'type_change', 
            'aproved', 
            'amount', 
            'description'
        ]);
    }
    
    const confirmSave = async(operationData: OperationTypeProps) =>{
        let response
        selectedRowId
            ? response = await editOperation(handleCancel, selectedRowId.id!, operationData)
            : response = await createOperation(handleCancel, operationData);
        if(response == 'error'){
            setLoadingBtn(false)
        }else{
            setCollapsedFilters(!collapsedFilsters)  
        }                       
    }   
      
    const handleSave = async (values: ValuesForm)=>{
        if(formCheks.isWindow && (firstListCheckout.length == 0 || secondListCheckout.length == 0) ){ 
            if(firstListCheckout.length == 0 || secondListCheckout.length == 0) {          
                setSpanError('Debe seleccionar al menos una caja de depósito y una de extracción')            
                return
            }
        }  
        // setLoadingBtn(true)       
        const firstList = (action: string)=> firstListCheckout.map(({ id, amount }) => ({
            checkout_id: id,
            [action]: amount
        }));        

        const secondList =  (action: string)=> secondListCheckout.map(({ id, amount }) => ({
            checkout_id: id,
            [action]: amount
        })); 

        let origin_checkout = operationType === 'buy' ? secondList('debit') : firstList('debit') 
        let destination_checkout = operationType === 'buy' ? firstList('credit')  : secondList('credit')
        let validate_totals: string | undefined = validateTotals(formCheks.isWindow)
        if(validate_totals === 'doseNotMatch'){
            return
        }

        if(curriencies?.main_currency?.id && curriencies?.secondary_currency?.id && userData){
            let operationData={                
                type_id: isUSDT ? 9 : 7 ,           
                main_currency_id: Number(curriencies.main_currency.id),
                secondary_currency_id:  Number(curriencies.secondary_currency.id), 
                user_id: userData.id,
                debit: Number(values.amount),
                currency_quote_sell_price: values.type_change ? Number(values.type_change) : undefined,
                currency_sale:  !isUSDT ? (operationType === 'sell') : undefined,
                approved: aproved,
                customer_id: values.client?.key ? Number(values.client.key) : undefined,
                currency_quote_buy_price: values.type_change ? Number(values.type_change) : undefined ,//No está más?   
                origin_operation_details: origin_checkout ? origin_checkout : undefined,
                destination_operation_details: destination_checkout ? destination_checkout : undefined,  
                windows_operation: formCheks.isWindow,
                full_name: values.full_name || undefined,
                description: values.description || undefined,
                usdt_sale: isUSDT ? operationType === 'sell' : undefined,
                usdt_quote_percentage: Number(values.comission) || undefined
            } 
                       
            Modal.confirm({
                title: 'Confirmar operación',
                okText: 'Confirmar',
                cancelText: 'Cancelar',
                onOk:()=>confirmSave(operationData),
                content: (
                    <div>
                        {operationData.full_name && <p><strong>Ventanilla: </strong> {operationData.full_name}</p>}
                        {operationData.customer_id && <p><strong>Cliente: </strong> {values.client.label}</p>}
                        <p><strong>{operationType === 'sell' ? 'Venta' : 'Compra'}: </strong>{curriencies.main_currency?.sign} {formatAmount(Number(values.amount))}</p>
                        <p><strong>{operationType === 'sell' ? 'Compra' : 'Venta'}: </strong>{curriencies.secondary_currency.sign} {formatAmount(values.result)}</p>     
                        {operationData.usdt_quote_percentage && <p><strong>% de cambio: </strong> {operationData.usdt_quote_percentage} %</p>}     
                        {(operationData.currency_quote_buy_price ?? operationData.currency_quote_sell_price) && (
                            <p>
                                <strong>Tipo de cambio:</strong>{" "}
                                {curriencies.secondary_currency?.sign + " " + (operationData.currency_quote_buy_price ?? operationData.currency_quote_sell_price)}
                            </p>
                        )}  
                        <p>
                            <strong>Estado: </strong>
                            <strong style={{ color: operationData.approved ? 'green' : 'orange' }}>
                                {operationData.approved ? 'Aprobado' : 'Sin aprobar'}
                            </strong>
                        </p>
                        <Row
                            style={{
                                borderTop: '1px solid gray',
                                padding: '8px 0px',
                            }}
                        >
                            <Col 
                                span={12} 
                                style={{ 
                                    flexDirection:'column',
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    //alignItems:'start'
                                }}
                            >
                                {renderCheckoutSection(operationType === 'sell' ? 'Cajas de extracción' : 'Cajas de depósito', firstListCheckout)}
                            </Col>
                            <Col 
                                span={12} 
                                style={{ 
                                    flexDirection:'column',
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    //alignItems:'start'
                                    
                                }}>
                                {renderCheckoutSection(operationType === 'sell' ? 'Cajas de depósito' : 'Cajas de extracción', secondListCheckout)}
                            </Col>
                        </Row>     
                        <Row
                            style={{
                                borderTop: '1px solid gray'
                            }}
                        >
                            <Col  span={12}>
                                <p style={{margin: 5}}><strong>Total: </strong>{firstListCheckout?.[0]?.sign}  {formatAmount(firstListCheckout?.reduce((sum, { amount }) => sum + Number(amount), 0))}</p>
                            </Col>
                            <Col span={12}>
                                <p style={{margin: 5}}><strong>Total: </strong>{secondListCheckout?.[0]?.sign}  {formatAmount(secondListCheckout?.reduce((sum, { amount }) => sum + Number(amount), 0))}</p>
                            </Col>
                        </Row>      
                        { validate_totals && <p><strong style={{color: 'orange'}}>Advertencia: </strong>{validate_totals}</p> }                                                                                           
                    </div>
                ),
            });
        } 
    }  

    const formatAmount = (value: number) => 
        Number(value).toLocaleString("es-AR", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    
    const renderCheckoutSection = (title: string, list: typeof firstListCheckout) => {
        return  (
            <>
                <p style={{ margin: 3}}><strong>{title}:</strong></p>
                {list.length > 0 && list?.map(({ name, sign, amount }, index) => (
                    <p style={{fontSize: 12, margin: 3}} key={index}><strong>{name}: </strong> {sign} {formatAmount(Number(amount))}</p>
                ))}               
            </>
        );
    }



    const validateTotals = (window: boolean) =>{
        const totalMain = firstListCheckout.reduce((sum, x) => sum + Number(x.amount), 0);
        const totalSecondary = secondListCheckout.reduce((sum, x) => sum + Number(x.amount), 0);

        const errorMessages = [];

        if (amount && totalMain !== Number(amount.toFixed(2))) {
            if(window){
                notification.error({
                    message: `El monto total de ${operationType === 'sell' ? 'venta' : 'compra'} debe coincidir con el monto total de ${operationType === 'sell' ? 'extracción' : 'depósito'}`
                })
                return 'doseNotMatch'
            }
            errorMessages.push(`El monto total de ${operationType === 'sell' ? 'venta' : 'compra'} no coincide con el monto total de ${operationType === 'sell' ? 'extracción' : 'depósito'}`);                       
        }

        if (result && totalSecondary !== Number(result?.toFixed(2))) {
            if(window){
                notification.error({
                    message: `El monto total de ${operationType === 'sell' ? 'compra' : 'venta'} debe coincidir con el monto total de ${operationType === 'sell' ? 'depósito' : 'extracción'}`
                })
                return 'doseNotMatch'
            }
            errorMessages.push(`El monto total de ${operationType === 'sell' ? 'compra' : 'venta'} no coincide con el monto total de ${operationType === 'sell' ? 'depósito' : 'extracción'}`);
        }

        return errorMessages.length > 0 ? errorMessages.join('. ') : undefined;                            
    }


    
    const handleOperationType = (event:{value:'sell' | 'buy' } ) =>{
        if(event){
            setOperationType(event.value)
        }
        clearState()  
        setAproved(false)     
    }   

    const handleChangeClients = (values: {
        label: string,
        value: string,
        key: string
    }) =>{
        setCustomerSelected({
            id: values.key,
            name: values.label
        })
    }

    const onChangeAproved: CheckboxProps['onChange'] = async (e) => {
        setAproved(e.target.checked); 
    };

    const onChangeWindow: CheckboxProps['onChange'] = async (e) => {
        if(e.target.checked){//SI ES VENTANILLA, ES OBLIGATORIO CARGAR CAJAS
            setFormChecks({
                deposit: true,
                extraction: true,
                isWindow: true
            })
        }else{
            setFormChecks({
                deposit: false,
                extraction: false,
                isWindow: false
            })
        }
        clearInputs()
    };

    const clearInputs = ()=>{
        setBalance(undefined)
        setCustomerSelected(undefined)
        form_buy_and_sell.setFieldValue('client', undefined)
        form_buy_and_sell.setFieldValue('full_name', undefined)
    }
    
    return(         
        <Modal
            width={formCheks.deposit || formCheks.extraction ? 1100: 900}
            style={{ top: 10 }}
            title= {
                <ModalTitle 
                    title={'Compra / Venta: Moneda'} 
                    isApproved={transactionsData && transactionsData.approved ? true : false}
                    isDeleted={transactionsData && transactionsData.deleted ? true : false}
                    operationNumber={transactionsData ? transactionsData.operation_id: undefined}
                />
            }
            footer={transactionsData?.approved ? null : undefined} 
            open={showForms.buy_and_sell }
            onCancel={handleCancel}  
            confirmLoading={loadingBtn}                   
            okText="Guardar"
            cancelText="Cancelar" 
            onOk={()=>form_buy_and_sell.submit()}                
        >
            <NewCustomer justify="end" positions="leftTop"/>
            <Form
                form={form_buy_and_sell}
                id='form_buy_and_sell'
                onFinish={handleSave}
                layout="vertical"                    
            >      
                <Row
                    gutter={[16, 16]}
                    style={{margin: '0px 0px 5px', padding: 0}}
                >
                    <Col span={5} style={{padding: 0, margin: 0}}>
                        <Form.Item
                            label='Tipo de operación'
                            name='operation_type'
                            rules={mandatory}
                            style={{marginBottom: 5}}
                        >
                            <Select         
                                style={{width: '100%'}}                  
                                labelInValue  
                                placeholder='Seleccionar'   
                                disabled={transactionsData?.approved}
                                onChange={(event)=> handleOperationType(event)}          
                                options={[
                                    { value: 'sell', label: 'Venta' },
                                    { value: 'buy', label: 'Compra' },
                                ]}                   
                            />
                        </Form.Item> 
                    </Col>
                    <Col span={7}>
                    { formCheks.isWindow
                        ?  <Form.Item
                            label={formCheks.isWindow ? 'Nombre y apellido' : 'Cliente'}
                            name='full_name'
                            rules={mandatory} 
                            style={{marginBottom: 5}}
                        > 
                            <Input
                                style={{width: '95%'}} 
                                placeholder="Nombre y apellido"
                                disabled={transactionsData?.approved}
                            />
                        </Form.Item> 
                        : <Form.Item
                                label={'Cliente'}
                                name='client'
                                rules={mandatory} 
                                style={{marginBottom: 5}}
                            >
                                <Select 
                                        showSearch                         
                                        labelInValue  
                                        loading={!clients }
                                        placeholder={!clients ? 'Cargando campos...' : 'Seleccionar'}      
                                        style={{width: '95%'}}  
                                        onChange={handleChangeClients}       
                                        disabled={transactionsData?.approved}           
                                    >
                                        {clients && clients.map((item) => (
                                            <Option value={item.first_name + ' ' + item.last_name} key={item.id} >
                                                {item.first_name + ' ' + item.last_name}
                                            </Option>
                                        ))} 
                                    </Select>                                                              
                            </Form.Item>    
                    }
                    </Col>
                    <Col 
                        span={9}                                    
                    >
                        { !transactionsData && !formCheks.isWindow &&
                            <Row 
                                style={{
                                    display: 'flex',
                                    paddingBottom: 10, 
                                    marginTop: 35                                  
                                }}
                            >
                                <span style={{padding: '0px', fontWeight: '500', marginRight: '10px'}}>SALDO DE CTA CLIENTE:</span>
                                <span style={{padding: '0px', fontWeight: '500', color: balance?.balance && balance.balance >= 0 ? 'green' : 'red'}}>
                                    {balance?.currency_id && allCurrency.find(x => x.id === balance.currency_id)?.sign || ''} {' '}
                                    {balance? new Intl.NumberFormat('es-AR', { style: 'decimal', minimumFractionDigits: 2 }).format(balance.balance) : ''}
                                </span>
                            </Row> 
                        }                              
                    </Col>
                    <Col 
                        span={3}
                        
                    >                          
                        <Checkbox
                            style={{marginTop: 35}}
                            disabled={transactionsData?.approved}
                            checked={formCheks.isWindow}
                            onChange={onChangeWindow}                               
                        >
                            Ventanilla
                        </Checkbox>
                    </Col>
                </Row>                                      
                {
                    operationType && 
                    <>                                                      
                       <Row gutter={[16, 16]} style={{ width: '100%' }}>
                        {[
                           {
                                is_main: true,
                                setListCheckout: setFirstListCheckout,
                                ListCheckout: firstListCheckout,
                                operationType: operationType === 'buy' ? ('Moneda compra' as const) : ('Moneda venta' as const),
                                checked: operationType === 'buy' ? formCheks.deposit : formCheks.extraction,
                            },
                            {
                                is_main: false,
                                setListCheckout: setSecondListCheckout,
                                ListCheckout: secondListCheckout,
                                operationType: operationType === 'buy' ? ('Moneda venta' as const) : ('Moneda compra' as const),
                                checked: operationType === 'buy' ? formCheks.extraction : formCheks.deposit,
                            },
                        ].map(({ is_main, setListCheckout, ListCheckout, operationType, checked }, index) => (
                            <Col key={index} span={12}>
                            <FormItems
                                setCurrencies={setCurrencies}
                                formCheks={formCheks}
                                setFormChecks={setFormChecks}
                                operationType={operationType}
                                checked={checked}
                                is_main={is_main}
                                transactionsData={transactionsData}
                                setListCheckout={setListCheckout}
                                ListCheckout={ListCheckout}
                                form_buy_and_sell={form_buy_and_sell}
                            />
                            </Col>
                        ))}
                        </Row>                                                                               
                        {
                            isUSDT &&
                            <Form.Item
                                label= '% de cotización'         
                                name = 'comission'
                                className="sell_and_buy_divide" 
                                rules={mandatory} 
                            >
                                <InputNumber
                                    disabled={transactionsData?.approved}
                                    addonBefore={'%'}
                                    onChange={(e)=>setComission(Number(e))}
                                />
                            </Form.Item>
                        }  
                        {
                            isUSDT && (curriencies?.secondary_currency?.id==='2' )
                            ? <></>
                            : <Form.Item
                                label= 'Tipo de cambio'         
                                name= 'type_change'
                                className="sell_and_buy_divide"  
                                rules={mandatory}
                            >
                                <InputNumber
                                    disabled={transactionsData?.approved} 
                                    addonBefore={curriencies?.secondary_currency?.sign || ' ¤ '}
                                    onChange={(e)=>{
                                        if(e){setSpanError(undefined);}
                                        setTypeChange(Number(e))}}
                                />
                            </Form.Item>
                        }                                              
                        <Row
                            style={{
                                width: '100%',
                                display: 'flex',
                                gap: 4,
                                marginBottom: 15
                            }}
                        >
                            <Col style={{ flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <Form.Item
                                    label= {operationType==='buy' ? 'Monto de compra' : 'Monto de venta'}         
                                    name= 'amount'
                                    rules={mandatory}                                        
                                    style={{marginBottom: 0}}
                                >
                                    {   
                                        invested 
                                        ? <>
                                            <span style={{marginRight: 10, fontWeight: 700}}>{curriencies?.main_currency?.sign || ' ¤ '}</span>
                                            <span style={{fontSize: 15, fontWeight: 700}}>
                                                {amount && Number(amount).toLocaleString("es-AR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                            </span>
                                        </>
                                        : <InputNumber
                                            disabled={transactionsData?.approved} 
                                            addonBefore={curriencies?.main_currency?.sign || ' ¤ '}
                                            onChange={(e)=>{setAmount(Number(e))}}       
                                            //parser={(value) => value ? value.replace(/,/g, '') : ''}      
                                            //formatter={(value) => (value ? value.toLocaleString('es-AR'):'')}    
                                            onKeyDown={(event) => {
                                                if (event.key === ',') {
                                                    event.preventDefault(); // Evita que la coma aparezca en el input
                                                }
                                            }}                 
                                        /> 
                                    }                   
                                </Form.Item>
                                <Button
                                    style={{marginRight: 15, marginTop: 30}}
                                    disabled={transactionsData?.approved}
                                    onClick={()=>{
                                        setInvested(!invested)
                                    }}
                                >
                                    <SwapOutlined />
                                </Button>                            
                            </Col>
                            <Col style={{ flex: 1, display:'flex', flexDirection: 'row' }}>
                                <Form.Item
                                    label= 'Resultado'         
                                    name= 'result'
                                    style={{marginLeft: 5, marginBottom: '0px'}}
                                >
                                    {
                                        invested 
                                        ?  <InputNumber
                                                addonBefore={curriencies?.secondary_currency?.sign || ' ¤ '}
                                                disabled={transactionsData?.approved}
                                                //value={result}
                                                onChange={(e)=>{setResult(Number(e))}}
                                                onBlur={calculateAmount}
                                            />
                                        : <>
                                            <span style={{marginRight: 10, fontWeight: 700}}>{curriencies?.secondary_currency?.sign}</span>
                                            <span style={{fontSize: 15, fontWeight: 700}}>
                                                {result && Number(result).toLocaleString("es-AR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                            </span>
                                        </>
                                    }                                                                                                                      
                                </Form.Item>
                            </Col>
                        </Row>                            
                    </>                                          
                }  
                <Checkbox
                    disabled={transactionsData?.approved}
                    checked={aproved}
                    onChange={onChangeAproved}
                    style={{marginBottom:10}}
                >
                    Aprobado
                </Checkbox>                   
                    <Form.Item
                    label='Descripción'
                    name='description'
                >
                    <Input
                        disabled={transactionsData?.approved}
                        placeholder="Comentario"
                    />
                </Form.Item>                                  
            </Form>
            <span style={{color: 'red'}}>{spanError && spanError}</span>
        </Modal>
       
    )
}

export default PucrchaseSaleCurrencyForm;