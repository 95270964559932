import React, { useEffect, useState } from "react";
import { Button, Checkbox, Form, Input, InputNumber, Popover, Row, Select } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useForm } from "antd/es/form/Form";
import { useCustomers } from "../../Services/useCustomers";

export interface FilterValues {
  value: string;
  id: string;
  key: string;
}

export interface customerValuesForm {
  first_name: string;
  last_name: string;
  phone_number: number;
  email: string;
  is_supplier: string;
  is_customer: string;
}

interface NewCustomerProps {
    positions: 'rightTop' | 'leftTop',
    justify: 'end' | 'start'
}

export const NewCustomer: React.FC<NewCustomerProps> = ({
  positions,
  justify
}) => {
  const [loadingBtn, setLoadingBtn] = useState(false);
  const { createCustomers } = useCustomers();
  const [isSupplier, setIsSupplier] = useState(false);
  const [isCustomer, setIsCustomer] = useState(true);
  const { Option } = Select;
  const [form_customer] = useForm();
  const [visible, setVisible] = useState(false); // Controla la visibilidad del Popover


  const clearform = () =>{
    form_customer.resetFields();
    setIsCustomer(true);
    setIsSupplier(false);
  }

  const handleCancel = () => {
    setVisible(false); // Cierra el Popover
   // callback();
  };

  const handleSave = async (values: customerValuesForm) => {
    const newValues = {
      first_name: values.first_name,
      last_name: values.last_name,
      phone_number: values?.phone_number?.toString() || '',
      email: values?.email || '',
      is_supplier: isSupplier,
      is_customer: isCustomer,
    };

    await createCustomers(handleCancel, newValues);
  };

  const content = (
    <Form
      form={form_customer}
      id="form_customer"
      onFinish={handleSave}
      layout="vertical"
    >
      <Form.Item
        label="Nombre"
        name="first_name"
        rules={[
          {
            required: true,
            message: 'Este campo es obligatorio',
          },
        ]}
        style={{marginBottom: 2}}
      >
        <Input placeholder="Nombre" />
      </Form.Item>

      <Form.Item
        label="Apellido"
        name="last_name"
        rules={[
          {
            required: true,
            message: 'Este campo es obligatorio',
          },
        ]}
        style={{marginBottom: 2}}
      >
        <Input placeholder="Apellido" />
      </Form.Item>

      <Form.Item label="Celular" name="phone_number" style={{marginBottom: 2}}>
        <InputNumber style={{ width: '100%' }} placeholder="3412326088" />
      </Form.Item>

      <Form.Item label="Email" name="email" style={{marginBottom: 2}}>
        <Input type="e-mail" placeholder="e-mail" />
      </Form.Item>

      <Form.Item name="is_supplier" style={{marginBottom: 2}}>
        <Checkbox checked={isSupplier} onChange={() => setIsSupplier(!isSupplier)}>
          Es Proveedor
        </Checkbox>
      </Form.Item>

      <Form.Item name="is_customer" style={{marginBottom: 2}}>
        <Checkbox checked={isCustomer} onChange={() => setIsCustomer(!isCustomer)}>
          Es Cliente
        </Checkbox>
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loadingBtn} style={{ float: 'right' }}>
          Guardar
        </Button>
      </Form.Item>
    </Form>
  );

  return (
    <Row
        justify={justify}
        style={{marginBottom: 10}}
    >
      <Popover
        content={content}
        title="Crear Nuevo Cliente"
        trigger="click" // Se activa al hacer clic
        visible={visible}
        onVisibleChange={setVisible} // Controla la visibilidad del Popover
        placement={ `${positions}` }// "rightTop" 
        destroyTooltipOnHide={true} // Elimina el Popover cuando se cierra        
      >
        <Button 
            onClick={clearform}
            icon={<PlusOutlined />} >
          <span style={{ marginLeft: 10 }}>Nuevo Cliente</span>
        </Button>
      </Popover>
    </Row>
  );
};

export default NewCustomer;