import React, { useContext, useState } from "react"
import {
    CheckOutlined,
  } from '@ant-design/icons';
import { useOperations } from "../../Services/useOperations";
import './styles.scss'
import { DataType } from "../../Contexts/UserContext/MovementAcountContext";
import { BalancesContext } from "../../Contexts/UserContext/BalancesContext";
import { OperationsContext } from "../../Contexts/UserContext/OperationsContext";
import {  message, Popconfirm, Popover } from "antd";
import RenderConfirm from "../DetailsPopup/DetailsPopup";


export const RenderApproved: React.FC<{
  text: string, 
  record: DataType, 
  setDataTable: Function,
  dataTable: DataType[]
  callback: Function
}> = ({
    text, 
    record, 
    setDataTable, 
    dataTable, 
    callback
  }) =>{

  const {confirmOperation} = useOperations()
  const { setMutateBalance } = useContext(BalancesContext) 
  const { setMutate: setMutateOperations } = useContext(OperationsContext)

  const [loadingApproved, setLoadingApproved] = useState(false)

  const handleApproved = async ()=>{ 
      setLoadingApproved(true)
      const status = await confirmOperation(+record.id!, setLoadingApproved)
      if(status===200 ){
        callback(false)
        setMutateBalance(true)
        setMutateOperations(true)
      }else{
      message.open({
        type: 'error',
        content: 'No fue posible aprobar la operación',
      })
    }   
  }

  const handleStopPropagation = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  return(
    <>
      {
        text==='true'
        ?
          <Popover
            content={ record.is_deleting_operation === 'true' ? undefined : <RenderConfirm record={record} isGetMain={false} operation={'Transacción'}/>}  
            trigger="click"
          >
            <CheckOutlined 
              style={{
                color: (record.deleted==='true' || record.is_deleting_operation==='true')? '#808080ab' : '#30cb85', 
                paddingLeft: '10px'
              }} />
            { record.is_deleting_operation === 'false' && <span 
              className="detail_hover"
              style={{
                color: (record.deleted==='true')? '#808080ab' : '#30cb85', 
                paddingLeft: '10px'
              }} 
            >
              Detalle
            </span>}
          </Popover>        
        : (
            (record.full_name && record.operation_type_id !== '7' && record.operation_type_id !== '9')  || 
            record.related_operation_id  ||//depósito o extracción vinculada a una compra venta usdt
            record.operation_type_id==='8' )
            ? <p className="pending">Pendiente</p>
            : 
            <Popconfirm
              placement="top"
              title='¿Confirma operación?'
              okText="Si"
              cancelText="Cancelar"
              onConfirm={(e)=>{
                handleApproved()
                if(e){
                  handleStopPropagation(e)//evita que el onclick ejecute funciones del componente padre por ser un componente que está superpuesto
                }                            
              }} 
              onCancel={(e)=>{
                if(e){
                  handleStopPropagation(e)//evita que el onclick ejecute funciones del componente padre por ser un componente que está superpuesto
                }
              }}  
              description={ record.is_deleting_operation === 'true' ? undefined : <RenderConfirm record={record} isGetMain={false} operation={'Transacción'}/>}       
            >
              <p  
                className={loadingApproved ? "loading_btn":"approve_btn"}
                //style={{color: loadingApproved ? 'grey' :'orange ', padding: '0px', margin: '0px', }}
              >
                { loadingApproved ? 'cargando...' : 'Aprobar' }
              </p> 
            </Popconfirm>           
      }    
    </>
  )
  }