import { Routes , Route, Navigate, } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import { UserContext } from '../../Contexts/UserContext/UsersContext'; 
import { PublicRoutes } from '../PublicRoutes';
import { PrivateRoutes } from '../PrivateRoutes';


const MainRoutes = () => {
 const {accesToken } = useContext(UserContext);


  return (
      <Routes>
            {
                accesToken && accesToken !== 'undefined'
                    ? ( <Route path="/*" element={<PrivateRoutes />} />)
                    : ( <Route path="/*" element={<PublicRoutes />} />)
            }
            <Route path='*' element={<Navigate to='/login' replace />} />       
        </Routes> 
   
  );
}

export default MainRoutes;