import React, { useContext } from "react";
import './styles.scss'
import { BodyContentProps } from "./types";
import { Route, Routes, Navigate } from "react-router";
import Home from "../Model";
import UserSection from "../Sections/UsersSection";
import CurrencySection from "../Sections/CurrencySection";
import BranchSection from "../Sections/BranchSection";
import CashDeskSection from "../Sections/CashDeskSection";
import MovementsCash from "../Sections/MovementsCash";
import ExchangeRateSection from "../Sections/ExchangeRate";
import BalanceSections from "../Sections/BalanceSections";
import OperationSections from "../Sections/OperationSections";
import { UserContext } from "../../Contexts/UserContext/UsersContext";
import MovementsAccount from "../Sections/MovementsAcount";
import CustomerSections from "../Sections/CustomerSections";
import ChangePassword from "../Sections/ChangePassword";
import ProfileSections from "../Sections/ProfileSection";
import OperatorCashSection from "../Sections/OperatorCashSection";
import { AllCheckout } from "../Sections/AllCheckouts";
import SummarySection from "../Sections/SummarySection";
import BalanceGeneral from "../Sections/BalanceGeneral";
import { InitializationSection } from "../Sections/InitializationSection";

export const BodyContent: React.FC<BodyContentProps> = ()=>{
    const {collapsed, initializationMode} = useContext(UserContext)

    return(
        <div className="bodyContent_container" 
            style={{  
                width: collapsed ? 'calc(100% - 112px)' :'calc(100% - 296px)',                
            }}
        >
             <Routes>
                {initializationMode ? (
                    <>
                        <Route path="initialization" element={<InitializationSection />} />
                        <Route path="user" element={<UserSection />} />
                        <Route path="currency" element={<CurrencySection />} />
                        <Route path="branch" element={<BranchSection />} />
                        <Route path="checkout" element={<CashDeskSection />} />
                        {/* <Route path="account_movements" element={<MovementsAccount />} /> */}
                        {/* <Route path="cash_movements" element={<MovementsCash />} /> */}
                        <Route path="exchange_rate" element={<ExchangeRateSection />} />
                        <Route path="balance_sheets" element={<BalanceSections />} />
                        <Route path="operations" element={<SummarySection />} />
                        <Route path="customers" element={<CustomerSections />} />
                        <Route path="change_password" element={<ChangePassword />} />
                        <Route path="profile" element={<ProfileSections />} />
                        <Route path="operation_cash_sections" element={<OperatorCashSection />} />
                        {/* <Route path="all_checkout" element={<AllCheckout />} /> */}
                        {/* <Route path="transactions" element={<OperationSections />} /> */}
                        <Route path="general_balance" element={<BalanceGeneral />} />
                        <Route path="*" element={<Navigate to="/dashboard" replace />} />
                    </>
                ) : (
                    <>
                        <Route path="user" element={<UserSection />} />
                        <Route path="currency" element={<CurrencySection />} />
                        <Route path="branch" element={<BranchSection />} />
                        <Route path="checkout" element={<CashDeskSection />} />
                        <Route path="account_movements" element={<MovementsAccount />} />
                        <Route path="cash_movements" element={<MovementsCash />} />
                        <Route path="exchange_rate" element={<ExchangeRateSection />} />
                        <Route path="balance_sheets" element={<BalanceSections />} />
                        <Route path="operations" element={<SummarySection />} />
                        <Route path="customers" element={<CustomerSections />} />
                        <Route path="change_password" element={<ChangePassword />} />
                        <Route path="profile" element={<ProfileSections />} />
                        <Route path="operation_cash_sections" element={<OperatorCashSection />} />
                        <Route path="all_checkout" element={<AllCheckout />} />
                        <Route path="transactions" element={<OperationSections />} />
                        <Route path="general_balance" element={<BalanceGeneral />} />
                        <Route path="*" element={<Navigate to="/dashboard" replace />} />
                    </>
                )}
            </Routes>
        </div>
        
    )
}

export default BodyContent

