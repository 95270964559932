import { useContext } from 'react';
import { axiosBackEndInstance } from '../Axios';
import { END_POINT_FEATURE_FLAGS } from '../Axios/ENDPOINTS';
import { useErrorLoguin } from '../hooks/useErrorLoguin';
import { UserContext } from '../Contexts/UserContext/UsersContext';
import { useNavigate } from 'react-router';

export interface FeatureFlagsProps{
  initialization_mode: boolean
}


export const useFeatureFlags= () => {

    const {errorHandling} = useErrorLoguin()
    const {setInitializationMode} = useContext(UserContext)
    const navigate = useNavigate();
     
    const initialize = async (      
      value: {initialization_mode: boolean }     
      ): Promise<string | undefined> => {      
    try {
      const  data = await axiosBackEndInstance.put(
        `${END_POINT_FEATURE_FLAGS}`, value
      )
      if(data.status == 200 || data.status == 201){
        setInitializationMode(data.data.initialization_mode)
        navigate('/dashboard');
        return 'ok'
      }        
    } catch (newError) {
      const status =  await errorHandling(newError).request.status      
      console.log('status ' + status + ' ' + newError);
      
    }
  };

  return {
    initialize
  };
};
