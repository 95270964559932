import React from "react";
import { DataType } from "../../../Contexts/UserContext/MovementAcountContext";
import { Row, Tooltip } from "antd";
import {
    CloseOutlined,
    UndoOutlined
  } from '@ant-design/icons';

export const RenderNumOperations = React.memo((value: {text: any, record: DataType})=>{
    return(
        <Row
            justify={
              value.record.deleted === 'true' || value.record.is_deleting_operation === 'true'
                ? 'space-between'
                : 'start'
            }
            style={{fontSize: 12}}
        >
            <span >{value.text}</span>            
            {value.record.deleted === 'true' && 
                <Tooltip
                    title='Eliminado'
                >
                    <CloseOutlined style={{color: 'red'}}/>
                </Tooltip>                
            }
             {value.record.is_deleting_operation === 'true' && 
                <Tooltip
                    title='Nota de crédito'
                >
                    <UndoOutlined style={{color: 'red'}}/>  
                </Tooltip>                
            }
        </Row>
        
    )
  })
  
  export const RenderAccountAmount = React.memo(
    ({ text, record, type }: { text: string; record: DataType; type: 'credit' | 'debit'}) => {
      if (text === '') return <span />;
  
      if ( record.destination_account_customer_id == '0' || record.origin_account_customer_id == '0'){
        return <span/>
      }

      let colorTxt = 'rgb(55, 189, 224)'
      if(record.deleted === 'true' || record.is_deleting_operation === 'true'){
          colorTxt = 'rgb(114, 114, 114)'
      }
  
      let sign = type === 'credit' ? record.destination_account_currency_sign || '' 
                : type === 'debit' ? record.origin_account_currency_sign || '' 
                : '';
  
      // Verificar si el número es negativo
      const isNegative = text.startsWith('-');
      
      // Convertir a positivo si es negativo
      const positiveText = isNegative ? text.slice(1) : text;
  
      return <span style={{color: colorTxt}}>{isNegative ? '-' : ''} {sign} {Number(positiveText).toLocaleString('es-AR', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</span>;
    }
  );
  
  export const RenderCustomerDestination = React.memo((value:{text: any, record: DataType})=>{ 
    let data =  value.record
    let customer = ''
    let showCustomer: boolean = true
  
    if(data.destination_account_customer_id && data.destination_account_customer_id !== '0' && showCustomer){
      customer = data.destination_account_customer_name + ' ' + data.destination_account_customer_last_name +' - ' + `[${data.destination_account_currency_sign}]`
    }
      return(
        <span>{customer}</span>
      )
    }
  )
  
  export const RenderCustomerOrigin = React.memo((value:{text: any, record: DataType})=>{ 
    let data =  value.record
    let customer = ''
    let showCustomer: boolean = true
  
   
    if(data.origin_account_customer_id && data.origin_account_customer_id !== '0' && showCustomer){
      customer = data.origin_account_customer_name + ' ' + data.origin_account_customer_last_name +' - ' + `[${data.origin_account_currency_sign}]`
    }
      return(
        <span>{customer}</span>
      )
    }
  )
  
  const formatted = (value: string)=>{
  
    const number = parseFloat(value);
  
    let data =  new Intl.NumberFormat('es-AR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);
  
    return  data
  }
  
  export const RenderAmount = React.memo(
    ({ text, record, type, data }: { text: string; record: DataType; type: 'credit' | 'debit'; data: 'destination' | 'origin' }) => {
      let sign = '';
      if((record.operation_type_id === '5' )){ // transferencia entre cajas uso la moneda de la caja
         sign = record[`${data}_checkout_currency_sign`] || ''
      }else{
        sign = record[`${data}_account_currency_sign`] || ''
      }
  
      switch(record.operation_type_id){
        case '8':
        case '12':
          if(record[`${data}_account_id`] == ''){
            text = ''
          }
          break
        case '13':
          if(record[`${type}`] === '0.00' ){
            text = ''
          }else{
            text = formatted(text)
            sign = record.origin_checkout_currency_sign || ''
          }
          break
        default:
          //a veces credito o débito puede venir en 0.00 para saber si lo muestro me fijo que el account o el destination tinenen datos
          if(type ==='credit' &&  ((record.destination_account_id && record.destination_account_id !== '0') || record.destination_checkout_id)){
            text = formatted(text)
          }else if(type === 'debit' && ((record.origin_account_id && record.origin_account_id !== '0') || record.origin_checkout_id)){
            text = formatted(text)
          }else{
            text = ''
          }        
      }
     
      return <span>
        {type === 'debit' && sign && '-'} 
        {sign}{' '}{text}</span>;
    }
  );
  
  export const RenderCheckout = React.memo((value:{text: string, record: DataType, })=>{
    let txt =''
    if(value.record.operation_type_id === '7' && value.record.windows_operation === 'true'){
      txt = value.text
    }
    if(value.record.operation_type_id !== '9' && value.record.operation_type_id !== '7'){
      txt = value.text
    }
    return(<div>{txt} </div>)
  }) 
  
  export const RenderBalance = React.memo(({text, record, type}:{text: string, record: DataType, type: 'destination' | 'origin'})=>{
    let txt =''
    let sign = record?.[`${type}_checkout_currency_sign`] || '';
  
    if(record.operation_type_id === '7' && record.windows_operation === 'true'){
      txt = ''
    }
    if(record.operation_type_id !== '9' && record.operation_type_id !== '7'){
      txt = text
    }

    let colorTxt = 'rgb(55, 189, 224)'

    if(record.deleted === 'true' || record.is_deleting_operation === 'true'){
        colorTxt = 'rgb(114, 114, 114)'
    }

    
  
    return(<div style={{color: colorTxt}}>    
      {txt !== '' 
        ? sign + ' ' + formatted(txt) 
        : txt} 
      </div>)
  }) 
  