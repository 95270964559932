import React, { useContext, useEffect, useState } from "react";
import './styles.scss';
import { Checkbox, CheckboxProps, Col, Form, Input, InputNumber, Modal, Row, Select } from "antd";
import { TransfersBetweenBoxesProps } from "./types";
import { useGetCashDesk } from "../../../Services/useGetCashDesk";
import { CheckoutBalance, useCashDesk } from "../../../Services/useCashDesk";
import { OperationTypeProps, useOperations } from "../../../Services/useOperations";
import { VariablesContext } from "../../../Contexts/UserContext/VariablesContext";
import { UserContext } from "../../../Contexts/UserContext/UsersContext";
import { useGetBranch } from "../../../Services/useGetBranch";
import { Transaction } from "../../../Services/useGetMovements";
import ModalTitle from "../../ModalTitle";
import { OperationsContext } from "../../../Contexts/UserContext/OperationsContext";

export interface FilterValues{
    value: string,
    label: string,
    key: number,
}

export interface CashValuesForm {
    origin_checkout: FilterValues,
    destination_checkout: FilterValues,
    amount_debit: number,
    amount_credit: number,
    description: string
}

interface currencySelected{
    id: number, 
    name: string,
    currency:{id: number, sign: string}
}

export const TransfersBetweenBoxes: React.FC<TransfersBetweenBoxesProps> =({
    form,  
})=>{
    const {data: cashs} = useGetCashDesk()
    const {userData} = useContext(UserContext)
    const { Option } = Select;
    const mandatory = [
        {
            required: true,
            message:'Este campo es obligatorio'
        }
    ]
    const {getCheckoutBalance} = useCashDesk()
    const {createOperation, editOperation, getOperation} = useOperations()
    const {collapsedFilsters, setCollapsedFilters, setShowForms, showForms, setSelectedRowId, selectedRowId} = useContext(VariablesContext)
    const {setRowSelect} = useContext(OperationsContext)
    const {data: branches } = useGetBranch()

    const [spanError, setSpanError] = useState<string>()
    const [cash, setCash] = useState<currencySelected | undefined>()
    const [secondCheckout, setSecondCheckout] = useState<currencySelected | undefined>()
    const [balance, setBalance] = useState<CheckoutBalance>()  
    const [approved, setaproved] = useState(false)    
    const [loadingBtn, setLoadingBtn] = useState(false)
    const [disabledBtn, setDisabledBtn] = useState(false)
    const [originBranch, setOriginBranch] = useState<number>()
    const [destinationBranch, setDestinationBranch] = useState<number>()
    const [transactionsData, setTransactionsData] = useState<Transaction>()

    useEffect(()=>{  
        if(selectedRowId){
            updateForm(selectedRowId)
        }else{
            form.resetFields()
        }      
    },[])

    const updateForm = async(data:{id: string}) =>{
        const response = await getOperation(+data.id)
        if(response){
            setTransactionsData(response)
            setaproved(response.approved)  
            setDestinationBranch(response?.destination_checkout.branch.id)
            setOriginBranch(response?.origin_checkout.branch.id) 
            setCash({ 
                id: response.origin_checkout.id, 
                name: response.origin_checkout.name,
                currency:{
                    id: response.origin_checkout.currency.id, 
                    sign: response.origin_checkout.currency.sign
                }}
            )
            setSecondCheckout({ 
                id: response.destination_checkout.id, 
                name: response.destination_checkout.name,
                currency:{
                    id: response.destination_checkout.currency.id, 
                    sign: response.destination_checkout.currency.sign
                }}
            )
            form.setFieldsValue({ 
                branch_origin:{
                    value: response.origin_checkout?.branch?.name || '',
                    label: response.origin_checkout?.branch?.name || '',
                    key: response.origin_checkout?.branch?.id! || '',
                },
                origin_checkout:{
                    value: response.origin_checkout?.name || '',
                    label: response.origin_checkout?.name || '',
                    key: response.origin_checkout?.id! || '',
                },                     
                amount_debit: response.debit, 
                branch_destination:{
                    value: response.destination_checkout?.branch?.name || '',
                    label: response.destination_checkout?.branch?.name || '',
                    key: response.destination_checkout?.branch?.id || '',
                },
                destination_checkout: {
                    value: response.destination_checkout?.name || '',
                    label: response.destination_checkout?.name || '',
                    key: response.destination_checkout?.id! || '',
                },                     
                amount_credit: response.credit || '',
                description: response.description || ''
            });    
        }
    }

    const handleCancel =()=>{
        setShowForms(Object.fromEntries(Object.keys(showForms).map(key => [key, false])))// pone todos en false
        if(selectedRowId){
            setSelectedRowId(undefined)
        }
        setTransactionsData(undefined)
        setaproved(false)
        setRowSelect(undefined)
    }

    const confirmSave = async(operationData: OperationTypeProps) =>{
        let newData
        if(selectedRowId){  
            newData = await editOperation(handleCancel, selectedRowId.id!,  operationData)
        
        }else{
            newData = await createOperation(handleCancel, operationData)
        }
        if(newData){
            //chequeo si la tabla está cargada y si la operacion creada coincide con los datos un tabla para agregarlo
            // if (showingCash && ( showingCash.id===newData.destination_checkout.id || showingCash.id===newData.origin_checkout.id)){
            //    setMutate(true)
            // }
            // mutateOperationsSections(true)
        }           
        setCollapsedFilters(!collapsedFilsters) 
    }

    const handleSave = async (e: CashValuesForm)=>{
        let operationData: OperationTypeProps | undefined
        if(userData){
            operationData = {
                type_id: 5, //correspondiente a Transferencia entre cajas, por eso va harcord
                origin_checkout_id: e.origin_checkout.key,
                destination_checkout_id: e.destination_checkout.key,
                credit: e.amount_credit,
                debit: e.amount_debit,
                user_id: userData.id,
                approved: approved,
                description: e.description 
            }   
        }   
        
        if(operationData){
            
            Modal.confirm({
                title: 'Confirmar operación',
                okText: 'Confirmar',
                cancelText: 'Cancelar',
                onOk:()=> operationData && confirmSave(operationData),
                content: (
                    <div>
                        <p><strong>Transferencia entre cajas</strong></p>
                        {e.origin_checkout.label && <p style={{borderTop: '1px solid gray', paddingTop: 10}}><strong>Sucursal - Caja de origen: </strong> 
                            {cashs?.find((x)=> x.id === Number(e.origin_checkout.key))?.branch.name}{' - '}
                            {e.origin_checkout.label}
                        </p>}                                    
                        {e.amount_debit && <p><strong>Débito: </strong>
                            {cashs?.find((x)=> x.id === Number(e.origin_checkout.key))?.currency.sign} {' '}
                            {Number(e.amount_debit).toLocaleString('es-AR', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                        </p>}       
                        {e.destination_checkout .label && <p style={{borderTop: '1px solid gray', paddingTop: 10}}><strong>Sucursal - Caja de destino: </strong> 
                            {cashs?.find((x)=> x.id === Number(e.destination_checkout.key))?.branch.name}{' - '}
                            {e.destination_checkout.label}
                        </p>}         
                        {e.amount_debit && <p  style={{borderBottom: '1px solid gray', paddingBottom: 10}}><strong>Crédito: </strong>
                            {cashs?.find((x)=> x.id === Number(e.destination_checkout.key))?.currency.sign} {' '}
                            {Number(e.amount_credit).toLocaleString('es-AR', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                        </p>}                    
                        <p>
                            <strong>Estado: </strong>
                            <strong style={{ color: operationData.approved ? 'green' : 'orange' }}>
                                {operationData.approved ? 'Aprobado' : 'Sin aprobar'}
                            </strong>
                        </p>                                                                                                                  
                    </div>
                ),
            });  
        }  
    }           

    const changeOriginCheckout = async(e: FilterValues,  values: any) =>{
        form.setFieldsValue({ amount_debit: undefined });
        setSpanError(undefined)
        if(e){
            let cash = e.key
            const balanceData = await getCheckoutBalance(cash) //consulto el balance de caja
            setCash({
                id: e.key, 
                name: e.label, 
                currency:{
                    id: values.currency.id, 
                    sign: values.currency.sign
                }
            })
            if(balanceData && balanceData){
                setBalance(balanceData)
            }     
        }          
    }

    const changeSecondCheckout = (e: FilterValues,  values: any)=>{
        form.setFieldsValue({ amount_credit: undefined });
        if(e){           
            setSecondCheckout({
                id: e.key, 
                name: e.label, 
                currency:{
                    id: values.currency.id, 
                    sign: values.currency.sign
                }
            }) 
        }  
    }

    const onChangeAmount = (e: any) =>{
        if(balance){ 
            if(e > balance.balance ){            
                let value = new Intl.NumberFormat('es-AR', { style: 'decimal', minimumFractionDigits: 2 }).format(balance.balance)
                setSpanError('El monto a transferir supera el monto de cuenta: ' + cash?.currency.sign + ' ' + value  )  
                setDisabledBtn(true)
            }else{
                setSpanError(undefined)
                setDisabledBtn(false)
            }
        }
    }  
    
    const onChangeapproved: CheckboxProps['onChange'] = async (e) => {
        setaproved(e.target.checked); 
    };

    return(
        <div>
            <Modal
                title= {
                    <ModalTitle
                        title={'Transferencia entre cajas'} 
                        isApproved={transactionsData && transactionsData.approved ? true : false}
                        isDeleted={transactionsData && transactionsData.deleted ? true : false}
                        operationNumber={transactionsData ? transactionsData.operation_id: undefined}
                    />
                }
                footer={transactionsData?.approved ? null : undefined} 
                open={showForms.transfers_between_cashs}
                onCancel={handleCancel}  
                confirmLoading={loadingBtn}                   
                okText="Guardar"
                cancelText="Cancelar" 
                onOk={()=>form.submit()}
                okButtonProps={{
                    disabled: disabledBtn
                }}
                width={700}
                
            >
                <Form
                    form={form}
                    id='form_deposit_extraction'
                    onFinish={handleSave}
                    layout="vertical"                   
                >    
                    <Row  gutter={[10, 10]}>
                        <Col span={8} >
                            <Form.Item
                                label='Sucursal (filtro)'
                                name='branch_origin'
                            >
                                <Select
                                    disabled={transactionsData?.approved}
                                    labelInValue  
                                    onChange={(e)=>{
                                        setOriginBranch(+e.key)
                                        setCash(undefined)
                                        form.setFieldsValue({ amount_debit: undefined, origin_checkout: undefined });
                                    }}
                                    placeholder= 'Sucursal'
                                >
                                    {branches && branches.map((item) => (
                                        <Option value={item.name} key={item.id}>
                                            {item.name}
                                        </Option>
                                    ))} 
                                </Select> 
                            </Form.Item>
                        </Col>
                        <Col span={8} >
                            <Form.Item
                                label='Caja de origen'
                                name='origin_checkout'
                                rules={mandatory}
                            >
                                <Select          
                                    disabled={transactionsData?.approved}                 
                                    labelInValue  
                                    loading={!cashs }
                                    placeholder={!cashs ? 'Cargando cajas...' : 'Seleccione una caja'}      
                                    style={{minWidth: '200px'}} 
                                    onChange={changeOriginCheckout}                                                          
                                >
                                    {cashs && cashs
                                        .filter((item) => item.branch.id === originBranch || item.branch.id===0)
                                        .map((item) => (
                                            <Option value={item.name} key={item.id} currency={item.currency}>
                                                {item.name}
                                            </Option>
                                        ))
                                    } 
                                </Select>
                            </Form.Item>                            
                        </Col>
                        <Col span={8}>
                             <Form.Item
                                label='Monto extracción'
                                name='amount_debit'
                                rules={mandatory}
                            >
                                <InputNumber
                                    disabled={transactionsData?.approved}
                                    placeholder="Monto extracción"
                                    addonBefore={cash?.currency.sign ? cash?.currency.sign : ' ¤ '}
                                    onChange={onChangeAmount}                                    
                                    // formatter={(value) => (value ? String(value).replace(/,/g, "") : "")} // Elimina comas en la visualización
                                    // parser={(value) => (value ? parseFloat(value.replace(/,/g, "")) || 0 : 0)} // Convierte a número y evita NaN 
                                    onKeyDown={(event) => {
                                        if (event.key === ',') {
                                            event.preventDefault(); // Evita que la coma aparezca en el input
                                        }
                                    }} 
                                />
                            </Form.Item>
                        </Col>
                    </Row>                      
                    <Row gutter={[10, 10]}>
                        <Col span={8}>
                            <Form.Item
                                label='Sucursal (filtro)'
                                name='branch_destination'
                            >
                                <Select
                                    labelInValue  
                                    disabled={transactionsData?.approved}
                                    onChange={(e)=>{
                                        setDestinationBranch(+e.key)
                                        setSecondCheckout(undefined)
                                        form.setFieldsValue({ amount_credit: undefined, destination_checkout: undefined });
                                    }}
                                    placeholder= 'Sucursal'
                                >
                                    {branches && branches.map((item) => (
                                        <Option value={item.name} key={item.id}>
                                            {item.name}
                                        </Option>
                                    ))} 
                                </Select> 
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label='Caja destino'
                                name='destination_checkout'
                                rules={mandatory}
                            >
                                <Select       
                                    disabled={transactionsData?.approved}                    
                                    labelInValue  
                                    loading={!cashs }
                                    placeholder={!cashs ? 'Cargando cajas...' : 'Seleccione una caja'}      
                                    style={{minWidth: '200px'}} 
                                    onChange={changeSecondCheckout}                                                          
                                >
                                    {cashs && cashs
                                        .filter((item) => item.branch.id === destinationBranch || item.branch.id===0)
                                        .map((item) => (
                                            <Option value={item.name} key={item.id} currency={item.currency}>
                                                {item.name}
                                            </Option>
                                        ))
                                    }
                                </Select>                                 
                            </Form.Item>                              
                        </Col>           
                        <Col span={8}>
                            <Form.Item
                                label='Monto Depósito'
                                name='amount_credit'
                                rules={mandatory}
                            >
                                <InputNumber
                                    disabled={transactionsData?.approved}
                                    placeholder="Monto depósito"
                                    addonBefore={secondCheckout ? secondCheckout.currency.sign :  ' ¤ ' } 
                                    // formatter={(value) => (value ? String(value).replace(/,/g, "") : "")} // Elimina comas en la visualización
                                    // parser={(value) => (value ? parseFloat(value.replace(/,/g, "")) || 0 : 0)} // Convierte a número y evita NaN                                   
                                    onKeyDown={(event) => {
                                        if (event.key === ',') {
                                            event.preventDefault(); // Evita que la coma aparezca en el input
                                        }
                                    }} 
                                />
                            </Form.Item> 
                        </Col>         
                    </Row> 
                    <Checkbox
                        disabled={transactionsData?.approved}
                        checked={approved}
                        onChange={onChangeapproved}
                    >
                        Aprobado
                    </Checkbox>
                    <Form.Item 
                        label='Descripción'
                        name='description'
                    >
                        <Input
                            disabled={transactionsData?.approved}
                            placeholder="Comentario"
                        />
                    </Form.Item>   
                    <span style={{color: 'red'}}>{spanError && spanError} </span>             
                </Form>
            </Modal>
        </div>
    )
}

export default TransfersBetweenBoxes;