import './App.css';
import { BrowserRouter, useNavigate } from 'react-router-dom';
import MainRoutes from './Routes/MainRoutes';
import { UserContextProvider } from './Contexts/UserContext/UsersContext';
import './assets/global.scss'
import {  VariablesContextProvider } from './Contexts/UserContext/VariablesContext';
import { StylesContextProvider } from './Contexts/UserContext/StylesContext';
import { BalancesContextProvider } from './Contexts/UserContext/BalancesContext';
import { MovementCashContextProvider } from './Contexts/UserContext/MovementCashContext';
import { OperationsContextProvider } from './Contexts/UserContext/OperationsContext';
import { MovementAccountContextProvider } from './Contexts/UserContext/MovementAcountContext';


function App() {

  return (
    <BrowserRouter>
      <StylesContextProvider>
        <BalancesContextProvider>
          <VariablesContextProvider>            
            <UserContextProvider>
              <OperationsContextProvider>
                <MovementAccountContextProvider>
                  <MovementCashContextProvider>
                    <MainRoutes/>
                  </MovementCashContextProvider>                
                </MovementAccountContextProvider>                
              </OperationsContextProvider>
            </UserContextProvider>    
          </VariablesContextProvider>  
        </BalancesContextProvider> 
      </StylesContextProvider>    
    </BrowserRouter>
  );
}

export default App;
