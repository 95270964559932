import React from "react";
import {  Col, Row, } from "antd";

export const ModalTitle: React.FC<{
    title: string,
    isApproved: boolean,
    isDeleted: boolean,
    operationNumber: number | undefined
}> = ({
    title,
    isApproved,
    isDeleted,
    operationNumber
}) =>{
    let color: string = ''
    let text: string = ''

    if(isDeleted){
        color = 'red'
        text = 'Eliminada'
    }else if(isApproved){
        color = 'rgb(48, 203, 133)'
        text = 'Aprobada'
    }

    return(
        <Row>
            <Col>
                <span style={{fontSize: 15}}>{title}</span> 
            </Col>
           {    (isApproved || isDeleted) &&
                <Col>
                    <span 
                        style={{
                            backgroundColor: color,
                            padding: '5px 10px',
                            borderRadius: 4,
                            marginLeft: 10,
                            color: 'white'
                        }}
                    >
                        {text}
                    </span>
                    {
                        operationNumber &&
                        <span 
                        style={{
                            backgroundColor: '#37bde0',
                            padding: '5px 10px',
                            borderRadius: 4,
                            marginLeft: 10,
                            color: 'white'
                        }}
                    >
                      { `N° ${operationNumber}` }
                    </span>
                    }
                   
                </Col>
            }
        </Row>
    )
}

export default ModalTitle