import React, { useContext, useEffect, useState } from "react";
import './styles.scss';
import { Checkbox, CheckboxProps, Col, Form, Input, InputNumber, Modal, notification, Row, Select } from "antd";
import { TransfersSuppliersProps } from "./types";
import {  useGetAllAccount } from "../../../Services/useGetAllAccount";
import { OperationTypeProps, useOperations } from "../../../Services/useOperations";
import { ResponseAccounts, useAccounts } from "../../../Services/useAccounts";
import { VariablesContext } from "../../../Contexts/UserContext/VariablesContext";
import { UserContext } from "../../../Contexts/UserContext/UsersContext";
import { AllClientsProps, useGetAllClients } from "../../../Services/useGetAllClients";
import { CurrencyProps, useGetCurrency } from "../../../Services/useGetCurrency";
import { AllOperationTypeProps, useGetOperationType } from "../../../Services/useGetOperationType";
import { Transaction } from "../../../Services/useGetMovements";
import ModalTitle from "../../ModalTitle";
import { OperationsContext } from "../../../Contexts/UserContext/OperationsContext";
import { Button } from "antd/es/radio";
import {PlusOutlined } from "@ant-design/icons"
import NewCustomer from "../../NewCustomer";

export interface FilterValues{
    value: string,
    label: string,
    key: number,
}

export interface ValuesForm {
    operation_type: FilterValues,
    origin_account: FilterValues,
    destination_account: FilterValues,
    ammount: number,
    approved: boolean,
    description: string,
    supplier: FilterValues,
    supplier_currency: FilterValues,    
    origin_customer: FilterValues,
    origin_currency: FilterValues, 
    commission: number,
    supplier_commission: number   
}

export const TransfersSuppliers: React.FC<TransfersSuppliersProps> =({
    form,
})=>{

    const {data: account} = useGetAllAccount()
    
    const {createOperation, editOperation, getOperation} = useOperations()
    const {getAccountsCurrencyAndCustomer} = useAccounts()  
    const { collapsedFilsters, setCollapsedFilters, setShowForms, showForms, setSelectedRowId, selectedRowId } = useContext(VariablesContext)
    const {setRowSelect} = useContext(OperationsContext)
    const { data: currency} = useGetCurrency()
    const { data: clients } = useGetAllClients()
    const { data: suppliers } = useGetAllClients({ is_supplier: true });
    const {userData} = useContext(UserContext)  
    const { data: operation_type } = useGetOperationType()
    const { Option } = Select;
    const mandatory = [
        {
            required: true,
            message:'Este campo es obligatorio'
        }
    ]
    
    const [loadingBtn, setLoadingBtn] = useState(false)
    const [approved, setaproved] = useState(false)
    const [selectedCurrency, setSelectedCurrency] = useState<{id: number, name: string, sign: string} | undefined>()
    const [spanError, setSpanError] = useState<string>() 
    const [secondSelectorList, setSecondSelectorList] = useState<AllClientsProps[]>([])
    const [secondCurrencySelectorList, setCurrencySecondSelectorList] = useState<CurrencyProps[]>([])
    const [ammmountSign, setAmmountSign] = useState<string>('')
    const [ammount, setAmmount] = useState<number>() 
    const [results, setResults] = useState<number>()
    const [comission, setComission] = useState<number>(3)
    const [suplierComission, setSuplierComission] = useState<number>(1.5)
    const [typeTrasnfer, setTypeTransfer] = useState<'deposit' | 'extraction'>()
    const [transactionsData, setTransactionsData] = useState<Transaction>()

    useEffect(()=>{
        form.resetFields()
    },[])

    useEffect(()=>{
        if(
            ammount !== undefined && ammount !== null &&
            comission !== undefined && ammount !== null && 
            suplierComission !== undefined && ammount !== null){
            if(typeTrasnfer === 'deposit'){
                
                setResults((ammount-(ammount*(comission/100))))
            }
            if(typeTrasnfer === 'extraction'){
                setResults(-(ammount*(1+comission/100)))
            }            
        }
    },[comission, ammount, typeTrasnfer, suplierComission])


   useEffect(() => {
     
       if(selectedRowId && account && operation_type){
            uploadForm(selectedRowId)            
        }else{
            form.resetFields(undefined) 
            setSelectedCurrency(undefined)
            setAmmount(undefined)
        }        
    }, [account, operation_type]);

    const uploadForm = async(data:{id: string}) =>{
        const response = await getOperation(+data.id)
        if(response){
            setTransactionsData(response)
            setaproved(response.approved)      
            //let origin_account = account?.filter((x)=>x.id === Number(rowSelect.origin_account_id))[0]
            //let destination_account = account?.filter((x)=>x.id === Number(rowSelect.destination_account_id))[0]  
            
            const isType10 = response.type.id===10;
            const customerAccount = isType10 ? response.destination_account : response.origin_account;
            const supplierAccount = isType10 ? response.origin_account: response.destination_account;                                                    

          
                let type_id =  response.type.id 

                form.setFieldsValue({
                    supplier: {
                        label: supplierAccount?.customer?.first_name + ' ' + supplierAccount?.customer.last_name,
                        value: supplierAccount.customer?.first_name + ' ' + supplierAccount?.customer.last_name,
                        key: supplierAccount.customer.id,
                    },
                    origin_customer: {
                        label: customerAccount?.customer.first_name + ' ' + customerAccount?.customer.last_name,
                        value: customerAccount?.customer.first_name + ' ' + customerAccount?.customer.last_name,
                        key: customerAccount?.customer.id,
                    },
                    origin_currency: {
                        label: response.origin_account.currency.name,
                        value: response.origin_account.currency.name,
                        key: response.origin_account.currency.id
                    },
                    operation_type:{
                        label: response.type.name,
                        value: response.type.name,
                        key: response.type.id,
                    },
                    commission: response.commission,
                    supplier_commission:response.supplier_commission,
                    ammount: response.debit ? response.debit :response.credit,
                    description: response.description
                });  
         
            if(response.type.id === 10){
                setTypeTransfer('deposit')
            }else if(response.type.id === 11){
                setTypeTransfer('extraction')
            }
            setAmmountSign(customerAccount.currency.sign)
            if(response.commission){
                setComission(response.commission)
            }
            if(response.supplier_commission){
                setSuplierComission(response.supplier_commission)
            }

            setSelectedCurrency({
                name: response.origin_account.currency.name,
                id: response.origin_account.currency.id,
                sign: response.origin_account.currency.sign
            })   

            setAmmount(response.debit ? response.debit : response.credit) 
        }  
    }

    const checkBalance = async(user_id: number, ammount: number) =>{
        if(selectedCurrency){
            const balanceData = await getAccountsCurrencyAndCustomer(user_id, +selectedCurrency.id) //consulto el balance de cliente
            if(balanceData && balanceData){
               // setBalance( balanceData)
                if(ammount > balanceData.balance ){            
                    let value = new Intl.NumberFormat('es-AR', { style: 'decimal', minimumFractionDigits: 2 }).format(balanceData.balance)
                    setSpanError('Notificación: El monto a transferir supera el monto de cuenta: ' + selectedCurrency?.sign + ' ' + value  )  
                }else{
                    setSpanError('')
                }
            }                    
        }         
    }

    const handleCancel =()=>{
        setShowForms(Object.fromEntries(Object.keys(showForms).map(key => [key, false])))// pone todos en false
        if(selectedRowId){
            setSelectedRowId(undefined)
        }        
        setTransactionsData(undefined)
        setRowSelect(undefined)
    }

    const changeTransferer = (e: {
        key: string,
    } ) =>{
        let typeOperations: 'deposit' | 'extraction' | undefined= e.key==='10' 
            ? 'deposit' 
            : e.key==='11' 
                ? 'extraction' 
                : undefined
        setTypeTransfer(typeOperations)
          
    }

    const onChangeFirstClient = (e: FilterValues) =>{
        if(suppliers){
            let list = suppliers?.filter((x)=> 
                +e.key !== x.id)
            setSecondSelectorList(list)
            form.resetFields(['destination_customer'])

        }        
    }

    const onChangeFirstCurrency = (e: FilterValues, values: { sign: string } | { sign: string }[]) =>{
        const signValue = Array.isArray(values) ? values[0]?.sign : values.sign;
        if(currency){
            let list = currency?.filter((x)=> 
                +e.key===x.id)
            setCurrencySecondSelectorList(list)
            setAmmountSign(signValue)
        }        
        setSelectedCurrency({
            id: e.key, 
            name: e.label,
            sign: signValue})
    }

    const onChangeAmmount = (e: any) =>{
        setAmmount(e)       
    }  

    const onChangeapproved: CheckboxProps['onChange'] = async (e) => {
        setaproved(e.target.checked); 
    };

    const hanldeChangeComision = (value: number | null) =>{
        if(value !== undefined && value !== null){
            setComission(value)
        }
    }

    const hanldeChangeSuplierComision = (value: number | null) =>{
        if(value !== undefined &&  value !== null){
            setSuplierComission(value)
        }
    }

    const confirmSave = async(operationData: OperationTypeProps) =>{
        let newData
        if(selectedRowId){
            newData = await editOperation(handleCancel, selectedRowId.id!, operationData)
        }else{
            newData = await createOperation(handleCancel, operationData)
        }                  
        //checkAccountBalance()
        setCollapsedFilters(!collapsedFilsters)
        setLoadingBtn(false)  
    }

    const handleSave = async (e: ValuesForm)=>{
        if(e.supplier.key===e.origin_customer.key){
            notification.error({
                message: 'Error', 
                description:
                  'Cliente y proveedor deben ser distintos', 
                placement: 'top'
            })
            return   

        }
        //setLoadingBtn(true)
        let operationData: OperationTypeProps | undefined
        if(userData){
            operationData = {
                description: e.description,
                type_id: e.operation_type.key,
                currency_id: e.origin_currency.key,
                user_id: userData.id,
                debit: Number(e?.operation_type?.key) == 11 ? e.ammount : undefined,
                credit: Number(e?.operation_type?.key) == 10 ? e.ammount : undefined,
                approved: approved, 
                customer_id: e.origin_customer.key, 
                supplier_id: e.supplier.key, 
                commission: e.commission, 
                supplier_commission: e.supplier_commission
            }              
        }    
        if(operationData){
            let sign = currency?.find((x)=> x.id === Number(e.origin_currency.key))?.sign || ''
            Modal.confirm({
                title: 'Confirmar operación',
                okText: 'Confirmar',
                cancelText: 'Cancelar',
                onOk:()=> operationData && confirmSave(operationData),
                content: (
                    <div>
                        <p><strong>{e.operation_type.label}</strong></p>
                        {e.origin_customer.label && <p><strong>Cliente: </strong> {e.origin_customer.label}</p>}    
                        {e.supplier.label && <p><strong>Proveedor: </strong> {e.supplier.label}</p>}                                      
                        {e.origin_currency.key && <p><strong>Monto: </strong>{sign} {e.ammount.toLocaleString("es-AR",{maximumFractionDigits:2, minimumFractionDigits:2})}</p>}
                        {
                            operationData.commission !== undefined && 
                            operationData.commission > 0 && <p><strong>Comisión cliente: </strong>{operationData.commission } %</p>}
                        {  operationData.supplier_commission !== undefined &&  operationData.supplier_commission > 0 && <p><strong>Comisión proveedor: </strong>{operationData.supplier_commission } %</p>}
                        {results && 
                            <p>
                                <strong>{`${e.operation_type.key.toString() === '11' ? 'Débito': 'Crédito'} cuenta cliente:`} </strong>
                                {sign} {results.toLocaleString("es-AR",{maximumFractionDigits:2, minimumFractionDigits:2})}
                            </p>
                        }  
                        <p>
                            <strong>Estado: </strong>
                            <strong style={{ color: operationData.approved ? 'green' : 'orange' }}>
                                {operationData.approved ? 'Aprobado' : 'Sin aprobar'}
                            </strong>
                        </p>                                                                                                                  
                    </div>
                ),
            });  
        }            
    }        
    return(
        <div>
            <Modal
                style={{ top: 10 }}
                title= {
                    <ModalTitle
                        title={'Transferencia bancaria a terceros'} 
                        isApproved={transactionsData && transactionsData.approved ? true : false}
                        isDeleted={transactionsData && transactionsData.deleted ? true : false}
                        operationNumber={transactionsData ? transactionsData.operation_id: undefined}
                    />
                }
                footer={transactionsData?.approved ? null : undefined}
                open={showForms.transfer_to_suppliers}
                onCancel={handleCancel}  
                //confirmLoading={loadingBtn}                   
                okText="Guardar"
                cancelText="Cancelar" 
                onOk={()=>form.submit()}
            >
                <NewCustomer justify={"end"} positions= {'leftTop'} />
                <Form
                    form={form}
                    id='form_deposit_extraction'
                    onFinish={handleSave}
                    layout="vertical"                    
                >   
                  <Form.Item
                        label='Tipo de operación'
                        name='operation_type'                
                        rules={mandatory}
                    >
                        <Select
                            disabled={transactionsData?.approved}
                            labelInValue
                            placeholder="Seleccione el tipo de movimiento"   
                            onChange={changeTransferer}                         
                        >
                            {operation_type &&  operation_type.filter(item => [10, 11].includes(item.id)).map((item) => (
                                <Option value={item.name} key={item.id} >
                                    {item.name}
                                </Option>
                            ))} 
                        </Select>
                    </Form.Item>  
                    <Row>
                        <Col span={14} >
                            <Form.Item
                                label='Cliente'
                                name='origin_customer'
                                rules={mandatory}
                            >   
                                <Select      
                                    disabled={transactionsData?.approved}                     
                                    labelInValue  
                                    loading={!clients }
                                    placeholder={!clients ? 'Cargando campos...' : 'Cliente'}      
                                    style={{minWidth: '200px'}} 
                                    showSearch   
                                    onChange={onChangeFirstClient}                                 
                                >
                                    {clients && clients.map((item) => (
                                        <Option value={item.first_name + ' ' + item.last_name} key={item.id} >
                                            {item.first_name + ' ' + item.last_name}
                                        </Option>
                                    ))} 
                                </Select>

                            </Form.Item>
                        </Col>
                        <Col span={10}>
                            <Form.Item
                                label='Moneda cuenta de origen'
                                name='origin_currency'                         
                                rules={mandatory}                       
                            
                            >
                                <Select                                
                                    disabled={transactionsData?.approved}
                                    labelInValue     
                                    placeholder='Moneda de cuenta' 
                                    onChange={onChangeFirstCurrency}                                                                  
                                >
                                    {currency && currency.map((item) => (
                                        <Option 
                                            value={item.name} 
                                            key={item.id}
                                            sign={item.sign} >
                                        {item.name}
                                        </Option>
                                    ))} 
                                </Select>
                            </Form.Item>
                        </Col>                                                                               
                    </Row> 
                    <Row>
                        <Col span={14}>
                            <Form.Item
                                label='Proveedor'
                                name='supplier'
                                rules={mandatory}
                            >   
                                <Select   
                                    disabled={transactionsData?.approved}                        
                                    labelInValue  
                                    loading={!suppliers }
                                    placeholder={!suppliers ? 'Cargando campos...' : 'Proveedor'}      
                                    style={{minWidth: '200px'}} 
                                    showSearch   
                                   // onChange={(e: FilterValues)=>setSelectedSupplier({id: e.key, name: e.label})}                              
                                >
                                    {secondSelectorList && secondSelectorList.map((item) => (
                                        <Option value={item.first_name + ' ' + item.last_name} key={item.id} >
                                            {item.first_name + ' ' + item.last_name}
                                        </Option>
                                    ))} 
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={10}>
                            <Form.Item
                                label='Moneda cuenta de destino'
                                name='origin_currency'                         
                                rules={mandatory}                       
                            
                            >
                                <Select
                                    labelInValue     
                                    placeholder='Moneda de cuenta'  
                                    disabled={true}                                                                                                    
                                >
                                    {secondCurrencySelectorList && secondCurrencySelectorList.map((item) => (
                                        <Option 
                                            value={item.name} 
                                            key={item.id} >
                                        {item.name}
                                        </Option>
                                    ))} 
                                </Select>
                            </Form.Item>                        
                        </Col>                                               
                    </Row> 
                    <Form.Item
                        label='Comisión'
                        name='commission'
                        initialValue={comission}
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                    >
                        <InputNumber                            
                            disabled={transactionsData?.approved}
                            style={{width: '100%'}}
                            placeholder="Comisión"                            
                            addonBefore='%'
                            onChange={hanldeChangeComision}
                        />
                    </Form.Item>   
                    <Form.Item
                        label='Comisión proveedor'
                        name='supplier_commission'
                        initialValue={suplierComission}
                        rules={[
                            {
                                required: true,
                                message:'Este campo es obligatorio'
                            }
                        ]}
                    >
                        <InputNumber
                            disabled={transactionsData?.approved}
                            onChange={hanldeChangeSuplierComision}
                            style={{width: '100%'}}
                            placeholder="Comisión"
                            addonBefore='%'
                        />
                    </Form.Item>                                                   
                    <Form.Item
                        label='Monto'
                        name='ammount'
                        rules={mandatory}
                    >
                        <InputNumber
                            disabled={transactionsData?.approved}
                            style={{width: '100%'}}
                            placeholder="Monto"
                            onChange={onChangeAmmount}
                            addonBefore={ammmountSign ? ammmountSign : ' ¤ '}
                            onKeyDown={(event) => {
                                if (event.key === ',') {
                                    event.preventDefault(); // Evita que la coma aparezca en el input
                                }
                            }}   
                        />
                    </Form.Item>  
                    <Row
                        style={{
                            marginBottom: 15, 
                            fontWeight: 700
                        }}
                    >
                        <span style={{marginRight:10}}>
                            {typeTrasnfer === 'deposit' && 'Credito en cuenta cliente:'}
                            {typeTrasnfer === 'extraction' && 'Débito en cuenta cliente:'} 
                        </span>
                        <span>{ammmountSign && ammmountSign}</span>
                        <span>{results?.toLocaleString("es-AR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>  
                    </Row>
                    <Checkbox
                        disabled={transactionsData?.approved}
                        checked={approved}
                        onChange={onChangeapproved}
                    >
                        Aprobado
                    </Checkbox>
                    <Form.Item
                        label='Descripción'
                        name='description'
                    >
                        <Input
                            disabled={transactionsData?.approved}
                            placeholder="Comentario"
                        />
                    </Form.Item>  
                    <span style={{color: 'red'}}>{spanError && spanError} </span>           
                </Form>
            </Modal>
        </div>
    )
}

export default TransfersSuppliers;